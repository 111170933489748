import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Grid, Typography, Box, TextField, IconButton, Input } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';

import { Colors } from '../../src/config/default';
import { hashEmailAndPassword } from '../services/common';
import theme from '../theme';
import { sign_In, user_information } from '../redux/actions/action';
import { useLocation } from 'react-router-dom';
import { signIn, getInfo } from '../services/services';
import Btn from '../components/button';
import {
  ValidationMessages,
  userRoles,
  headings,
  IntegrationPage
} from '../constants/appConstants';
import { useToast } from '../toast/toastContext';

export default function SignIn({
  setForgotPassword,
  setIsHovered,
  setLoading,
  setSignIn,
  isTransitionCompleted
}) {
  const { showToast } = useToast();
  const { SOMETHING_WENT_WRONG } = IntegrationPage;
  const { REQUIRED, INVALID_EMAIL, PASSWORD_CHECK, INVALID_PASSWORD, FORGOT_PASSWORD } =
    ValidationMessages;
  const {
    EMAIL,
    EMAIL_PLACEHOLDER,
    PASSWORD,
    PASSWORD_PLACEHOLDER,
    HEADING,
    SUBMIT,
    SIGNUP_HEADING,
    SIGNUP_QUESTION,
    TOKEN
  } = headings;
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { ADMIN, ACCOUNTANT, OWNER, MANAGER, CLEVEL, VENDOR } = userRoles;
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const inputStyle = {
    WebkitBoxShadow: '0 0 0 1000px #141414 inset',
    WebkitTextFillColor: '#fff'
  };
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const validationSchema = Yup.object({
    email: Yup.string().trim().email(INVALID_EMAIL).required(REQUIRED),
    password: Yup.string()
      .required(REQUIRED)
      .min(8, PASSWORD_CHECK)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`~£])[A-Za-z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`~£]{8,}$/,
        PASSWORD_CHECK
      )
  });

  const queryParams = new URLSearchParams(location.search);
  const redirectTo = queryParams.get('redirectTo');
  const stripeEmail = queryParams.get('customerEmail');
  const callSource = queryParams.get('callSource')?.toLowerCase();

  if (redirectTo) {
    localStorage.clear();
    localStorage.setItem('stripeEmail', stripeEmail);
    localStorage.setItem('callSource', callSource);
  }

  localStorage.setItem('switchState', 'true');

  const handleSubmit = useCallback(
    async (values) => {
      setIsLoading(true);
      const { hashedEmail, hashedPassword } = hashEmailAndPassword(
        values.email?.toLowerCase(),
        values.password
      );
      const params = {
        email: hashedEmail,
        password: hashedPassword
      };
      const signInResult = await signIn(params);
      if (signInResult?.status === 200) {
        setLoading(true);
        dispatch(sign_In(signInResult?.data?.payload?.data));
        const token = signInResult?.data?.payload?.data?.cognitoRes?.idToken?.jwtToken;
        const accessToken = signInResult?.data?.payload?.data?.cognitoRes?.accessToken?.jwtToken;
        const setHeaders = () => {
          const headerConfig = {
            headers: { Authorization: 'Bearer ' + token }
          };
          return headerConfig;
        };
        const getUserresult = await getInfo(
          signInResult?.data?.payload?.data?.cognitoRes?.accessToken?.payload?.username,
          setHeaders
        );
        if (getUserresult?.status === 200) {
          localStorage.setItem(TOKEN, token);
          localStorage.setItem('accessToken', accessToken);
          const role = signInResult?.data?.payload?.data?.cognitoRes?.idToken.payload['given_name'];
          localStorage.setItem('userRole', role);
          dispatch(user_information(getUserresult?.data?.payload?.data));
          if (redirectTo === 'stripe') {
            navigate('/stripe');
          } else if (role === OWNER) {
            navigate('/home');
          } else if (role === ADMIN) {
            navigate('/admin');
          } else if (role === ACCOUNTANT) {
            navigate('/accountant');
          } else if (role === CLEVEL || role === MANAGER) {
            navigate('/approver');
          } else if (role === VENDOR) {
            navigate('/vendor');
          } else {
            showToast(INVALID_PASSWORD, 'error');
            setIsLoading(false);
          }

          setLoading(false);
        } else {
          showToast(SOMETHING_WENT_WRONG, 'error');
        }
      } else {
        showToast(INVALID_PASSWORD, 'error');
        setIsLoading(false);
      }
    },
    [isTransitionCompleted]
  );
  const initialValues = {
    email: stripeEmail ? stripeEmail : '',
    password: ''
  };
  const onSubmit = (values) => {
    handleSubmit(values);
  };
  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema
  });

  return (
    <ThemeProvider theme={theme}>
      <Grid>
        <Box
          sx={{
            marginTop: '4rem',
            display: 'flex',
            flexDirection: 'column'
          }}>
          <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 3 }}>
            <TextField
              variant="standard"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              fullWidth
              id={EMAIL}
              type={EMAIL}
              placeholder={EMAIL_PLACEHOLDER}
              name={EMAIL}
              autoComplete="off"
              inputProps={{ style: inputStyle }}
            />
            {formik.touched.email && formik.errors.email ? (
              <Box sx={{ color: 'red', margin: '0.1rem 0' }}>{formik.errors.email}</Box>
            ) : (
              <Box sx={{ whiteSpace: 'pre', margin: '0.1rem 0' }}> </Box>
            )}

            <Input
              className="no-autofill-bg"
              sx={{ mt: 2 }}
              variant="standard"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              fullWidth
              name={PASSWORD}
              placeholder={PASSWORD_PLACEHOLDER}
              autoComplete="off"
              id={PASSWORD}
              type={showPassword ? 'text' : 'password'}
              inputProps={{ style: inputStyle, color: 'white' }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {formik.touched.password && formik.errors.password ? (
              <Box sx={{ color: 'red', margin: '0.1rem 0' }}>{formik.errors.password}</Box>
            ) : (
              ''
            )}
            <Grid item sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Typography
                style={{
                  fontFamily: 'Roboto',
                  color: Colors.WHITE,
                  marginBottom: '2.5rem',
                  cursor: 'pointer',
                  marginTop: '1.5rem',
                  fontWeight: 200,
                  fontSize: '12px'
                }}
                onClick={() => {
                  setSignIn(false);
                  setForgotPassword(true);
                }}>
                {FORGOT_PASSWORD}
              </Typography>
            </Grid>
            <Btn
              color="#fff"
              disabled={!(formik.isValid && formik.dirty)}
              type={SUBMIT}
              variant="outlined"
              text={HEADING}
              loading={isLoading}
              radius="7.93px"
              setIsHovered={setIsHovered}
            />
          </Box>
          <Grid container justifyContent="center" sx={{ marginTop: '1.3rem' }}>
            <Box>
              <Typography
                sx={{
                  color: Colors.WHITE,
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  fontWeight: 100,
                  cursor: 'pointer'
                }}
                onClick={() => setSignIn(false)}>
                {SIGNUP_QUESTION}
                <span style={{ color: Colors.TOURQUISE, fontFamily: 'Roboto' }}>
                  <b> {SIGNUP_HEADING}</b>
                </span>
              </Typography>
            </Box>
          </Grid>
        </Box>
      </Grid>
    </ThemeProvider>
  );
}
