import React from 'react';
import { useSelector } from 'react-redux';

import {
  TimelineDot,
  TimelineContent,
  TimelineConnector,
  TimelineSeparator,
  TimelineItem,
  Timeline
} from '@mui/lab';
import TimelineOppositeContent, {
  timelineOppositeContentClasses
} from '@mui/lab/TimelineOppositeContent';
import { Typography, Box } from '@mui/material';

import { autoCap } from '../services/validationService';
import { Colors } from '../config/default';
import { activity } from '../constants/appConstants';
import { isEmpty } from 'lodash';

export default function TimelineActivity({
  timelineLeftContent,
  timelineRightContent,
  documentType,
  activityIndex
}) {
  const userDetails = useSelector((state) => state?.userInfo?.userInfo);
  const erpType = userDetails?.user?.erpType;
  function transformDataForQBO(data) {
    if (erpType === 'QuickBooksOnline') {
      data?.lineItems?.forEach((item) => {
        if (item?.jobId !== undefined) {
          item.projectId = item?.jobId;
          delete item?.jobId;
        }
        if (item?.costType !== undefined) {
          item.class = item?.costType;
          delete item?.costType;
        }
      });
      if (data?.standardContent?.jobId !== undefined) {
        data.standardContent.projectId = data?.standardContent?.jobId;
        delete data?.standardContent?.jobId;
      }
      if (data?.standardContent?.costType !== undefined) {
        data.standardContent.class = data?.standardContent?.costType;
        delete data?.standardContent?.costType;
      }
    }
    return data;
  }
  const rightContent = transformDataForQBO(timelineRightContent);
  const {
    UPDATED_BY,
    UPLOADED_BY,
    STATUS,
    CONTENT,
    LINEITEM,
    ATTACHMENT,
    DELIVERY_TICKET,
    CREATED_BY
  } = activity;
  const dateObject = new Date(timelineLeftContent?.time);
  const formattedDate = dateObject?.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  });
  return (
    <Timeline
      className="timeline-root"
      sx={{
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex: 0.2
        }
      }}>
      <TimelineItem sx={{ minHeight: '10px' }}>
        <TimelineOppositeContent
          sx={{
            width: { xs: '3em', sm: '5em' }
          }}>
          <Typography
            sx={{
              color: Colors.TEXTGREY,
              fontSize: { xs: '10px', sm: '0.7em', xl: '1em' },
              fontWeight: '600'
            }}>
            {formattedDate}
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot
            sx={{ backgroundColor: Colors.TOURQUISE, height: '0.3em', width: '0.3em' }}
          />
          <TimelineConnector sx={{ bgcolor: Colors.TEXTGREY }} />
        </TimelineSeparator>
        <TimelineContent sx={{ color: Colors.TEXTGREY }}>
          {timelineLeftContent?.status === 'Pending' || timelineLeftContent?.status === 'Error' ? (
            <Typography sx={{ color: Colors.TOURQUISE, fontSize: { lg: '0.8em', xl: '1em' } }}>
              {UPLOADED_BY}
            </Typography>
          ) : timelineLeftContent?.status === 'Created' ||
            timelineLeftContent?.status === 'Populated' ? (
            <Typography sx={{ color: Colors.TOURQUISE, fontSize: { lg: '0.8em', xl: '1em' } }}>
              {CREATED_BY}
            </Typography>
          ) : (
            <Typography sx={{ color: Colors.TOURQUISE, fontSize: { lg: '0.8em', xl: '1em' } }}>
              {UPDATED_BY}
            </Typography>
          )}

          <Typography
            sx={{
              color: Colors.TEXTGREY,
              fontSize: { xs: '11px', sm: '0.8em', xl: '1em' },
              fontWeight: '600'
            }}>
            {timelineLeftContent?.user}
          </Typography>
          <Typography
            sx={{ color: Colors.TOURQUISE, mt: '0.5em', fontSize: { lg: '0.8em', xl: '1em' } }}>
            {STATUS}
          </Typography>
          <Typography
            sx={{
              color: Colors.TEXTGREY,
              fontSize: { xs: '13px', sm: '0.8em', xl: '1em' },
              fontWeight: '600'
            }}>
            {timelineLeftContent?.status === 'Pending'
              ? 'Created'
              : timelineLeftContent?.status === 'Created' ||
                timelineLeftContent?.status === 'Populate'
              ? 'Populated'
              : timelineLeftContent?.status}
          </Typography>
          {((documentType === 'Po' && activityIndex != 0) || documentType !== 'Po') && (
            <>
              {rightContent &&
                !isEmpty(rightContent?.standardContent) &&
                timelineLeftContent?.status !== 'Populated' &&
                timelineLeftContent?.status !== 'Created' && (
                  <>
                    {Object?.entries(rightContent['standardContent'])?.length > 0 && (
                      <Typography
                        sx={{
                          color: Colors.TOURQUISE,
                          mt: '0.5em',
                          fontSize: { lg: '0.8em', xl: '1em' }
                        }}>
                        {CONTENT}:
                      </Typography>
                    )}

                    {Object?.entries(rightContent['standardContent'])?.map(([key, value]) => (
                      <div key={key}>
                        <Typography
                          sx={{
                            fontSize: { xs: '13px', sm: '0.8em', xl: '1em' }
                          }}>
                          {autoCap(key)} : {value}
                        </Typography>
                      </div>
                    ))}
                  </>
                )}
              {rightContent && !isEmpty(rightContent?.['lineItems'])
                ? rightContent?.['lineItems']?.map(
                    (lineItem, index) =>
                      !isEmpty(lineItem) && (
                        <div key={index}>
                          {rightContent?.['lineItems']?.some(
                            (item) => Object?.keys(item)?.length > 0
                          ) && (
                            <Typography
                              sx={{
                                fontSize: {
                                  xs: '13px',
                                  sm: '0.8em',
                                  xl: '1em',
                                  color: Colors.TOURQUISE
                                }
                              }}>
                              {`${LINEITEM}  ${index + 1}`}
                            </Typography>
                          )}

                          {Object?.entries(lineItem)?.map(([key, value]) => (
                            <Typography
                              key={key}
                              sx={{
                                fontSize: { xs: '13px', sm: '0.8em', xl: '1em' }
                              }}>
                              {key}: {value}
                            </Typography>
                          ))}
                        </div>
                      )
                  )
                : ''}

              {rightContent && !isEmpty(rightContent?.attachment) && (
                <>
                  <Typography
                    sx={{
                      color: Colors.TOURQUISE,
                      mt: '0.5em',
                      fontSize: { lg: '0.8em', xl: '1em' }
                    }}>
                    {ATTACHMENT}:
                  </Typography>
                  {rightContent?.attachment?.map((value, i) => {
                    return (
                      <div key={i}>
                        <Box
                          sx={{
                            color: Colors.TOURQUISE,
                            fontSize: { xs: '13px', sm: '0.8em', xl: '1em' }
                          }}>
                          {i + 1}:
                        </Box>
                        <Typography
                          sx={{
                            fontSize: { xs: '13px', sm: '0.8em', xl: '1em' }
                          }}>
                          Refrence No : {value?.referenceNo}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: { xs: '13px', sm: '0.8em', xl: '1em' },
                            maxWidth: '230px',
                            wordBreak: 'break-word'
                          }}>
                          Filename : {value?.fileName}
                        </Typography>
                      </div>
                    );
                  })}
                </>
              )}
              {rightContent && !isEmpty(rightContent?.supportingDocument) && (
                <>
                  <Typography
                    sx={{
                      color: Colors.TOURQUISE,
                      mt: '0.5em',
                      fontSize: { lg: '0.8em', xl: '1em' }
                    }}>
                    {DELIVERY_TICKET}:
                  </Typography>
                  {rightContent?.supportingDocument?.map((value, i) => {
                    return (
                      <div key={i}>
                        <Box
                          sx={{
                            color: Colors.TOURQUISE,
                            fontSize: { xs: '13px', sm: '0.8em', xl: '1em' }
                          }}>
                          {i + 1}:
                        </Box>
                        <Typography
                          sx={{
                            fontSize: { xs: '13px', sm: '0.8em', xl: '1em' }
                          }}>
                          Refrence No : {value?.documentNumber}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: { xs: '13px', sm: '0.8em', xl: '1em' },
                            maxWidth: '230px',
                            wordBreak: 'break-word'
                          }}>
                          Filename : {value?.fileName}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: { xs: '13px', sm: '0.8em', xl: '1em' }
                          }}>
                          Type :{' '}
                          {value?.documentType === 'Estimate' ? 'Quote' : value?.documentType}
                        </Typography>
                      </div>
                    );
                  })}
                </>
              )}
            </>
          )}
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
}
