import { applyMiddleware, createStore } from 'redux';
import { combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import signInReducer from '../reducers/signInReducer';
import pendingReducer from '../reducers/getPendingData';
import tabsReducer from '../reducers/tabs';
import queueReducer from '../reducers/getQueue';
import allErrorsReducer from '../reducers/getAllErrors';
import allUsersReducer from '../reducers/getAllUsers';
import UserInformationReducer from '../reducers/userInformation';
import manualUsersReducer from '../reducers/getManualUsers';
import allVendorsReducers from '../reducers/getAllVendors';

const middleware = [thunk];

const Reducer = combineReducers({
  signIn: signInReducer,
  queue: queueReducer,
  pending: pendingReducer,
  errors: allErrorsReducer,
  users: allUsersReducer,
  userInfo: UserInformationReducer,
  tabs: tabsReducer,
  manualUsers: manualUsersReducer,
  vendor:allVendorsReducers
});
const persistConfig = {
  key: 'root',
  storage
};
const persistedReducer = persistReducer(persistConfig, Reducer);
export const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);
export const persistor = persistStore(store);
