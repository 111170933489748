import React from 'react';

import { DataGrid } from '@mui/x-data-grid';

import { Colors } from '../config/default';

const datagridSx = {
  border: 'none',
  color: Colors.TEXTGREY,
  paddingX: '1rem',
  '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
    outline: 'none !important'
  },
  '& .MuiDataGrid-main': { borderRadius: '10px' },
  '& .MuiDataGrid-virtualScrollerRenderZone': {
    borderBottom: 'none !important',
    '& .MuiDataGrid-root': { border: 'none !important' },
    '& .MuiDataGrid-row': {
      width: '99%',
      borderRadius: '10px',
      '&:hover': {
        backgroundColor: '#24658D !important',
        color: Colors.WHITE
      },
      '&:nth-of-type(2n-1)': {
        backgroundColor: '#1E1E1E'
      }
    },
    '& div': { border: 'none' }
  },

  '& .MuiDataGrid-columnCheckbox': {
    backgroundColor: '#fdfdfd',
    width: '20px',
    height: '20px'
  },

  '& .MuiDataGrid-columnHeaders': {
    border: 'none !important',
    color: Colors.WHITE
  }
};
export default function AdminDataTable({ rows, columns }) {
  return (
    <div style={{ width: '100%', height: '75vh' }}>
      <DataGrid
        sx={datagridSx}
        rows={rows}
        columns={columns}
        autoPageSize
        checkboxSelection={false}
        disableRowSelectionOnClick
      />
    </div>
  );
}
