import React, { useEffect, useState } from 'react';

import GeneratePoCard from './generatePoCard';
import { CircularProgress, Grid, Typography, Modal, Hidden, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';

import { getSupportingDocument } from '../services/services';
import { Colors } from '../config/default';
import Navigation from './navigation';
import { autoCap } from '../services/validationService';
import useMediaQuery from '@mui/material/useMediaQuery';
import PaginationButtons from './paginationButtons';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import { formatNumber } from '../services/common';

function SupportingDocument({
  refId,
  po,
  annotationType,
  deliveryTicketUpload,
  poMatchPermission
}) {
  const smallScreen = useMediaQuery('(max-height:1400px)');
  const userDetails = useSelector((state) => state?.userInfo?.userInfo);
  const companyId = userDetails?.company?._id;
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(false);
  const [images, setImages] = useState([]);
  const { id } = useParams();
  const handleImageClick = () => {
    setSelectedImage(!selectedImage);
  };
  const handleDTClick = () => {
    setSelectedDt(!selectedDt);
  };
  const findValue =
    annotationType === 'G702'
      ? 1
      : annotationType === 'Invoice' || annotationType === 'Receipt'
      ? deliveryTicketUpload && poMatchPermission
        ? 0
        : poMatchPermission
        ? 0
        : 1
      : 0;
  const [navigationValue, setNavigationValue] = useState(findValue);
  const [index, setIndex] = useState(0);
  const [dtIndex, setDtIndex] = useState(0);
  const [documentContent, setDocumentContent] = useState([]);
  const [documentLineItems, setDocumentLineItems] = useState([]);
  const [response, setResponse] = useState({});
  const [cardOpen, setCardOpen] = useState(false);
  const [dTUrl, setDTUrl] = useState([]);
  const [selectedDt, setSelectedDt] = useState(false);
  useEffect(() => {
    setDtIndex(0);
    setIndex(0);
  }, [navigationValue]);
  const getDocument = async () => {
    setLoading(true);
    const type = navigationValue === 1 ? 'DeliveryTicket' : 'Po';
    const findRef = !isEmpty(refId) ? refId[0]?.id : 'N/A';
    const recordNumber = navigationValue === 1 ? findRef : po;
    const initalizedValue = recordNumber === '' ? 'N/A' : recordNumber;
    const result = await getSupportingDocument(companyId, initalizedValue, type, id);
    if (result?.status === 200) {
      setResponse(result?.data?.payload?.data);
      setDocumentContent(result?.data?.payload?.data?.supportingDocument?.standardContent);
      setDocumentLineItems(result?.data?.payload?.data?.supportingDocument?.standardizeLineItems);
      setImages(result?.data?.payload?.data?.document?.convertedS3Keys);
      setDTUrl(result?.data?.payload?.data?.supportingDocument?.convertedS3Keys);
    } else {
      setResponse({});
    }

    setLoading(false);
  };
  useEffect(() => {
    getDocument();
  }, [navigationValue]);
  const slideRight = () => {
    setIndex((index + 1) % images.length);
  };
  const slideLeft = () => {
    const nextIndex = index - 1;
    if (nextIndex < 0) {
      setIndex(images.length - 1);
    } else {
      setIndex(nextIndex);
    }
  };
  const documentContentMapped = documentContent
    ?.map((item) => {
      if (item?.name === 'Estimate_no') {
        return {
          name: 'Order no',
          value: item?.value
        };
      } else if (item?.name === 'Estimate_date') {
        return {
          name: 'Date',
          value: item?.value
        };
      } else if (item?.name === 'Expiration_date') {
        return null;
      } else {
        return {
          name: autoCap(item?.name),
          value: item?.value
        };
      }
    })
    .filter((item) => item !== null);

  const documentLineItemsMapped = documentLineItems?.map((item) => ({
    ...item,
    qty: formatNumber(parseFloat(item?.qty)),
    total: formatNumber(parseFloat(item?.total)),
    unit_price: formatNumber(parseFloat(item?.unit_price))
  }));

  const lineItemsPerPage = 12;
  const remainingLineitems = documentLineItemsMapped?.length - lineItemsPerPage;
  const totalCardPages = Math?.ceil(remainingLineitems / lineItemsPerPage);
  const filteredArray = documentContentMapped?.filter((item) => item?.value !== '');
  const handleCardOpen = () => {
    setCardOpen(true);
  };
  const handleClose = () => {
    setCardOpen(false);
  };
  const slideRightDT = () => {
    setDtIndex((dtIndex + 1) % dTUrl.length);
  };
  const slideLeftDT = () => {
    const nextIndex = dtIndex - 1;
    if (nextIndex < 0) {
      setDtIndex(dTUrl?.length - 1);
    } else {
      setDtIndex(nextIndex);
    }
  };
  return (
    <Grid
      container
      sx={{
        padding: '1rem',
        height: { xs: '87vh', xl: '60vh' },
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '0em',
          height: '0em',
          borderRadius: '20px'
        },
        '&::-webkit-scrollbar-track': {
          boxShadow: 'inset 0 0 6px rgba(0,0,0,0.11)',
          webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.11)',
          outline: '1px solid slategrey'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#fff',
          outline: '1px solid slategrey'
        }
      }}>
      {loading ? (
        <Grid container sx={{ alignItems: 'center', justifyContent: 'center', height: '100%' }}>
          <CircularProgress size={45} sx={{ color: Colors.DARKBLUE }} />
        </Grid>
      ) : (
        <>
          <Grid
            item
            xs={12}
            sx={{
              mt: '.5rem',
              marginBottom: '1.5rem',
              display: 'flex',
              justifyContent: 'space-between',
              alignItem: 'center'
            }}>
            <Typography variant="h6" sx={{ color: Colors.WHITE }}>
              {navigationValue === 0
                ? 'Compare PO to Invoice'
                : navigationValue === 1
                ? 'Compare Delivery Ticket to Invoice'
                : 'Supporting Document'}
            </Typography>
            {(annotationType === 'Invoice' || annotationType === 'Receipt') &&
              deliveryTicketUpload &&
              poMatchPermission && (
                <Navigation
                  value={navigationValue}
                  setValue={setNavigationValue}
                  label1="PO"
                  label2="DT"
                />
              )}
          </Grid>
          {isEmpty(response) ? (
            <Grid
              container
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                color: Colors.WHITE
              }}>
              No Document found
            </Grid>
          ) : (
            <Grid
              container
              sx={{
                justifyContent: { xs: 'center', lg: 'space-between' },
                alignItems: 'center'
              }}>
              {navigationValue === 0 ? (
                <Grid
                  item
                  xs={12}
                  md={5}
                  sx={{
                    padding: '1.5rem',
                    backgroundColor: Colors.NAV_BLACK,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '10px'
                  }}>
                  <div
                    style={{
                      width: '100%'
                    }}>
                    <GeneratePoCard
                      textSize={{ xs: '8px', lg: '10px', xl: '12px' }}
                      height={{ xs: '47vh', md: '61vh', xl: '42vh' }}
                      parentHeight="100%"
                      cursor={smallScreen ? 'pointer' : 'auto'}
                      onClick={smallScreen ? handleCardOpen : handleCardOpen}
                      documentContentMapped={filteredArray}
                      documentLineItemsMapped={documentLineItemsMapped}
                      lineItemsPerPage={lineItemsPerPage}
                      totalCardPages={totalCardPages}
                      show={true}
                      width="auto"
                    />
                    <Modal
                      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                      open={cardOpen}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description">
                      <Grid
                        sx={{
                          height: { xs: '60vh', md: '67vh', xl: '50vh' },
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          alignItems: 'center',
                          transform:
                            documentLineItemsMapped?.length > 10 ? 'scale(1.20)' : 'scale(1.3)',
                          cursor: 'pointer'
                        }}
                        onClick={handleClose}>
                        <GeneratePoCard
                          textSize={{ xs: '8px', lg: '10px', xl: '12px' }}
                          cursor="pointer"
                          height={{ xs: '47vh', md: '60vh', xl: '45vh' }}
                          onClick={handleClose}
                          documentContentMapped={filteredArray}
                          documentLineItemsMapped={documentLineItemsMapped}
                          lineItemsPerPage={lineItemsPerPage}
                          totalCardPages={totalCardPages}
                          width={{ xs: '60vw', md: '20vw', xl: '16vw' }}
                          show={true}
                        />
                      </Grid>
                    </Modal>
                  </div>
                </Grid>
              ) : (
                <>
                  <Grid
                    item
                    xs={12}
                    md={5}
                    sx={{
                      padding: '1.5rem',
                      height: { xs: '72vh', xl: '48vh' },
                      objectFit: 'contain',
                      display: 'flex',
                      backgroundColor: Colors.NAV_BLACK,
                      justifyContent: 'center',
                      borderRadius: '10px'
                    }}>
                    {dTUrl?.length > 0 && (
                      <img
                        className={`${selectedDt ? 'image-container enlarged' : ''}`}
                        src={dTUrl[dtIndex]}
                        alt={dtIndex}
                        style={{
                          cursor: 'pointer',
                          height: '100%',
                          objectFit: 'contain',
                          maxHeight: '100%',
                          maxWidth: '100%',
                          aspectRatio: 1
                        }}
                        onClick={handleDTClick}
                      />
                    )}
                    {dTUrl?.length > 1 && (
                      <PaginationButtons
                        disableFirstButton={dtIndex === 0}
                        disableSecondButton={dtIndex === dTUrl?.length - 1}
                        slideLeft={slideLeftDT}
                        slideRight={slideRightDT}
                        index={dtIndex}
                        images={dTUrl}
                      />
                    )}
                  </Grid>
                </>
              )}
              <>
                <Hidden lgDown>
                  <CompareArrowsIcon sx={{ color: Colors.TOURQUISE, fontSize: '3rem' }} />
                </Hidden>
                <Grid
                  item
                  xs={12}
                  md={5}
                  sx={{
                    padding: '1.5rem',
                    height: { xs: '72vh', xl: '48vh' },
                    objectFit: 'contain',
                    display: 'flex',
                    backgroundColor: Colors.NAV_BLACK,
                    justifyContent: 'center',
                    borderRadius: '10px'
                  }}>
                  {images?.length > 0 && (
                    <img
                      className={`${selectedImage ? 'image-container enlarged' : ''}`}
                      src={images[index]}
                      alt={index}
                      style={{
                        cursor: 'pointer',
                        height: '100%',
                        objectFit: 'contain',
                        maxHeight: '100%',
                        maxWidth: '100%',
                        aspectRatio: 1
                      }}
                      onClick={handleImageClick}
                    />
                  )}
                  {images?.length > 1 && (
                    <PaginationButtons
                      disableFirstButton={index === 0}
                      disableSecondButton={index === images?.length - 1}
                      slideLeft={slideLeft}
                      slideRight={slideRight}
                      index={index}
                      images={images}
                    />
                  )}
                </Grid>
              </>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
}

export default SupportingDocument;
