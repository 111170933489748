import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Button, Grid, CircularProgress } from '@mui/material';

import '../index.css';
import { mergePdf, getSelectedDocs } from '../services/services';
import { get_queue } from '../redux/actions/action';
import PermissionRadio from './permissionRadio';
import { Colors } from '../config/default';
import { useToast } from '../toast/toastContext';
import useMediaQuery from '@mui/material/useMediaQuery';
import '../image.css';

function SplitDoc({ handleClose, splitDocData }) {
  const dispatch = useDispatch();
  const [permission, setPermission] = useState('Invoice');
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(true);
  const [myData, setMyData] = useState(splitDocData);
  const smallScreen = useMediaQuery('(min-width:446px) and (max-width:824px)');
  const extraSmallScreen = useMediaQuery('(max-width:445px)');

  useEffect(() => {}, [reload]);
  const userDetails = useSelector((state) => state?.userInfo?.userInfo?.user);
  const isG702 = userDetails?.G702;
  const isReceipt = userDetails?.receiptUpload;
  const deliveryTicket = userDetails?.deliveryTicketUpload;

  let options = ['Invoice'];
  if (
    (userDetails?.erpType === 'Foundation' || userDetails?.erpType === 'QuickBooksDesktop') &&
    userDetails?.quotePermission
  ) {
    options.push('Quote');
  }
  if (isG702) {
    options.push('G702');
  }
  if (isReceipt) {
    options.push('Receipt');
  }
  if (deliveryTicket) {
    options.push('Delivery Ticket');
  }
  const userRole = useSelector(
    (state) => state?.signIn?.signIn?.cognitoRes?.idToken?.payload?.given_name
  );
  const callSource = userRole === 'Vendor' ? 'api' : 'app';
  const { showToast } = useToast();
  const validImageData = splitDocData.filter(
    (item) => item.s3_file_name !== '$$' && item.s3_file_name !== '##'
  );

  const [isEnlargedArray, setIsEnlargedArray] = useState(Array(validImageData.length).fill(false));
  const handleImageClick = (index) => {
    const updatedEnlargedArray = [...isEnlargedArray];
    updatedEnlargedArray[index] = !updatedEnlargedArray[index];
    setIsEnlargedArray(updatedEnlargedArray);
  };

  useEffect(() => {}, [reload]);

  useEffect(() => {
    let size = myData.length;
    myData.map((data, index) => {
      myData.push(data);
      if (index < size - 1) myData.push({ s3_file_name: '$$' });
    });
    for (let x = 0; x < size; x++) {
      myData.shift();
    }
    setMyData(myData);
  }, []);
  const type =
    permission === 'Quote'
      ? 'Estimate'
      : permission === 'Delivery Ticket'
      ? 'DeliveryTicket'
      : permission;

  const handleSubmit = (item, index) => {
    myData[index].s3_file_name === '$$'
      ? (myData[index] = { s3_file_name: '##' })
      : (myData[index] = { s3_file_name: '$$' });
    setReload(!reload);
  };

  const handleClick = async () => {
    setLoading(true);
    let finalArrayData = [];
    let populateArray = [];
    for (let x = 0; x < myData.length; x++) {
      if (myData[x]?.s3_file_name !== '$$' && myData[x]?.s3_file_name !== '##') {
        populateArray.push(myData[x]?.s3_file_name);
        if (x === myData.length - 1) {
          finalArrayData.push(populateArray);
        }
      }
      if (myData[x]?.s3_file_name === '$$') {
        if (populateArray.length) {
          finalArrayData.push(populateArray);
          populateArray = [];
        }
      }
      if (myData[x]?.s3_file_name === '##') {
        populateArray.push(myData[x + 1]?.s3_file_name);
        if (x === myData.length - 2) {
          finalArrayData.push(populateArray);
        }
        x++;
      }
    }
    const params = {
      s3FileNames: finalArrayData
    };
    const result = await mergePdf(type, params);
    if (result?.data?.metadata?.status === 'SUCCESS') {
      showToast(result?.data?.metadata?.message, 'success');
      const response = await getSelectedDocs(callSource);
      dispatch(get_queue(response?.data?.payload?.data));
      handleClose();
    } else {
      showToast(result?.response?.data?.metadata?.message, 'error');
    }
    setLoading(false);
  };
  return (
    <>
      <Grid
        container
        sx={{
          paddingTop: '0px'
        }}>
        <Grid container sx={{ justifyContent: 'center' }}>
          <Grid item xs={10}>
            <PermissionRadio
              permission={permission}
              setPermission={setPermission}
              question="Select the document type you are going to upload"
              options={options}
            />
          </Grid>
        </Grid>

        <Grid
          container
          sx={{
            height: '400px',
            overflowY: 'scroll',
            margin: '.7rem 3rem',
            borderRadius: '20px',
            padding: '0rem 1rem',
            border: '2px dashed #5B5B5B',
            '&::-webkit-scrollbar': {
              width: '0.2em',
              borderRadius: '20px'
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.11)',
              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.11)',
              outline: '1px solid slategrey'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#fff',
              outline: '1px solid slategrey'
            }
          }}>
          {myData?.map((item, index) => {
            return (
              <Grid
                item
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  height: 'max-content',
                  margin: '1rem 0rem'
                }}>
                {item.s3_file_name === '$$' ? (
                  <hr
                    style={{
                      border: 'none',
                      borderLeft: '8px solid #E95050',
                      height: smallScreen ? '290px' : extraSmallScreen ? '210px' : '365px',
                      margin: '0px',
                      cursor: 'pointer',
                      borderRadius: '30px'
                    }}
                    onClick={() => {
                      handleSubmit(item, index);
                    }}
                  />
                ) : item.s3_file_name === '##' ? (
                  <hr
                    style={{
                      border: 'none',
                      borderLeft: '8px solid #429EB0',
                      height: smallScreen ? '290px' : extraSmallScreen ? '210px' : '365px',
                      cursor: 'pointer',
                      margin: '0px',
                      borderRadius: '30px'
                    }}
                    onClick={() => {
                      handleSubmit(item, index);
                    }}
                  />
                ) : (
                  <Grid
                    item
                    className={`splitImage-container ${
                      isEnlargedArray[index] ? 'splitEnlarged' : ''
                    }`}
                    onClick={() => handleImageClick(index)}>
                    <img
                      id="i"
                      style={{
                        cursor: isEnlargedArray[index] ? 'zoom-out' : 'zoom-in',
                        width: isEnlargedArray[index]
                          ? 'auto'
                          : smallScreen
                          ? '250px'
                          : extraSmallScreen
                          ? '180px'
                          : '300px',
                        height: isEnlargedArray[index]
                          ? 'auto'
                          : smallScreen
                          ? '290px'
                          : extraSmallScreen
                          ? '210px'
                          : '365px'
                      }}
                      alt="file"
                      src={item?.signedUrl}
                    />
                  </Grid>
                )}
              </Grid>
            );
          })}
        </Grid>
        <Grid container sx={{ m: '1em 0em', padding: '1rem', justifyContent: 'center' }}>
          <Button
            disabled={permission === '' || loading}
            variant="contained"
            sx={{
              borderRadius: '10px',
              width: '7rem',
              height: '2.5rem',
              color: Colors.WHITE,
              textTransform: 'none',
              backgroundColor: Colors.DEEPBLUE,
              '&:hover': {
                backgroundColor: Colors.DEEPBLUE
              }
            }}
            onClick={handleClick}>
            {loading ? <CircularProgress size={25} color="inherit" /> : 'Submit'}
          </Button>
        </Grid>
        {/* {isEnlargedArray[currentImageIndex] && ( */}
        {/* <div
          style={{
            // position: 'absolute',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            zIndex: '10000',
            gap: '10px'
          }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '2rem',
              borderRadius: '5px',
              border: '2px solid black',
              cursor: 'pointer'
            }}
            onClick={handlePrevious}>
            <ArrowBackIosIcon sx={{ ml: '0.4em', color: Colors.DARKBLUE }} />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '2em',
              width: '2rem',
              borderRadius: '5px',
              border: '2px solid black',
              cursor: 'pointer'
            }}
            onClick={handleNext}>
            <ArrowForwardIosIcon sx={{ color: Colors.DARKBLUE }} />
          </div>
        </div> */}
      </Grid>
    </>
  );
}
export default SplitDoc;
