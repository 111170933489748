import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import theme from '../../theme';
import { ThemeProvider } from '@mui/material/styles';
import { CircularProgress, Grid } from '@mui/material';

import { getSelectedDocs, createCsv } from '../../services/services';
import { clientParentTabs, vendorChildTabs, dashboardTabs } from '../../services/common';
import { get_queue, get_tabs } from '../../redux/actions/action';
import Layout from '../../components/layout';
import HeaderSection from '../../components/headerSection';
import DataTable from '../../components/dataTable';
import { useToast } from '../../toast/toastContext';
import VendorDashboard from '../pages/vendorDashboard';

export default function VendorHomePage() {
  const globalActiveTab = useSelector((state) => state?.tabs?.tabs);
  const parentActiveTab = globalActiveTab?.activeTab;
  const [activeTab, setActiveTab] = useState(parentActiveTab || clientParentTabs[0]?.name);
  const [childActiveTab, setChildActiveTab] = useState(
    globalActiveTab?.documentChildActiveTab || vendorChildTabs[0]?.name
  );
  const [dashboardChildTabs, setDashboardChildTabs] = useState(dashboardTabs[0]?.name);
  const [filteredData, setFilteredData] = useState([]);
  const [recordExist, setRecordExist] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [checkedIds, setCheckedIds] = useState([]);
  const [createCsvLoading, setCreateCsvLoading] = useState(false);
  const [searchProducts, setSearchProducts] = useState('');
  const [dataAccordingTabs, setDataAccordingTabs] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [selectedRowsHasPO, setSelectedRowsHasPO] = useState(true);
  const pendingQueues = useSelector((state) => state?.queue?.queue[0]);
  const activeIntegration = useSelector((state) => state?.userInfo?.userInfo?.activeIntegration);
  const { showToast } = useToast();
  const userRole = useSelector(
    (state) => state?.signIn?.signIn?.cognitoRes?.idToken?.payload?.given_name
  );
  const callSource = userRole === 'Vendor' ? 'api' : 'app';

  const Tabs = {
    activeTab: activeTab,
    documentChildActiveTab: childActiveTab
  };
  useEffect(() => {
    dispatch(get_tabs(Tabs));
  }, [activeTab, childActiveTab]);
  useEffect(() => {
    setActiveTab(parentActiveTab || clientParentTabs[0]?.name);
    setChildActiveTab(globalActiveTab?.documentChildActiveTab || vendorChildTabs[0]?.name);
  }, [parentActiveTab, globalActiveTab?.documentChildActiveTab]);
  const allQueuesData = async () => {
    setLoading(true);
    let result = await getSelectedDocs(callSource);
    if (result?.response?.status === 401) {
      localStorage.clear();
      navigate('/');
    }
    dispatch(get_queue(result?.data?.payload?.data));
    setLoading(false);
  };

  useEffect(() => {
    allQueuesData();
  }, []);
  const intervalCall = async () => {
    let result;
    result = await getSelectedDocs(callSource);
    if (result?.response?.status === 401) {
      localStorage.clear();
      navigate('/');
    }
    dispatch(get_queue(result?.data?.payload?.data));
  };
  useEffect(() => {
    const interval = setInterval(() => {
      intervalCall();
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  const handleCreateCSV = async (e) => {
    e.preventDefault();
    setCreateCsvLoading(true);
    const params = {
      documentIds: checkedIds
    };
    const response = await createCsv(params);
    if (response?.status === 200) {
      showToast(response?.data?.metadata?.message, 'success');
      setCheckedIds('');
      allQueuesData();
    } else {
      showToast(response?.data?.metadata?.message, 'error');
    }
    setCreateCsvLoading(false);
  };
  const handleClear = () => {
    intervalCall();
    setRecordExist(false);
    setFilteredData([]);
    setSearchProducts('');
  };
  return (
    <ThemeProvider theme={theme}>
      <Grid container sx={{ height: '100vh', backgroundColor: 'primary.light' }}>
        <Layout
          activeTab={parentActiveTab}
          setActiveTab={setActiveTab}
          currentTabs={clientParentTabs}
          path="vendorHome"
          filteredData={filteredData}
          recordExist={recordExist}
          handleClear={handleClear}
        />
        <Grid container sx={{ paddingX: { lg: '3rem' } }}>
          <Grid
            container
            sx={{
              height: '81vh'
            }}>
            <HeaderSection
              currentTabs={parentActiveTab === 'Dashboard' ? dashboardTabs : vendorChildTabs}
              setActiveTab={
                parentActiveTab === 'Dashboard' ? setDashboardChildTabs : setChildActiveTab
              }
              activeTab={parentActiveTab === 'Dashboard' ? dashboardChildTabs : childActiveTab}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setLoading={setLoading}
              loading={loading}
              checkedIds={checkedIds}
              setCheckedIds={setCheckedIds}
              filteredData={filteredData}
              setFilteredData={setFilteredData}
              recordExist={recordExist}
              setRecordExist={setRecordExist}
              intervalCall={intervalCall}
              tab={parentActiveTab === 'Dashboard' ? 'Dashboard' : 'documents'}
              createCsvLoading={createCsvLoading}
              currentUser="Vendor"
              handleCreateCSV={handleCreateCSV}
              searchProducts={searchProducts}
              setSearchProducts={setSearchProducts}
              dataAccordingTabs={dataAccordingTabs}
              selectedRowsHasPO={selectedRowsHasPO}
            />
            {loading ? (
              <Grid
                container
                sx={{
                  height: '75vh',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                <CircularProgress size={150} color="primary" />
              </Grid>
            ) : parentActiveTab === 'Dashboard' ? (
              <VendorDashboard
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            ) : (
              <DataTable
                resdata={pendingQueues}
                setCheckedIds={setCheckedIds}
                checkedIds={checkedIds}
                filterData={filteredData}
                toShow="ApiSearch"
                recordExist={recordExist}
                currentUser="Vendor"
                isIntegrated={activeIntegration}
                childActiveTab={childActiveTab}
                setDataAccordingTabs={setDataAccordingTabs}
                setSelectedRowsHasPO={setSelectedRowsHasPO}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
