const initialState = {
  allUsers: []
};

const allUsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'allUsers':
      return {
        ...state,
        allUsers: [action.payload]
      };
    default:
      return state;
  }
};
export default allUsersReducer;
