import React, { useState } from 'react';

import { ThemeProvider } from '@mui/material/styles';
import { Button, Grid, CircularProgress } from '@mui/material';

import theme from '../theme';
import CustomTextField from './textfield';
import CustomSelect from './customSelect';
import { Colors } from '../config/default';
import { addRole } from '../services/services';
import { useToast } from '../toast/toastContext';
import { isEmailValid } from '../services/validationService';

function AddUser({ getUsers, setOpen, show, data }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [role, setRole] = useState(data?.role || '');
  const [email, setEmail] = useState('');
  const [name, setName] = useState(data?.name || '');
  const [loading, setLoading] = useState(false);
  const { showToast } = useToast();

  const createNewUser = async () => {
    setLoading(true);
    const params = {
      email: email?.toLowerCase(),
      role: role === 'C Level' ? 'C_Level' : role,
      name: name
    };
    const result = await addRole(params);
    if (result?.status === 200) {
      showToast(result?.data?.metadata?.message, 'success');
      setOpen(false);
      getUsers();
    } else if (result?.response?.status === 401) {
      localStorage.clear();
      navigate('/');
    } else {
      showToast(
        result?.data?.metadata?.message || result?.response?.data?.metadata?.message,
        'error'
      );
    }

    setLoading(false);
  };
  const handleClose = (event) => {
    const selectedValue = event.target.getAttribute('data-value');
    setRole(selectedValue);
    setAnchorEl(null);
  };

  return (
    <ThemeProvider theme={theme}>
      <CustomTextField toShow={show} setValue={setEmail} value={email} placeHolder="Email" />
      <CustomTextField
        disabled={data}
        setValue={setName}
        value={name}
        placeHolder="Designation (visible to user)"
        mt="1rem"
      />
      <CustomSelect
        disabled={data}
        text="Add New Role"
        role={role}
        handleClose={handleClose}
        setAnchorEl={setAnchorEl}
        anchorEl={anchorEl}
        options={['Accountant', 'Manager', 'C Level']}
        setRole={setRole}
      />
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '1em'
        }}>
        <Button
          disabled={
            role === '' ||
            role === null ||
            email === '' ||
            loading ||
            !isEmailValid(email) ||
            name === ''
          }
          variant="contained"
          onClick={createNewUser}
          sx={{
            borderRadius: '10px',
            backgroundColor: Colors.DEEPBLUE,
            color: Colors.WHITE,
            width: '6rem',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: Colors.DEEPBLUE
            }
          }}>
          {loading ? <CircularProgress size={25} color="inherit" /> : 'Submit'}
        </Button>
      </Grid>
    </ThemeProvider>
  );
}

export default AddUser;
