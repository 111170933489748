import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { useParams, useNavigate } from 'react-router';

import { Button, Card, Grid, Typography, CircularProgress } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import {
  DashboardOutlined,
  InsertDriveFileOutlined,
  Attachment,
  CorporateFare
} from '@mui/icons-material';

import { Colors } from '../config/default';
import InvoiceList from '../components/invoiceLists';
import { getDocumentDetails, getErrorDocument } from '../services/services';
import PngImage from '../components/pngImage';
import AccountDetailList from '../components/accountDetailList';
import Layout from '../components/layout';
import { parentTabs, AdminParentTabs, clientParentTabs } from '../services/common';
import PaginationButtons from '../components/paginationButtons';
import { userRoles } from '../constants/appConstants';
import ActivityDrawer from '../components/activityDrawer';
import ErrorDetailsList from '../components/ErrorDetailsList';
import theme from '../theme';
import { documentDetails } from '../constants/appConstants';
import LineitemsDrawer from '../components/lineitemsDrawer';
import AttachmentDrawer from '../components/attachmentDrawer';

export default function VerifiedDetails() {
  const { ACCOUNTANT, CLEVEL, MANAGER, ADMIN, OWNER, VENDOR } = userRoles;
  const { BACK, DOCUMENT_DETAILS, ACCOUNT_DETAILS } = documentDetails;
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [index, setIndex] = useState(0);
  const [data, setData] = useState(null);
  const [activeTab, setActiveTab] = useState('');
  const [documentType, setDocumentType] = useState('');
  const [lineitems, setLineitems] = useState([]);
  const [documentReference, setDocumentReference] = useState('');
  const [originalQuote, setOriginalQuote] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(originalQuote.length / 1);
  const indexOfLastItem = currentPage * 1;
  const indexOfFirstItem = indexOfLastItem - 1;
  const currentItems = originalQuote?.slice(indexOfFirstItem, indexOfLastItem);
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state?.userInfo?.userInfo);
  const erpType = userDetails?.user?.erpType;
  const userRole = useSelector(
    (state) => state?.signIn?.signIn?.cognitoRes?.idToken?.payload?.given_name
  );

  const [isConstructionCompany, setIsConstructionCompany] = useState(false);
  const callSource = userRole === 'Vendor' ? 'api' : 'app';
  let content = !isEmpty(url?.standardContent) ? url?.standardContent : url?.content;
  let accountantParentTabs = [
    {
      name: 'Dashboard',
      icon: <DashboardOutlined />
    },
    {
      name: 'Documents',
      icon: <InsertDriveFileOutlined />
    },
    { name: 'Attachments', icon: <Attachment /> }
  ];
  if (erpType === 'Foundation') {
    accountantParentTabs = [
      ...accountantParentTabs,
      { name: 'Integrations', icon: <CorporateFare /> }
    ];
  }
  const { id } = useParams();
  const selectedData = async () => {
    setLoading(true);
    let result;
    if (userDetails?.role === ADMIN) {
      result = await getErrorDocument(id);
    } else {
      result = await getDocumentDetails(id, callSource);
    }
    if (result?.response?.status === 401) {
      localStorage.clear();
      navigate('/');
    } else if (result?.response?.status === 403) {
      navigate('/unauthorized');
    }
    const res = result?.data?.payload?.data?.document;
    setLineitems(res?.lineItems);
    setIsConstructionCompany(res?.constructionCompany);
    setDocumentType(res?.documentType);
    setDocumentReference(res?.documentReference);

    setUrl(res);
    setImages(
      result?.data?.payload?.data?.document?.convertedS3Keys ||
        result?.data?.payload?.data?.convertedS3Keys
    );
    setData(result?.data?.payload?.data);
    setAttachments(result?.data?.payload?.data?.attachments || []);
    setLoading(false);
  };

  useEffect(() => {
    selectedData();
  }, []);
  useEffect(() => {
    if (documentReference && !isEmpty(documentReference)) {
      showOriginalQuote(documentReference[0]?.id || documentReference);
    }
  }, [documentReference]);

  const showOriginalQuote = async (id) => {
    const res = await getDocumentDetails(id, 'app');
    if (res?.status === 200) {
      setOriginalQuote(res?.data?.payload?.data?.document?.convertedS3Keys);
    }
  };
  const handleBackward = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleForward = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const slideRight = () => {
    setIndex((index + 1) % images.length);
  };
  const slideLeft = () => {
    const nextIndex = index - 1;
    if (nextIndex < 0) {
      setIndex(images.length - 1);
    } else {
      setIndex(nextIndex);
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        sx={{
          height: '100vh',
          backgroundColor: Colors.BG_BLACK
        }}>
        <Layout
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          currentTabs={
            userRole === OWNER
              ? parentTabs
              : userRole === ACCOUNTANT
              ? accountantParentTabs
              : userRole === ADMIN
              ? AdminParentTabs
              : userRole === VENDOR
              ? clientParentTabs
              : accountantParentTabs
          }
          path="Details"
          show={userDetails?.role === OWNER ? 'showUser' : ''}
        />
        <Grid
          xs={12}
          sx={{
            mt: { xs: '2em', md: '0' },
            display: 'flex',
            justifyContent: 'space-between',
            pl: '3em',
            pr: '3em'
          }}>
          <Button
            variant="outlined"
            sx={{
              border: `1px solid ${Colors.DARKBLUE}`,
              borderRadius: '10px',
              height: '2rem',
              width: '5rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '.8em',
              textTransform: 'none',
              color: Colors.WHITE,
              '&:hover': {
                background: 'none',
                border: `1px solid ${Colors.DARKBLUE}`
              }
            }}
            onClick={() =>
              userDetails?.role === MANAGER || userDetails?.role === CLEVEL
                ? navigate('/approver')
                : userDetails?.role === ACCOUNTANT
                ? navigate('/accountant')
                : userDetails?.role === ADMIN
                ? navigate('/admin')
                : userDetails?.role === VENDOR
                ? navigate('/vendor')
                : navigate('/home')
            }>
            <ArrowBack sx={{ color: Colors.DARKBLUE, fontSize: '1.4em', mr: '.3em' }} />
            {BACK}
          </Button>
          <div style={{ display: 'flex' }}>
            {!isEmpty(attachments) && (
              <AttachmentDrawer
                data={attachments}
                setAttachments={setAttachments}
                userRole={userRole}
              />
            )}

            {userDetails?.role !== ADMIN && <ActivityDrawer id={id} documentType={documentType} />}
          </div>
        </Grid>
        {loading && (
          <Grid
            container
            sx={{
              height: '80vh',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <CircularProgress size={150} color="primary" />
          </Grid>
        )}
        {!loading && (
          <Grid
            container
            sx={{
              height: '80vh',
              alignItems: 'flex-start',
              ml: { xs: '0em', sm: '3em' },
              mr: { xs: '0em', sm: '3em' },
              justifyContent: { xs: 'center', sm: 'normal' },
              gap: { xs: '1em', xl: '2em' },
              backgroundColor: Colors.BG_BLACK,
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                width: '0em',
                height: '0em',
                borderRadius: '20px'
              },
              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.11)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.11)',
                outline: '1px solid slategrey'
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#fff',
                outline: '1px solid slategrey'
              }
            }}>
            <Card
              sx={{
                height: '67vh',
                mt: { xs: '2em', md: '0' },
                width: { xs: '70%', sm: '22%', xl: '18%' },
                backgroundColor: Colors.NAV_BLACK,
                boxShadow: 'none',
                borderRadius: '1em',
                padding: '1.5em'
              }}>
              <div>
                <div>
                  <Typography
                    sx={{
                      color: Colors.TOURQUISE,
                      fontSize: { xs: '0.80em', lg: '0.80em', xl: '1.25em' },
                      fontWeight: '600',
                      mb: '1.5em'
                    }}>
                    {DOCUMENT_DETAILS}
                  </Typography>
                </div>

                <Grid
                  item
                  sx={{
                    marginTop: '0.5em',
                    height: '59vh',
                    paddingRight: '1.25em',
                    width: '-webkit-fill-available',
                    overflowY: 'auto',
                    '&::-webkit-scrollbar': {
                      width: '0.2em',
                      height: '0em',
                      borderRadius: '20px'
                    },
                    '&::-webkit-scrollbar-track': {
                      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.11)',
                      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.11)',
                      outline: '1px solid slategrey'
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: '#fff',
                      outline: '1px solid slategrey'
                    }
                  }}>
                  {userDetails?.role === ADMIN && <ErrorDetailsList data={data} />}
                  <InvoiceList apiData={content} document={url} />
                  {userDetails?.role !== ADMIN && (
                    <div>
                      {userRole === 'Vendor' ||
                      documentType === 'Estimate' ||
                      documentType === 'DeliveryTicket' ? (
                        ''
                      ) : (
                        <>
                          <div>
                            <Typography
                              sx={{
                                color: Colors.TOURQUISE,
                                fontSize: { xs: '0.80em', lg: '0.80em', xl: '1.25em' },
                                fontWeight: '600',
                                mb: '1.5em'
                              }}>
                              {ACCOUNT_DETAILS}
                            </Typography>
                          </div>
                          <AccountDetailList Data={url} annotationType={documentType} />
                        </>
                      )}
                    </div>
                  )}
                </Grid>
              </div>
              {lineitems?.length > 0 && (
                <LineitemsDrawer
                  lineitems={lineitems}
                  isConstructionCompany={isConstructionCompany}
                  annotationType={documentType}
                />
              )}
            </Card>
            <Grid>
              {images?.length > 0 && <PngImage imageSrc={images[index]} imageAlt={index} />}
              {images?.length > 1 && (
                <PaginationButtons
                  disableFirstButton={index === 0}
                  disableSecondButton={index === images.length - 1}
                  slideLeft={slideLeft}
                  slideRight={slideRight}
                  index={index}
                  images={images}
                />
              )}
            </Grid>
            {documentType === 'Po' && images?.length > 0 && (
              <Grid>
                {currentItems?.map((s3Key, index) => (
                  <PngImage imageSrc={s3Key} imageAlt={index} />
                ))}
                {totalPages > 1 && (
                  <PaginationButtons
                    disableFirstButton={currentPage === 1}
                    disableSecondButton={currentPage === totalPages}
                    slideLeft={handleBackward}
                    slideRight={handleForward}
                    index={currentPage - 1}
                    totalPages={totalPages}
                  />
                )}
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </ThemeProvider>
  );
}
