import React, { useState } from 'react';
import { Box, Grid, Card, CardContent, Typography, Checkbox } from '@mui/material';
import { HtmlTooltip } from '../services/common';
import { Colors } from '../config/default';

export default function ImageSelect({
  data,
  url,
  fontLength,
  selectedDocumentId,
  setSelectedDocumentId,
  variant
}) {
  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date?.toLocaleDateString();
  };
  const handleFileSelect = (id) => {
    if (selectedDocumentId?.includes(id)) {
      setSelectedDocumentId(selectedDocumentId?.filter((docId) => docId !== id));
    } else {
      setSelectedDocumentId([...selectedDocumentId, id]);
    }
  };

  const [isEnlarged, setIsEnlarged] = useState(false);
  const handleImageClick = (e) => {
    setIsEnlarged(!isEnlarged);
  };
  const truncateString = (str, maxLength) => {
    if (str?.length > maxLength) {
      return str.substring(0, maxLength) + '...';
    }
    return str;
  };
  const content = data?.standardizeContent || data?.standardizeData;
  const dataArray = [
    {
      label: 'Vendor:',
      value: truncateString(content?.vendor, fontLength),
      actualValue: content?.vendor
    },
    {
      label: 'Document No:',
      value: truncateString(content?.document_number, fontLength),
      actualValue: content?.document_number
    },
    {
      label: 'Document Date:',
      value: formatDate(content?.date),
      actualValue: formatDate(content?.date)
    }
  ];

  return (
    <Grid item>
      <Card>
        <CardContent sx={{ position: 'relative' }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: '.5rem' }}>
            <Box
              className={`image-container ${isEnlarged ? 'enlargedDT' : ''}`}
              component="img"
              src={url}
              alt="Document"
              sx={{
                height: 180,
                cursor: 'pointer',
                paddingRight: '1rem',
                objectFit: 'contain'
              }}
              onClick={handleImageClick}
            />
            <Checkbox
              sx={{ position: 'absolute', top: 3, right: 0 }}
              checked={selectedDocumentId?.includes(data?._id)}
              onChange={() => handleFileSelect(data?._id)}
            />
          </Box>

          {dataArray?.map((item, i) => {
            return (
              <Box
                component="div"
                key={i}
                style={{
                  display: 'flex',
                  color: Colors.WHITE
                }}>
                <Typography variant={variant} sx={{ width: '6rem', margin: 0 }}>
                  {item?.label}
                </Typography>
                <HtmlTooltip
                  title={
                    <Typography color="inherit" sx={{ fontSize: '1em' }}>
                      {item?.actualValue}
                    </Typography>
                  }>
                  <Typography variant={variant} sx={{ margin: 0 }}>
                    {item?.value}
                  </Typography>
                </HtmlTooltip>
              </Box>
            );
          })}
        </CardContent>
      </Card>
    </Grid>
  );
}
