import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActions, Box, Grid } from '@mui/material';
import { truncateFileName } from '../services/common';
import { Colors } from '../config/default';
import Btn from './button';
import '../image.css';

export default function ImageCard({
  data,
  onClick,
  btnText,
  height,
  width,
  variant,
  fontLength,
  btnColor,
  url
}) {
  const [isEnlarged, setIsEnlarged] = useState(false);
  const handleImageClick = (e) => {
    setIsEnlarged(!isEnlarged);
  };
  const truncateString = (str, maxLength) => {
    if (str?.length > maxLength) {
      return str.substring(0, maxLength) + '...';
    }
    return str;
  };
  const content = data?.standardizeContent || data?.standardizeData;
  const dataArray = [
    {
      label: 'Invoice Name:',
      value: truncateFileName(data?.originalFileName, fontLength)
    },
    {
      label: 'Invoice No:',
      value: truncateString(content?.document_number, fontLength)
    },
    { label: 'Invoice Date:', value: content?.date },
    {
      label: 'Vendor:',
      value: truncateString(content?.vendor, fontLength)
    },
    { label: 'Total:', value: content?.total }
  ];
  const smallImageStyle = {
    cursor: 'pointer',
    opacity: 1,
    zIndex: 1,
    objectFit: 'contain'
  };

  const largeImageStyle = {
    width: '100vw',
    height: '100vh',
    objectFit: 'contain',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: isEnlarged ? 9999 : -1,
    cursor: 'pointer',
    opacity: isEnlarged ? 1 : 0
  };
  return (
    <Card
      sx={{
        width: width,
        backgroundColor: Colors.Canvas_BG,
        borderRadius: '20px',
        padding: '.5rem 0rem',
        height: height,
        marginTop: '.5rem'
      }}>
      <CardMedia
        className={btnText === '' ? `image-container ${isEnlarged ? 'enlarged' : ''}` : ''}
        component="img"
        height="185"
        image={url}
        alt="document"
        onClick={handleImageClick}
        sx={smallImageStyle}
      />

      {btnText !== '' && (
        <CardMedia
          component="img"
          height="185"
          image={url}
          alt="document"
          onClick={handleImageClick}
          sx={largeImageStyle}
        />
      )}

      <CardContent sx={{ m: 0, padding: '.5rem 1rem' }}>
        {dataArray?.map((item, i) => {
          return (
            <Box
              component="div"
              key={i}
              style={{
                display: 'flex',
                color: Colors.WHITE
              }}>
              <Typography variant={variant} sx={{ width: '7rem', margin: 0 }}>
                {item?.label}
              </Typography>
              <Typography variant={variant} sx={{ margin: 0 }}>
                {item?.value}
              </Typography>
            </Box>
          );
        })}
      </CardContent>
      {btnText !== '' && (
        <CardActions sx={{ m: 0, padding: '0 .5rem' }}>
          <Btn
            text={btnText}
            backgroundColor={btnColor}
            height="2.2rem"
            radius="10px"
            color={Colors.WHITE}
            onClick={onClick}
          />
        </CardActions>
      )}
    </Card>
  );
}
