import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Card, Grid, Typography, CircularProgress, Skeleton } from '@mui/material';

import {
  CheckCircleOutlined,
  AccessTimeOutlined,
  CloseOutlined,
  InsertDriveFileOutlined,
  VisibilityOutlined,
  HourglassTopRounded,
  ErrorOutlineOutlined,
  PanToolOutlined
} from '@mui/icons-material';

import { ThemeProvider } from '@mui/material/styles';
import theme from '../theme';
import { Colors } from '../config/default';
import { getDashboardDocumentCount, getDashboardRecord } from '../services/services';
import { convertTime } from '../services/common';
import { userRoles, dashboardText } from '../constants/appConstants';
import LineGraph from '../components/lineGraph';
import PieChartCard from '../components/pieChart';
import DateRangePickerWithButtons from '../components/DatePicker';

function Dashboard() {
  const { OWNER, ACCOUNTANT } = userRoles;
  const {
    AVERAGE_TIME,
    TIME,
    MINUTES,
    AVERAGE_CHANGES,
    CHANGES,
    CHANGE,
    VENDOR_DOCUMENTS,
    JOBID_DOCUMENTS,
    COSTID_DOCUMENTS,
    AGING_DOCUMENTS,
    AMOUNT_DOCUMENTS,
    AGING_TOTAL
  } = dashboardText;
  const [resdata, setResData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [type, setType] = useState('By Vendor');
  const [status, setStatus] = useState('All');
  const [agingType, setAgingType] = useState('By Count');
  const [documentCount, setDocumentCount] = useState();
  const [documentCountLoader, setDocumentCountLoader] = useState(false);
  const userRole = useSelector(
    (state) => state?.signIn?.signIn?.cognitoRes?.idToken?.payload?.given_name
  );
  const isConstructionCompany = useSelector(
    (state) => state?.userInfo?.userInfo?.user?.constructionCompany
  );
  const navigate = useNavigate();
  const handleClose = (event) => {
    const selectedValue = event?.target?.getAttribute('data-value');
    setType(selectedValue);
    setAnchorEl(null);
  };
  const handleCloseAgingFilter = (event) => {
    const selectedValue = event?.target?.getAttribute('data-value');
    setAgingType(selectedValue);
    setAnchorEl(null);
  };
  function convertTimeToSeconds(time) {
    const match = time.match(/(\d+)h?:?(\d*)min?:?(\d*)sec?/);
    if (!match) {
      return 0;
    }
    const [, hours, minutes, seconds] = match.map((part) => (part === '' ? 0 : Number(part)));
    return hours * 60 + minutes;
  }
  const getData = async (start, end, byType) => {
    setLoading(true);
    if (start && end) {
      start.setHours(0, 0, 0, 0);
      end.setHours(23, 59, 59, 999);
      const utcStartDate = new Date(start.getTime() - start.getTimezoneOffset() * 60000);
      const utcEndDate = new Date(end.getTime() - end.getTimezoneOffset() * 60000);
      const result = await getDashboardRecord(
        utcStartDate?.toISOString(),
        utcEndDate?.toISOString(),
        byType,
        'app'
      );
      if (result.status === 200) {
        setResData(result?.data?.payload?.data);
        setType(result?.data?.payload?.data?.vendorType);
      } else if (result?.response?.status === 401) {
        localStorage.clear();
        navigate('/');
      } else {
        setResData(null);
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    getData(startDate, endDate, type);
  }, [startDate, endDate]);
  const processedData = resdata?.pendingToVerified?.averageTimeByDate?.map((item) => ({
    date: item.date,
    value: convertTimeToSeconds(item?.averageTimeToApproved)
  }));

  const getDocumentCount = async () => {
    setDocumentCountLoader(true);
    const res = await getDashboardDocumentCount();
    if (res?.status === 200) {
      setDocumentCount(res?.data?.payload?.data);
    }
    setDocumentCountLoader(false);
  };
  useEffect(() => {
    getDocumentCount();
  }, []);

  const documentsByStatus =
    status === 'All'
      ? resdata?.vendorsDocumentAnalytics
      : status === 'Approved'
      ? resdata?.vendorsDocumentAnalyticsForApproved
      : status === 'Populated'
      ? resdata?.vendorsDocumentAnalyticsForPopulated
      : status === 'on Hold'
      ? resdata?.vendorsDocumentAnalyticsForOnHold
      : [];
  const vendorData = documentsByStatus?.map((vendor, i) => ({
    id: i,
    value: vendor?.DocumentsCount,
    label: vendor?.vendorName
  }));
  const amountData = documentsByStatus?.map((vendor, i) => ({
    id: i,
    value: vendor?.totalSumOfInvoices,
    label: vendor?.vendorName
  }));
  const costIdData = resdata?.costIdAnalysis?.map((costID, i) => ({
    id: i,
    value: costID?.count,
    label: costID?.id
  }));
  const jobIdData = resdata?.jobIdAnalysis?.map((jobID, i) => ({
    id: i,
    value: jobID?.count,
    label: jobID?.id
  }));
  const agingData = resdata?.docAge?.map((aging, i) => ({
    id: i,
    value: aging?.value,
    label: aging?.label
  }));
  const totalData = resdata?.docAge?.map((aging, i) => ({
    id: i,
    value: aging?.sum,
    label: aging?.label
  }));

  const documents = [
    userRole === OWNER || userRole === ACCOUNTANT
      ? {
          status: 'Processing',
          value: documentCount?.Pending,
          icon: <HourglassTopRounded sx={{ color: Colors.LIGHTBLUE, marginRight: '.5em' }} />,
          color: Colors.LIGHTBLUE
        }
      : {
          status: 'On Hold',
          value: documentCount?.['on Hold'],
          icon: <PanToolOutlined sx={{ color: Colors.LIGHTBLUE, marginRight: '.5em' }} />,
          color: Colors.LIGHTBLUE
        },
    {
      status: 'Pending',
      value: documentCount?.Populated,
      icon: <AccessTimeOutlined sx={{ color: Colors.YELLOW, marginRight: '.5em' }} />,
      color: Colors.YELLOW
    },
    {
      status: 'Error',
      value: documentCount?.Error,
      icon: <ErrorOutlineOutlined sx={{ color: Colors.RED, marginRight: '.5em' }} />,
      color: Colors.RED
    },
    {
      status: 'Approved',
      value: documentCount?.Approved,
      icon: <CheckCircleOutlined sx={{ color: Colors.LIGHTBLUE, marginRight: '.5em' }} />,
      color: Colors.LIGHTBLUE
    },
    {
      status: 'In Review',
      value: documentCount?.['Waiting For Approval'],
      icon: <VisibilityOutlined sx={{ color: Colors.YELLOW, marginRight: '.5em' }} />,
      color: Colors.YELLOW
    },

    {
      status: 'Rejected',
      value: documentCount?.Reject,
      icon: <CloseOutlined sx={{ color: Colors.RED, marginRight: '.5em' }} />,
      color: Colors.RED
    },
    {
      status: 'Total',
      value: documentCount?.totalDocuments,
      icon: <InsertDriveFileOutlined sx={{ color: Colors.LIGHTBLUE, marginRight: '.5em' }} />,
      color: Colors.LIGHTBLUE
    }
  ];
  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        sx={{
          justifyContent: 'center',
          marginTop: '.7rem',
          height: { xs: '59vh', sm: '75vh' },
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            width: '.2em',
            height: '0em',
            borderRadius: '20px'
          },
          '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.11)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.11)',
            outline: '1px solid slategrey'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#fff',
            outline: '1px solid slategrey'
          }
        }}>
        <Grid
          container
          item
          xs={12}
          sx={{
            justifyContent: { xs: '', lg: 'space-between' },
            paddingX: '2rem',
            marginTop: { xs: '1.25rem', sm: '.3rem' },
            flexWrap: 'wrap',
            gap: { xs: '1em', lg: '0em' }
          }}>
          {documents?.map((document, i) => {
            return (
              <Card
                key={i}
                sx={{
                  border: `2px solid ${document?.color}`,
                  borderRadius: '20px',
                  width: { xs: '100%', sm: '20%', lg: '9.35vw' },
                  height: '4.5rem',
                  padding: '1rem'
                }}>
                <div style={{ display: 'flex' }}>
                  {document?.icon}
                  <Typography sx={{ fontSize: '1rem', color: document?.color }}>
                    {document?.status}
                  </Typography>
                </div>
                {documentCountLoader ? (
                  <Skeleton style={{ height: '3rem', width: '3rem' }} animation="wave" />
                ) : (
                  <Typography sx={{ fontSize: '2rem' }}>{document?.value}</Typography>
                )}
              </Card>
            );
          })}
        </Grid>

        <Grid
          xs={12}
          container
          sx={{
            justifyContent: 'flex-end',
            marginTop: '1em',
            paddingRight: '2rem'
          }}>
          <DateRangePickerWithButtons
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
        </Grid>
        {loading || resdata === null ? (
          <Grid
            container
            sx={{
              height: '60vh',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <CircularProgress size={100} color="primary" />
          </Grid>
        ) : (
          <>
            <Grid
              container
              item
              xs={12}
              sx={{
                justifyContent: 'space-between',
                gap: { xs: '1em', sm: '1em', md: '0' },
                paddingX: '2rem'
              }}>
              <LineGraph
                average={AVERAGE_TIME}
                averageVal={
                  convertTime(resdata?.pendingToVerified?.averageTimeOfApprovedDocuments) || 0
                }
                averageHeading={TIME}
                target={MINUTES}
                dataSet={processedData}
                xAxisType="date"
                yAxisType="time"
                xAxisKey="date"
                yAxisKey="value"
                dataKey="value"
                midScreen={true}
              />
              <LineGraph
                average={AVERAGE_CHANGES}
                averageVal={resdata?.documentActivityChanges?.avgActivityChanges}
                averageHeading={CHANGES}
                target={CHANGE}
                dataSet={resdata?.documentActivityChanges?.averageTimeByDate}
                xAxisType="date"
                xAxisKey="date"
                yAxisKey="averageChanges"
                dataKey="averageChanges"
              />
            </Grid>
            <Grid
              container
              item
              xs={12}
              sx={{
                justifyContent:
                  (jobIdData?.length > 0 && isConstructionCompany) ||
                  (costIdData?.length > 0 && isConstructionCompany)
                    ? 'space-between'
                    : { xs: 'space-between', xl: 'flex-start' },
                paddingX: '2rem',
                gap:
                  (jobIdData?.length > 0 && isConstructionCompany) ||
                  (costIdData?.length > 0 && isConstructionCompany)
                    ? '0em'
                    : { xl: '3.3%' }
              }}>
              <PieChartCard
                setAnchorEl={setAnchorEl}
                anchorEl={anchorEl}
                type={type}
                setType={setType}
                handleClose={handleClose}
                showSelect={true}
                text={type === 'By Vendor' ? VENDOR_DOCUMENTS : AMOUNT_DOCUMENTS}
                dataArray={type === 'By Vendor' ? vendorData : amountData}
                status={status}
                setStatus={setStatus}
                showStatus={true}
                option1="By Vendor"
                option2="By Amount"
              />
              <PieChartCard
                text={agingType === 'By Count' ? AGING_DOCUMENTS : AGING_TOTAL}
                dataArray={agingType === 'By Count' ? agingData : totalData}
                type={agingType}
                showSelect={true}
                setType={setAgingType}
                handleClose={handleCloseAgingFilter}
                option1="By Count"
                option2="By Total"
                setAnchorEl={setAnchorEl}
                anchorEl={anchorEl}
              />
              <PieChartCard text={JOBID_DOCUMENTS} dataArray={jobIdData} />
              <PieChartCard text={COSTID_DOCUMENTS} dataArray={costIdData} />
            </Grid>
          </>
        )}
      </Grid>
    </ThemeProvider>
  );
}

export default Dashboard;
