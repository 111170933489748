import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs, Tab, Typography, Box } from '@mui/material';
import theme from '../theme';
import { ThemeProvider } from '@mui/material/styles';
import { Colors } from '../config/default';
import { get_tabs } from '../redux/actions/action';
import { userRoles } from '../constants/appConstants';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <ThemeProvider
      theme={theme}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </ThemeProvider>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

export default function TabPanel({
  tabs,
  peddingBottom,
  activeTab,
  setActiveTab,
  tab,
  handleClear,
  filteredData,
  recordExist,
  path,
  orientation,
  setCheckedIds,
  handleClearAttachmentSearch,
  handleClearErpSearch
}) {
  const { ADMIN, CLEVEL, ACCOUNTANT, MANAGER, VENDOR } = userRoles;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const globalActiveTab = useSelector((state) => state?.tabs?.tabs);
  const userRole = useSelector(
    (state) => state?.signIn?.signIn?.cognitoRes?.idToken?.payload?.given_name
  );
  const handleChange = (e, tabName) => {
    if ((tab === 'parent' || tab === 'child') && (!isEmpty(filteredData) || recordExist)) {
      handleClear();
    }
    if (tab === 'parent' && handleClearAttachmentSearch) {
      handleClearAttachmentSearch();
    }
    if (handleClearErpSearch) {
      handleClearErpSearch();
    }
    localStorage.setItem('chequeNo', '');
    localStorage.setItem('instrumentId', '');
    setActiveTab(tabName);
    if (tab !== 'parent') {
      setCheckedIds([]);
    }
    if (path === 'profile' || path === 'Details' || path === 'users') {
      dispatch(
        get_tabs({
          activeTab: tabName,
          documentChildActiveTab: globalActiveTab?.documentChildActiveTab,
          IntegrationChildActiveTab: globalActiveTab?.IntegrationChildActiveTab
        })
      );
      userRole === ADMIN
        ? navigate('/admin')
        : userRole === CLEVEL || userRole === MANAGER
        ? navigate('/approver')
        : userRole === ACCOUNTANT
        ? navigate('/accountant')
        : userRole === VENDOR
        ? navigate('/vendor')
        : navigate('/home');
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          width: 'fit-content',
          height: { xs: 'auto', lg: '4.5rem' },
          marginBottom: { md: '0rem', lg: '0rem' },
          overflowX: { xs: tab === 'parent' || tab === 'child' ? 'scroll' : '', md: 'hidden' }
        }}>
        <Box>
          <Tabs
            orientation={orientation ? 'vertical' : ''}
            onChange={handleChange}
            value={activeTab}
            variant="scrollable"
            scrollButtons="auto"
            TabIndicatorProps={{
              sx: {
                backgroundColor: 'secondary.light',
                height: '5px',
                width: { xs: '0.25rem', sm: '5rem', lg: '9.5rem' }
              }
            }}>
            {tabs?.map((childTab, index) => (
              <Tab
                icon={childTab.icon}
                iconPosition="start"
                label={childTab.name}
                value={childTab.name}
                key={childTab.name}
                sx={{
                  fontSize: '1rem',
                  fontFamily: 'Roboto',
                  textTransform: 'none',
                  paddingBottom: peddingBottom,
                  fontWeight: 400,
                  display: 'flex',
                  justifyContent: 'start',
                  marginRight: '1rem',
                  '&.Mui-selected': {
                    color: Colors.WHITE
                  }
                }}
              />
            ))}
          </Tabs>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
