import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { Colors } from '../config/default';

export default function Navigation({ value, setValue, label1, label2 }) {
  return (
    <Box
      sx={{
        width: 'max-content'
      }}>
      <BottomNavigation
        sx={{
          backgroundColor: Colors.NAV_BLACK,
          borderRadius: '10px',
          height: '2.2rem'
        }}
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}>
        <BottomNavigationAction
          label={label1}
          sx={{
            fontSize: '1.2rem'
          }}
        />
        <BottomNavigationAction
          label={label2}
          sx={{
            fontSize: '1.2rem'
          }}
        />
      </BottomNavigation>
    </Box>
  );
}
