import React from 'react';
import { useSelector } from 'react-redux';

import {
  Table,
  Grid,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  TableBody,
  Autocomplete,
  TextField,
  IconButton,
  Button
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { Colors } from '../config/default';
import { TooltipMessages } from '../constants/appConstants';
import {
  Error,
  Remove,
  AttachMoney,
  ShoppingCart,
  DescriptionOutlined,
  RequestQuoteOutlined,
  InsertDriveFileOutlined,
  Close,
  Add,
  ContentCopy,
  Description
} from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { replaceName } from '../services/common';
import { isEmpty } from 'lodash';
import { arrangedLineitems } from '../services/common';
import { validateLineItems } from '../services/validationService';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    color: `white !important`,
    borderBottom: 'none',
    backgroundColor: Colors.Canvas_Left_BG,
    position: 'sticky',
    top: 0,
    zIndex: 999
  },
  [`&.${tableCellClasses.body}`]: {
    color: `${Colors.WHITE} !important`,
    borderBottom: 'none',
    padding: '.5rem'
  }
}));
const iconMap = {
  Description: DescriptionOutlined,
  Amount: AttachMoney,
  Unit_Price: AttachMoney,
  Quantity: ShoppingCart,
  Product_Code: DescriptionOutlined,
  userCostType: RequestQuoteOutlined,
  userCostId: RequestQuoteOutlined,
  userJobId: InsertDriveFileOutlined,
  GlAccount: DescriptionOutlined,
  userPhases: InsertDriveFileOutlined,
  equipmentNo: DescriptionOutlined,
  serviceCode: DescriptionOutlined
};
export default function LineItems({
  setDrawerOpen,
  itemsData,
  handleClick,
  setRef,
  setLineItemsData,
  isLineItemsMatched,
  isPoMatched,
  obj,
  setObj,
  annotationType,
  data,
  constructionCompany,
  lineItem,
  setTolalItems,
  totalItems,
  headers,
  handleFieldChange,
  handleBoxClick,
  imageDimensions,
  setClickedBoxIndex,
  isInvoiceNumberMatched,
  serviceAndEquipmentPermission
}) {
  const smallScreen = useMediaQuery('(max-width:1000px)');
  const smallToLarge = useMediaQuery('(max-width:2200px)');
  const findItemHeight = (ref) => {
    const lineItems = arrangedLineitems(lineItem);
    for (let parentIndex = 0; parentIndex < lineItems.length; parentIndex++) {
      const elements = lineItems[parentIndex];
      for (let idx = 0; idx < elements.length; idx++) {
        const items = elements[idx];
        if ('box_' + items?.value + '**%**' + parentIndex + idx === ref) {
          return items.top * imageDimensions.height + items.height;
        }
      }
    }
    return null;
  };

  const userDetails = useSelector((state) => state?.userInfo?.userInfo);
  const erpType = userDetails?.user?.erpType;
  const { LINEITEMS_NOT_FOUND, AMOUNT_NOT_MATCHED, DUPLICATE_LINEITEM } = TooltipMessages;
  if (annotationType === 'Estimate' || annotationType === 'DeliveryTicket') {
    const itemsToRemove = ['GlAccount', 'userJobId', 'userPhases', 'userCostId', 'userCostType'];
    headers = headers.filter((item) => !itemsToRemove.includes(item));
  }
  if (erpType === 'QuickBooksOnline' || erpType === 'QuickBooksDesktop') {
    const itemsToRemove = ['userPhases'];
    headers = headers.filter((item) => !itemsToRemove.includes(item));
  }
  if (erpType === 'DeltekComputerEase' || erpType === 'QuickBooksOnline') {
    const itemsToRemove = ['userCostId'];
    headers = headers.filter((item) => !itemsToRemove.includes(item));
  }
  if (!serviceAndEquipmentPermission) {
    const itemsToRemove = ['equipmentNo', 'serviceCode'];
    headers = headers.filter((item) => !itemsToRemove.includes(item));
  }
  const handleSelectChange = (value, index, fieldName) => {
    const updatedValue = value !== null ? value : '';
    setObj((prevObj) => {
      const newObj = { ...prevObj };
      newObj[fieldName][index] = updatedValue || '';
      setLineItemsData(newObj);
      return newObj;
    });
  };

  const AddNewItem = () => {
    if (isEmpty(lineItem)) {
      setObj((prevObj) => {
        const newObj = { ...prevObj };
        headers?.forEach((fieldName) => {
          newObj[fieldName] = [''];
        });
        setLineItemsData(newObj);
        setTolalItems([0]);
        const newLineItem = Array.from({ length: headers?.length || 0 }, (_, index) => {
          return {
            name: headers[index],
            value: '',
            width: null,
            height: null,
            left: null
          };
        });
        lineItem.push(newLineItem);
        return newObj;
      });
    } else {
      setObj((prevObj) => {
        const newObj = { ...prevObj };
        headers?.forEach((fieldName) => {
          newObj[fieldName]?.push('');
        });
        setLineItemsData(newObj);
        setTolalItems((prevArr) => [...prevArr, 0]);
        const newItem = Array.from({ length: headers?.length || 0 }, (_, index) => {
          return {
            name: headers[index],
            value: '',
            width: null,
            height: null,
            left: null
          };
        });

        lineItem.push(newItem);
        return newObj;
      });
    }
  };

  const duplicateLineItem = (index) => {
    setObj((prevObj) => {
      const newObj = { ...prevObj };
      let filledValues = {};
      headers?.forEach((fieldName) => {
        if (
          fieldName !== 'userCostType' &&
          fieldName !== 'userCostId' &&
          fieldName !== 'userJobId' &&
          fieldName !== 'userPhases'
        ) {
          newObj[fieldName].splice(index + 1, 0, newObj[fieldName][index]);
        } else {
          filledValues[fieldName] = prevObj[fieldName][index];
          newObj[fieldName].splice(index + 1, 0, filledValues[fieldName]);
        }
      });
      setLineItemsData(newObj);
      setTolalItems((prevArr) => [...prevArr, 0]);
      const newLineItem = Array.from({ length: headers?.length || 0 }, (_, idx) => {
        return { ...lineItem[index][idx] };
      });
      lineItem.splice(index + 1, 0, newLineItem);
      return newObj;
    });
  };

  const useStyles = makeStyles((theme) => ({
    clearIndicator: {
      color: Colors.WHITE
    },
    option: {
      borderRadius: '7px',
      '&:hover': {
        borderRadius: '7px',
        backgroundColor: '#141414 !important'
      },
      '&:active': {
        borderRadius: '7px'
      }
    }
  }));
  const classes = useStyles();
  const handleRemoveRow = (indexToRemove) => {
    setObj((prevObj) => {
      const newObj = { ...prevObj };
      headers?.forEach((fieldName) => {
        newObj[fieldName]?.splice(indexToRemove, 1);
      });
      setLineItemsData(newObj);
      lineItem?.splice(indexToRemove, 1);
      const updatedTotalItems = [...totalItems];
      updatedTotalItems?.splice(indexToRemove, 1);
      setTolalItems(updatedTotalItems);

      return newObj;
    });
  };
  const getVisibleHeaders = (headers) => {
    return headers.filter((element) => {
      if (
        (element === 'userCostType' && !constructionCompany) ||
        (element === 'userPhases' && !constructionCompany) ||
        (element === 'userJobId' && !constructionCompany) ||
        (element === 'userCostId' && !constructionCompany) ||
        (element === 'Product_Code' &&
          annotationType !== 'Invoice' &&
          annotationType !== 'Estimate') ||
        ((!serviceAndEquipmentPermission ||
          erpType !== 'Foundation' ||
          (erpType === 'Foundation' && annotationType !== 'Invoice')) &&
          (element === 'equipmentNo' || element === 'serviceCode'))
      ) {
        return false;
      }
      return true;
    });
  };
  const visibleHeaders = getVisibleHeaders(headers);
  const filteredHeaders = visibleHeaders?.filter((header) => header !== undefined);
  const headerCount = filteredHeaders?.length;
  const cellWidth = `${100 / headerCount}%`;

  return (
    <Grid
      container
      xs={12}
      sx={{
        backgroundColor: Colors.NAV_BLACK,
        height: '77%',
        marginTop: '.2rem'
      }}>
      <TableContainer
        sx={{
          marginBottom: '1rem',
          height: '100%',
          overflowX: 'auto',
          overflowY: itemsData?.length > 2 ? 'scroll' : '',
          '&::-webkit-scrollbar': {
            width: '0.2em',
            height: '.2em'
          },
          '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            outline: '1px solid slategrey'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#fff',
            outline: '1px solid slategrey'
          }
        }}>
        <Table
          size="medium"
          sx={{
            width:
              annotationType === 'Invoice' &&
              erpType === 'Foundation' &&
              constructionCompany &&
              smallToLarge
                ? '120vw'
                : '100%',
            backgroundColor: Colors.NAV_BLACK,
            color: Colors.TEXTGREY
          }}
          aria-label="simple table">
          <TableHead>
            <TableRow>
              {visibleHeaders?.map((element, i) => {
                return (
                  element && (
                    <StyledTableCell
                      sx={{
                        width: cellWidth
                      }}
                      key={i}>
                      <span
                        style={{
                          whiteSpace: 'nowrap',
                          cursor: 'default',
                          display: 'flex',
                          alignItems: 'center'
                        }}>
                        {iconMap[element]
                          ? React.createElement(iconMap[element], {
                              style: { fontSize: '1.2rem', color: Colors.TOURQUISE }
                            })
                          : null}
                        {replaceName(element, erpType)}
                      </span>
                    </StyledTableCell>
                  )
                );
              })}
              <StyledTableCell
                sx={{
                  width: '20px',
                  p: 0,
                  border: 'none'
                }}>
                <div style={{ display: 'flex' }}>
                  <IconButton sx={{ marginRight: '1rem' }} onClick={() => setDrawerOpen(false)}>
                    <Close sx={{ color: Colors.TEXTGREY, cursor: 'pointer' }} />
                  </IconButton>
                </div>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ backgroundColor: Colors.NAV_BLACK }}>
            {totalItems &&
              Array.isArray(totalItems) &&
              totalItems?.map((x, index) => (
                <TableRow
                  sx={{
                    border: 'none',
                    backgroundColor: index % 2 === 0 ? Colors.NAV_BLACK : Colors.GREY,
                    borderRadius: '20px',
                    margin: '0rem 2rem !important'
                  }}>
                  {visibleHeaders?.map((items, idx) => {
                    let isMatched;
                    let isAmountMatched;
                    let isDescriptionMatched;
                    isPoMatched || isInvoiceNumberMatched?.status
                      ? ((isMatched = isLineItemsMatched && isLineItemsMatched[index]?.match),
                        (isAmountMatched =
                          isLineItemsMatched && isLineItemsMatched[index]?.data?.total),
                        (isDescriptionMatched =
                          isLineItemsMatched && isLineItemsMatched[index]?.data?.description))
                      : '';
                    const itemValue =
                      obj && obj[items] && obj[items][index] !== undefined ? obj[items][index] : '';
                    const iteMdata = itemsData && itemsData[index] ? itemsData[index] : '';
                    const boxIndex = `${index}${idx}`;
                    const isError = validateLineItems(
                      items,
                      itemValue,
                      annotationType,
                      erpType,
                      constructionCompany
                    );
                    return (
                      items && (
                        <StyledTableCell
                          sx={{
                            flex: 1
                          }}
                          ref={setRef('button_' + itemValue + '**%**' + index + idx)}
                          onClick={() => {
                            if (!smallScreen) {
                              setClickedBoxIndex(boxIndex);
                              const documentTypeIndex =
                                annotationType !== 'Invoice' && annotationType !== 'Estimate'
                                  ? idx + 1
                                  : idx;
                              handleBoxClick(
                                items,
                                itemValue,
                                'box_' + itemValue + '**%**' + index + documentTypeIndex,
                                findItemHeight(
                                  'box_' + itemValue + '**%**' + index + documentTypeIndex
                                )
                              );

                              handleClick(
                                itemValue + '**%**' + index + idx,
                                itemValue + '**%**' + index + documentTypeIndex,
                                documentTypeIndex,
                                iteMdata,
                                false
                              );
                            }
                          }}>
                          {items === 'userCostType' ||
                          items === 'userCostId' ||
                          items === 'userJobId' ||
                          items === 'GlAccount' ||
                          items === 'userPhases' ||
                          items === 'serviceCode' ||
                          items === 'equipmentNo' ? (
                            data &&
                            data?.map((acc) => {
                              if (acc?.name === items) {
                                return acc?.value?.length > 0 && !acc?.value?.includes(null) ? (
                                  <Grid
                                    item
                                    xs={12}
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center'
                                    }}>
                                    <Autocomplete
                                      value={obj[items][index] || null}
                                      onChange={(event, value) =>
                                        handleSelectChange(value, index, items)
                                      }
                                      inputValue={obj[items][index]}
                                      onInputChange={(event, newInputValue) => {
                                        handleSelectChange(newInputValue, index, items);
                                      }}
                                      options={acc?.value}
                                      renderOption={(props, option) => {
                                        const { key } = props;
                                        return (
                                          <li
                                            {...props}
                                            key={props['data-option-index'] + '-' + key}>
                                            {option}
                                          </li>
                                        );
                                      }}
                                      disableClearable={!obj[items][index]}
                                      classes={{
                                        clearIndicator: classes.clearIndicator,
                                        option: classes.option
                                      }}
                                      ListboxProps={{
                                        className: 'myCustomList'
                                      }}
                                      sx={{
                                        marginBottom: '.4rem',
                                        '& .MuiAutocomplete-popupIndicator': {
                                          color: Colors.WHITE
                                        }
                                      }}
                                      PaperComponent={({ children }) => (
                                        <Paper
                                          style={{
                                            backgroundColor: Colors.NAV_BLACK,
                                            color: Colors.WHITE,
                                            borderRadius: '10px',
                                            padding: '0px 8px'
                                          }}>
                                          {children}
                                        </Paper>
                                      )}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          variant="standard"
                                          InputProps={{
                                            ...params.InputProps,
                                            sx: {
                                              color: Colors.WHITE,
                                              fontSize: '13px',
                                              width: !isError?.status ? '9vw' : '9.8vw'
                                            }
                                          }}
                                        />
                                      )}
                                    />
                                    {!isError?.status && (
                                      <Tooltip title={isError?.message}>
                                        <Error
                                          sx={{
                                            fontSize: '20px',
                                            color: Colors.RED
                                          }}
                                        />
                                      </Tooltip>
                                    )}
                                  </Grid>
                                ) : (
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                      style={{
                                        border: 'none',
                                        whiteSpace: 'nowrap',
                                        color: Colors.WHITE,
                                        backgroundColor: 'transparent',
                                        outline: 'none',
                                        padding: '0px !important',
                                        width: '100%',
                                        borderBottom: '1px solid black'
                                      }}
                                      value={obj[items][index]}
                                      onChange={(e) => handleFieldChange(e, index, items)}
                                    />
                                    {!isError?.status && (
                                      <Tooltip title={isError?.message}>
                                        <Error
                                          sx={{
                                            fontSize: '20px',
                                            color: Colors.RED
                                          }}
                                        />
                                      </Tooltip>
                                    )}
                                  </div>
                                );
                              }
                            })
                          ) : (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              {((items === 'Product_Code' &&
                                (annotationType === 'Invoice' || annotationType === 'Estimate')) ||
                                (items === 'Description' &&
                                  annotationType !== 'Invoice' &&
                                  annotationType !== 'Estimate')) && (
                                <Tooltip title={DUPLICATE_LINEITEM}>
                                  <IconButton onClick={() => duplicateLineItem(index)}>
                                    <ContentCopy
                                      sx={{ color: Colors.TOURQUISE, cursor: 'pointer' }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              )}
                              <input
                                style={{
                                  border: 'none',
                                  whiteSpace: 'nowrap',
                                  color: Colors.WHITE,
                                  backgroundColor: 'transparent',
                                  outline: 'none',
                                  padding: '0px !important',
                                  width: '100%',
                                  borderBottom: '1px solid black'
                                }}
                                value={obj[items][index]}
                                onChange={(e) => handleFieldChange(e, index, items)}
                              />
                              {!isError?.status ? (
                                <Tooltip title={isError?.message}>
                                  <Error
                                    sx={{
                                      fontSize: '20px',
                                      color: Colors.RED
                                    }}
                                  />
                                </Tooltip>
                              ) : !isMatched &&
                                (isPoMatched || isInvoiceNumberMatched?.status) &&
                                annotationType !== 'Estimate' &&
                                annotationType !== 'DeliveryTicket' &&
                                annotationType !== 'Po' ? (
                                items === 'Description' &&
                                isDescriptionMatched?.status === false ? (
                                  <Tooltip
                                    title={
                                      isDescriptionMatched?.value === ''
                                        ? LINEITEMS_NOT_FOUND
                                        : `Description is "${isDescriptionMatched?.value}" in the supporting document.`
                                    }>
                                    <Error
                                      sx={{
                                        fontSize: '20px',
                                        color: Colors.YELLOW
                                      }}
                                    />
                                  </Tooltip>
                                ) : items === 'Amount' &&
                                  isDescriptionMatched?.status &&
                                  isAmountMatched?.status === false ? (
                                  <Tooltip
                                    title={`Amount is "${isAmountMatched?.value}" in the supporting document`}>
                                    <Error
                                      sx={{
                                        fontSize: '20px',
                                        color: Colors.YELLOW
                                      }}
                                    />
                                  </Tooltip>
                                ) : (
                                  ''
                                )
                              ) : (
                                ''
                              )}
                            </div>
                          )}
                        </StyledTableCell>
                      )
                    );
                  })}
                  <StyledTableCell
                    sx={{
                      p: 0,
                      border: 'none'
                    }}>
                    <Tooltip title="Remove Line item">
                      <IconButton onClick={() => handleRemoveRow(index)}>
                        <Remove sx={{ color: Colors.RED, cursor: 'pointer' }} />
                      </IconButton>
                    </Tooltip>
                  </StyledTableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <Grid
          container
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            border: 'none',
            height: !isEmpty(lineItem) ? 'auto' : '140px'
          }}>
          <Button
            startIcon={<Add />}
            sx={{
              color: Colors.TOURQUISE,
              textTransform: 'none',
              marginTop: isEmpty(lineItem) ? '1.5rem' : '0rem'
            }}
            onClick={AddNewItem}>
            Add New Line Item
          </Button>
        </Grid>
      </TableContainer>
    </Grid>
  );
}
