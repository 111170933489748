import React, { useState, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';

import { Grid } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { dropzoneData } from '../constants/appConstants';

import theme from '../theme';
import { Colors } from '../config/default';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: '15px',
  border: 'none',
  color: '#bdbdbd',
  outline: 'none',
  cursor: 'pointer',
  backgroundColor: Colors.Canvas_BG,
  fontSize: '.9rem'
};

const focusedStyle = {
  borderColor: Colors.DEEPGREY
};

const acceptStyle = {
  borderColor: Colors.TOURQUISE
};

const rejectStyle = {
  borderColor: Colors.RED
};

export default function AttachmentDropzone({ setFiles }) {
  const onDrop = (acceptedFiles) => {
    setFiles(acceptedFiles);
  };
  const { acceptedFiles, getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
      multiple: true
    });

  const { UPLOADING_INFO } = dropzoneData;

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        item
        xs={12}
        sx={{
          justifyContent: 'center',
          marginBottom: '1rem'
        }}>
        <section className="container" style={{ width: '100%' }}>
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} type="file" accept="application/pdf" />
            <Grid
              item
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <CloudUploadIcon sx={{ color: Colors.DEEPBLUE, mr: '.5rem' }} />
              <p style={{ textAlign: 'center', color: Colors.WHITE }}>{UPLOADING_INFO}</p>
            </Grid>
          </div>
        </section>
      </Grid>
    </ThemeProvider>
  );
}
