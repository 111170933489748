import React from 'react';
import { useSelector } from 'react-redux';
import { useToast } from '../toast/toastContext';

import { Button, CircularProgress, styled, Typography } from '@mui/material';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Colors } from '../config/default';
import { HtmlTooltip } from '../services/common';
import { accountDetailsComponent, documentCommentsText } from '../constants/appConstants';
import erpConfig from '../config/erpConfig';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '10px',
    backgroundColor: '#282828',
    marginTop: theme.spacing(1),
    minWidth: 160,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '.5em'
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: Colors.NAV_BLACK,
        borderRadius: '8px'
      },
      '&:hover': {
        backgroundColor: Colors.NAV_BLACK,
        borderRadius: '8px'
      }
    }
  }
}));

export default function DownloadFile({
  disabled,
  height,
  width,
  text,
  loadingCheckbox,
  icon,
  tooltipText
}) {
  const {
    GL_ACCOUNT,
    COST_ID,
    JOB_ID,
    VENDOR,
    PURCHASE_ORDER,
    COST_TYPE,
    PHASE,
    CLASS,
    PROJECT_ID
  } = accountDetailsComponent;
  const { ERROR_DOWNLOADING_CSV } = documentCommentsText;
  const { showToast } = useToast();
  const userDetails = useSelector((state) => state?.userInfo?.userInfo);
  const erpType = userDetails?.user?.erpType;
  const isConstructionCompany = userDetails?.user?.constructionCompany;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const csvPaths = erpConfig.csvPaths;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownloadCSVSample = (fileName, path) => {
    const fileUrl = path;

    fetch(fileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(link.href);
        setAnchorEl(null);
      })
      .catch((error) => {
        showToast(ERROR_DOWNLOADING_CSV, 'error');
      });
  };
  return (
    <div>
      <HtmlTooltip
        title={
          <Typography color="inherit" sx={{ fontSize: '1em' }}>
            {tooltipText}
          </Typography>
        }>
        <span>
          <Button
            disabled={disabled}
            sx={{
              width: { xs: 'max-content', lg: width },
              textTransform: 'unset !important',
              marginLeft: { md: '1em' },
              marginTop: { xs: '1em', sm: '0' },
              borderColor: Colors.DEEPBLUE,
              color: Colors.WHITE,
              height: height,
              borderRadius: '10px',
              '&:hover': {
                borderColor: Colors.DEEPBLUE
              }
            }}
            id="demo-customized-button"
            aria-controls={open ? 'demo-customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant="outlined"
            disableElevation
            onClick={handleClick}>
            {loadingCheckbox ? (
              <CircularProgress size={25} color="inherit" />
            ) : (
              <>
                {icon}
                {text}
              </>
            )}
          </Button>
        </span>
      </HtmlTooltip>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button'
        }}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}>
        {erpType !== 'DeltekComputerEase' && (
          <MenuItem onClick={() => handleDownloadCSVSample('GL Accounts.csv', csvPaths.GLAccounts)}>
            {GL_ACCOUNT}
          </MenuItem>
        )}
        <MenuItem
          onClick={() => handleDownloadCSVSample('Vendor List.csv', csvPaths.VendorList[erpType])}>
          {VENDOR}
        </MenuItem>

        {isConstructionCompany && (
          <>
            {erpType !== 'DeltekComputerEase' && (
              <MenuItem
                onClick={() =>
                  handleDownloadCSVSample(
                    erpType === 'QuickBooksOnline' ? 'Class List.csv' : 'Cost Type List.csv',
                    csvPaths.CostTypeList
                  )
                }>
                {erpType === 'QuickBooksOnline' ? CLASS : COST_TYPE}
              </MenuItem>
            )}
            <MenuItem
              onClick={() =>
                handleDownloadCSVSample(
                  erpType === 'QuickBooksOnline' ? 'Project Id List.csv' : 'Job List.csv',
                  csvPaths.JobList[erpType]
                )
              }>
              {erpType === 'QuickBooksOnline' ? PROJECT_ID : JOB_ID}
            </MenuItem>
            {erpType !== 'QuickBooksOnline' && (
              <>
                {erpType !== 'DeltekComputerEase' && (
                  <MenuItem
                    onClick={() => handleDownloadCSVSample('Cost List.csv', csvPaths.CostList)}>
                    {COST_ID}
                  </MenuItem>
                )}
                <MenuItem onClick={() => handleDownloadCSVSample('Phases.csv', csvPaths.Phases)}>
                  {PHASE}
                </MenuItem>
              </>
            )}
          </>
        )}
        <MenuItem onClick={() => handleDownloadCSVSample('PO List.csv', csvPaths.PurchaseOrder)}>
          {PURCHASE_ORDER}
        </MenuItem>
      </StyledMenu>
    </div>
  );
}
