const initialState = {
    allVendors: []
  };
  
  const allVendorsReducers = (state = initialState, action) => {
    switch (action.type) {
      case 'allVendors':
        return {
          ...state,
          allVendors: [action.payload]
        };
      default:
        return state;
    }
  };
  export default allVendorsReducers;
  