import React, { useEffect } from 'react';

import { Grid, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';

import { Colors } from '../config/default';
import { headings } from '../constants/appConstants';

export default function CustomTextField({
  value,
  setValue,
  placeHolder,
  mt,
  toShow,
  type,
  handleKeyPress,
  backgroundColor,
  disabled,
  onChange
}) {
  const { ADD_USER, ADD_VENDOR, REGISTER_USER } = headings;
  useEffect(() => {
    const inputElement = document.getElementById('bootstrap-input');
    if (inputElement) {
      inputElement?.focus();
    }
  }, [value]);

  return (
    <>
      {placeHolder === 'Email' && (
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography
            sx={{
              color: Colors.WHITE,
              fontWeight: 'bold',
              marginBottom: '2rem',
              fontSize: '1.2em'
            }}>
            {toShow === 'vendor'
              ? ADD_VENDOR
              : toShow === 'Register_Users'
              ? REGISTER_USER
              : ADD_USER}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12} sx={{ mt: mt ? mt : '' }}>
        <FormControl fullWidth variant="standard">
          <input
            disabled={disabled}
            type={type ? type : 'text'}
            placeholder={placeHolder}
            style={{
              borderRadius: '10px',
              position: 'relative',
              backgroundColor: backgroundColor ? backgroundColor : Colors.NAV_BLACK,
              border: '1px solid',
              outline: 'none',
              height: '2em',
              fontSize: 16,
              padding: '10px 12px',
              color: Colors.TEXTGREY,
              border: 'none'
            }}
            value={value}
            onChange={(e) => {
              onChange ? onChange(e) : setValue(e.target.value);
            }}
            onKeyDown={handleKeyPress ? handleKeyPress : undefined}
          />
        </FormControl>
      </Grid>
    </>
  );
}
