import React, { useState, useEffect } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  Autocomplete,
  TextField,
  Paper,
  Typography
} from '@mui/material';
import { Check, Error } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

import { Colors } from '../config/default';
import { HtmlTooltip } from '../services/common';

export default function AccountsInput({
  label,
  state,
  setState,
  data,
  setPermission,
  permission,
  allowInput,
  setValueChanged,
  stateName,
  checkStateName,
  validateAccountDetails,
  name,
  setValidationErrors
}) {
  const [accountCode, setAccountCode] = useState(permission);
  const [isError, setIsError] = useState(validateAccountDetails(permission, name));

  const handleChange = (e, value) => {
    setValueChanged(true);
    if (stateName && stateName !== '') {
      setPermission((prev) => ({ ...prev, [stateName]: value }));
    } else {
      setPermission(value);
    }
    setAccountCode(value);
  };
  useEffect(() => {
    setAccountCode(permission);
  }, [permission]);
  useEffect(() => {
    const validation = validateAccountDetails(accountCode, name);
    setIsError(validation);
    if (!validation.status) {
      setValidationErrors((prev) => ({ ...prev, [name]: validation.message }));
    } else {
      setValidationErrors((prev) => {
        const { [name]: removedError, ...rest } = prev;
        return rest;
      });
    }
  }, [accountCode, name, setValidationErrors, permission]);

  const useStyles = makeStyles(() => ({
    clearIndicator: {
      color: Colors.WHITE
    },
    option: {
      borderRadius: '7px',
      '&:hover': {
        borderRadius: '7px',
        backgroundColor: '#141414 !important'
      },
      '&:active': {
        borderRadius: '7px'
      }
    }
  }));

  const classes = useStyles();

  return (
    <div className="value-button">
      <Box className="box-style">
        <p className="paragraph-style">
          <Check
            sx={{
              fontSize: '1.5em',
              marginRight: '.5rem',
              color: state === true ? Colors.DARKBLUE : ''
            }}
            onClick={() => {
              if (checkStateName && checkStateName !== '') {
                setState((prev) => ({ ...prev, [checkStateName]: !state }));
              } else {
                setState(!state);
              }
            }}
          />
          {label}
        </p>
      </Box>
      <div style={{ display: 'flex' }}>
        {data && data?.length > 0 && !data?.includes(null) ? (
          <div className={!isError?.status ? 'dropdown-with-error' : 'first-input'}>
            <div>
              <FormControl fullWidth variant="standard">
                <InputLabel id="demo-simple-select-standard-label"></InputLabel>
                <Autocomplete
                  value={permission || null}
                  onChange={handleChange}
                  options={data}
                  renderOption={(props, option) => {
                    const { key } = props;
                    return (
                      <li {...props} key={props['data-option-index'] + '-' + key}>
                        {option}
                      </li>
                    );
                  }}
                  disableClearable={!permission}
                  classes={{ clearIndicator: classes.clearIndicator, option: classes.option }}
                  ListboxProps={{
                    className: 'myCustomList'
                  }}
                  sx={{
                    '& .MuiAutocomplete-popupIndicator': {
                      color: Colors.WHITE
                    }
                  }}
                  PaperComponent={({ children }) => (
                    <Paper
                      style={{
                        backgroundColor: Colors.NAV_BLACK,
                        color: Colors.WHITE,
                        borderRadius: '10px',
                        padding: '0px 8px'
                      }}>
                      {children}
                    </Paper>
                  )}
                  renderInput={(params) => (
                    <TextField
                      onBlur={
                        allowInput
                          ? (e) => {
                              setValueChanged(true);
                              const newValue = e.target.value;
                              if (stateName && stateName !== '') {
                                setPermission((prev) => ({ ...prev, [stateName]: newValue }));
                              } else {
                                setPermission(newValue);
                              }
                              setAccountCode(newValue);
                            }
                          : undefined
                      }
                      value={permission}
                      sx={{
                        marginTop: { lg: '.7rem' }
                      }}
                      {...params}
                      variant="standard"
                      label=""
                      InputProps={{
                        ...params.InputProps,
                        sx: {
                          color: Colors.WHITE,
                          fontSize: '13px'
                        }
                      }}
                    />
                  )}
                />
              </FormControl>
            </div>
          </div>
        ) : (
          <div className={!isError?.status ? 'dropdown-with-error' : 'first-input'}>
            <input
              className="input-field"
              value={accountCode}
              onChange={(e) => {
                setValueChanged(true);
                setAccountCode(e.target.value);
              }}
              onBlur={() => {
                if (stateName && stateName !== '') {
                  setPermission((prev) => ({ ...prev, [stateName]: accountCode }));
                } else {
                  setPermission(accountCode);
                }
              }}
            />
          </div>
        )}
        {!isError?.status && (
          <HtmlTooltip
            title={
              <Typography color="inherit" sx={{ fontSize: '1em' }}>
                {isError?.message}
              </Typography>
            }>
            <Error
              sx={{
                color: Colors.RED,
                marginTop: '.4rem'
              }}
            />
          </HtmlTooltip>
        )}
      </div>
    </div>
  );
}
