import React, { useEffect, useState } from 'react';
import { isEmpty, isEqual } from 'lodash';

import { DataGrid } from '@mui/x-data-grid';
import { Grid, Button, Box } from '@mui/material';

import { CustomerDataTable, userRoles } from '../constants/appConstants';
import { Colors } from '../config/default';
import {
  PersonOutlineOutlined,
  CheckCircleOutlined,
  AccessTimeOutlined,
  HighlightAltOutlined,
  EmailOutlined,
  Login,
  InfoOutlined,
  Person
} from '@mui/icons-material';

import { deleteUser } from '../services/services';
import Prompt from './prompt';
import { useToast } from '../toast/toastContext';
import MuiModal from './muiModel';

const datagridSx = {
  border: 'none',
  color: Colors.TEXTGREY,
  paddingX: '1rem',
  '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
    outline: 'none !important'
  },
  '& .MuiDataGrid-main': { borderRadius: '10px' },
  '& .MuiDataGrid-virtualScrollerRenderZone': {
    borderBottom: 'none !important',
    '& .MuiDataGrid-root': { border: 'none !important' },
    '& .MuiDataGrid-row': {
      width: '99%',
      borderRadius: '10px',
      '&:hover': {
        backgroundColor: '#24658D !important',
        color: Colors.WHITE
      },
      '&:nth-of-type(2n-1)': {
        backgroundColor: '#1E1E1E'
      }
    },
    '& div': { border: 'none' }
  },

  '& .MuiDataGrid-columnCheckbox': {
    backgroundColor: '#fdfdfd',
    width: '20px',
    height: '20px'
  },

  '& .MuiDataGrid-columnHeaders': {
    border: 'none !important',
    color: Colors.WHITE
  }
};
export default function UserDataGrid({ apiData, getUsers }) {
  const { OWNER, CLEVEL } = userRoles;
  const {
    USERNAME,
    EMAIL,
    USER_ROLE,
    ACTIONS,
    SIGN_UP,
    DELETE,
    INVITE_USER,
    CONFIRM_DELETE_USER,
    CANCEL
  } = CustomerDataTable;
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [user, setUser] = useState(null);
  const { showToast } = useToast();

  const handleOpen = (e, params) => {
    setUser(params);
    e.stopPropagation();
    setOpen(true);
  };
  const handleDeleteUser = async (e) => {
    setLoading(true);
    e.stopPropagation();

    const payload = {
      email: user?.row?.email,
      signUp: user?.row?.isSignUp === 'Pending' ? false : true
    };

    const result = await deleteUser(payload);
    if (result?.status === 200) {
      setLoading(false);
      showToast(result?.data?.metadata?.message, 'success');
      getUsers();
    } else {
      showToast(
        result?.data?.metadata?.message || result?.response?.data?.metadata?.message,
        'error'
      );
    }
  };
  const columns = [
    {
      field: 'email',
      headerName: (
        <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
          <EmailOutlined sx={{ color: Colors.TOURQUISE, fontSize: '17px', marginRight: '4px' }} />
          {EMAIL}
        </div>
      ),
      flex: 1,
      minWidth: 240,
      sortable: false
    },
    {
      field: 'name',
      headerName: (
        <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
          <PersonOutlineOutlined
            sx={{ color: Colors.TOURQUISE, fontSize: '17px', marginRight: '4px' }}
          />
          {USERNAME}
        </div>
      ),
      flex: 1,
      minWidth: 200,
      sortable: false
    },
    {
      field: 'role',
      headerName: (
        <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
          <PersonOutlineOutlined
            sx={{ color: Colors.TOURQUISE, fontSize: '17px', marginRight: '4px' }}
          />
          {USER_ROLE}
        </div>
      ),
      flex: 1,
      minWidth: 200,
      sortable: false
    },
    {
      field: 'isSignUp',
      headerName: (
        <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
          <Login sx={{ color: Colors.TOURQUISE, fontSize: '17px', marginRight: '6px' }} />
          {SIGN_UP}
        </div>
      ),
      flex: 1,
      minWidth: 200,
      sortable: false,
      renderCell: (params) => (
        <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
          {params?.row?.email === '-' ? (
            ''
          ) : params?.row?.isSignUp === 'Completed' ? (
            <CheckCircleOutlined
              sx={{ color: Colors.TOURQUISE, fontSize: '17px', marginRight: '4px' }}
            />
          ) : (
            <AccessTimeOutlined
              sx={{ color: Colors.MUSTARD, fontSize: '17px', marginRight: '4px' }}
            />
          )}
          {params?.row?.isSignUp}
        </div>
      )
    },
    {
      field: 'action',
      headerName: (
        <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
          <HighlightAltOutlined
            sx={{
              color: Colors.TOURQUISE,
              fontSize: '17px',
              marginRight: '4px'
            }}
          />
          {ACTIONS}
        </div>
      ),
      flex: 1,
      minWidth: 100,
      sortable: false,
      disablecolumnmenu: false,
      renderCell: (params) =>
        params?.row?.name === 'AP Person' ? (
          <span style={{ marginLeft: '1em' }}>--------</span>
        ) : params?.row?.email === '-' ? (
          <MuiModal
            text="Register"
            show="Register_Users"
            btnHeight="2rem"
            width="5.5rem"
            data={params?.row}
            getUsers={getUsers}
            color={Colors.TOURQUISE}
          />
        ) : (
          <>
            <Button
              variant="outlined"
              sx={{
                height: '2rem',
                borderRadius: '10px',
                fontSize: '.85em',
                textTransform: 'none',
                width: '5.5rem',
                color: Colors.WHITE,
                borderColor: Colors.RED,
                '&:hover': {
                  borderColor: Colors.RED,
                  background: ' rgba(196, 75, 108, 0.08)'
                }
              }}
              onClick={(e) => handleOpen(e, params)}>
              {DELETE}
            </Button>
            <Prompt
              title="Delete User"
              description={CONFIRM_DELETE_USER}
              leftButton={CANCEL}
              rightButton={DELETE}
              deleteContent={user?.row?.email}
              setOpen={setOpen}
              open={open}
              opacity={true}
              params={params}
              handleClick={handleDeleteUser}
              loading={loading}
            />
          </>
        )
    }
  ];
  useEffect(() => {
    const generatedData = apiData?.map((item, index) => ({
      id: index,
      email: item?.email || '-',
      role: item?.role === CLEVEL ? 'C Level' : item?.role === OWNER ? 'AP Person' : item?.role,
      isSignUp: item?.email === '' ? 'Not Registered' : item?.signUp ? 'Completed' : 'Pending',
      name: item?.name
        ? item?.name === OWNER
          ? 'AP Person'
          : item?.name
        : item?.role === CLEVEL
        ? 'C Level'
        : item?.role === OWNER
        ? 'AP Person'
        : item?.role
    }));
    if (!isEqual(generatedData, apiData)) {
      setRows(generatedData);
    }
  }, [apiData]);
  return isEmpty(apiData) ? (
    <Grid
      container
      sx={{
        color: Colors.TEXTGREY,
        justifyContent: 'center',
        alignItems: 'center',
        height: '75vh'
      }}>
      <Box
        sx={{
          backgroundColor: Colors.NAV_BLACK,
          padding: '.5em',
          borderRadius: '10px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '.8em',
          color: Colors.TEXTGREY
        }}>
        <InfoOutlined sx={{ color: Colors.TEXTGREY, marginRight: '.3em', fontSize: '1.3em' }} />
        {INVITE_USER}
      </Box>
    </Grid>
  ) : (
    <div style={{ width: '100%', height: '75vh' }}>
      <DataGrid
        sx={datagridSx}
        rows={rows}
        columns={columns}
        autoPageSize
        checkboxSelection={false}
        disableRowSelectionOnClick
      />
    </div>
  );
}
