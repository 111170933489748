import { isEmpty, isNaN } from 'lodash';
import { ValidationMessages } from '../constants/appConstants';
import { getVendorNameLength } from './common';

const validateTotal = (value) => {
  const regex = /^[\d\s,\.]+$/;
  return regex.test(value);
};

const validateDate = (datum) => {
  const cleanedDate = datum.replace(/(\d+)(st|nd|rd|th)/, '$1');

  const newDate = new Date(cleanedDate);

  if (isNaN(newDate.getTime())) {
    return false;
  }

  const day = newDate.getDate();
  const month = newDate.getMonth() + 1;
  const year = newDate.getFullYear();

  if (!day || !month || !year) {
    return false;
  }

  const containsFullDate =
    /^(?:(?:\b\w+\b[ ,]*(?:\b\d{1,2}(?:st|nd|rd|th)?\b)[ ,]*\b\d{2,4}\b)|(?:\b\d{1,2}\b[\/\-.]\b\d{1,2}\b[\/\-.]\b\d{2,4}\b))$/i;
  return containsFullDate.test(datum);
};
const isValueNotEmpty = (value) => {
  return !isEmpty(value) && value !== '-' && value !== 'N/A' && value.trim('') !== '';
};
export const ValidateAnnotation = (value, name, sum, matchingPermission) => {
  const erpType = localStorage.getItem('erpType');
  const vendorNamelength = getVendorNameLength(erpType);
  let res = { status: true, message: '' };

  if (name === 'total') {
    const isAmountMatching = matchingPermission ? parseFloat(value) === sum : true;
    if (validateTotal(value) && value?.trim('') !== '' && isAmountMatching) {
      res['status'] = true;
    } else {
      res['status'] = false;
      if (parseFloat(value) !== sum && matchingPermission) {
        res['message'] = ValidationMessages.LINEITEM_AMOUNT_MATCH;
      } else {
        res['message'] = ValidationMessages.INVALID_AMOUNT;
      }
    }
  }

  if (name === 'invoice_date') {
    if (validateDate(value) && value?.trim('') !== '') {
      res['status'] = true;
    } else {
      res['status'] = false;
      res['message'] = ValidationMessages.INVALID_DATE;
    }
  }

  if (name === 'due_date') {
    if (isEmpty(value)) {
      res['status'] = true;
    } else {
      if (validateDate(value)) {
        res['status'] = true;
      } else {
        res['status'] = false;
        res['message'] = ValidationMessages.INVALID_DUE_DATE;
      }
    }
  }

  if (name === 'vendor') {
    if (!isEmpty(value) && value !== '-' && value?.length <= vendorNamelength) {
      res['status'] = true;
    } else {
      res['status'] = false;
      if (value?.length > vendorNamelength) {
        res['message'] =
          erpType === 'Foundation'
            ? ValidationMessages.INVALID_FOUNDATION_VENDOR_LENGTH
            : ValidationMessages.INVALID_VENDOR_LENGTH;
      } else {
        res['message'] = ValidationMessages.INVALID_VENDOR;
      }
    }
  }

  if (name === 'invoice_number') {
    if (!isEmpty(value) && value !== '-' && value?.length <= 20) {
      res['status'] = true;
    } else {
      res['status'] = false;
      if (value?.length > 20) {
        res['message'] = ValidationMessages.INVALID_INVOICELENGTH;
      } else {
        res['message'] = ValidationMessages.INVALID_INVOICENUMBER;
      }
    }
  }
  return res;
};

export const ValidateReceipt = (value, name, sum, matchingPermission) => {
  const erpType = localStorage.getItem('erpType');
  const vendorNamelength = getVendorNameLength(erpType);
  let res = { status: true, message: '' };

  if (name === 'total') {
    const isAmountMatching = matchingPermission ? parseFloat(value) === sum : true;
    if (validateTotal(value) && value?.trim('') !== '' && isAmountMatching) {
      res['status'] = true;
    } else {
      res['status'] = false;
      if (parseFloat(value) !== sum && matchingPermission) {
        res['message'] = ValidationMessages.LINEITEM_AMOUNT_MATCH;
      } else {
        res['message'] = ValidationMessages.INVALID_AMOUNT;
      }
    }
  }
  if (name === 'receipt_date') {
    if (validateDate(value) && value?.trim('') !== '') {
      res['status'] = true;
    } else {
      res['status'] = false;
      res['message'] = ValidationMessages.INVALID_RECEIPT_DATE;
    }
  }
  if (name === 'due_date') {
    if (isEmpty(value)) {
      res['status'] = true;
    } else {
      if (validateDate(value)) {
        res['status'] = true;
      } else {
        res['status'] = false;
        res['message'] = ValidationMessages.INVALID_DUE_DATE;
      }
    }
  }

  if (name === 'vendor') {
    if (!isEmpty(value) && value !== '-' && value?.length <= vendorNamelength) {
      res['status'] = true;
    } else {
      res['status'] = false;
      if (value?.length > vendorNamelength) {
        erpType === 'Foundation'
          ? (res['message'] = ValidationMessages.INVALID_FOUNDATION_VENDOR_LENGTH)
          : (res['message'] = ValidationMessages.INVALID_VENDOR_LENGTH);
      } else {
        res['message'] = ValidationMessages.INVALID_VENDOR;
      }
    }
  }

  if (name === 'receipt_number') {
    if (!isEmpty(value) && value !== '-' && value?.length <= 20) {
      res['status'] = true;
    } else {
      res['status'] = false;
      if (value?.length > 20) {
        res['message'] = ValidationMessages.INVALID_RECEIPTLENGTH;
      } else {
        res['message'] = ValidationMessages.INVALID_RECEIPTNUMBER;
      }
    }
  }

  return res;
};

export const ValidateEstimate = (value, name, annotationType, sum, matchingPermission) => {
  const erpType = localStorage.getItem('erpType');
  const vendorNamelength = getVendorNameLength(erpType);
  let res = { status: true, message: '' };

  if (name === 'total') {
    const isAmountMatching = matchingPermission ? parseFloat(value) === sum : true;
    if (validateTotal(value) && value?.trim('') !== '' && isAmountMatching) {
      res['status'] = true;
    } else {
      res['status'] = false;
      if (parseFloat(value) !== sum && matchingPermission) {
        res['message'] = ValidationMessages.LINEITEM_AMOUNT_MATCH;
      } else {
        res['message'] = ValidationMessages.INVALID_AMOUNT;
      }
    }
  }
  if (name === 'Estimate_date' || name === 'Po_date') {
    if (validateDate(value) && value?.trim('') !== '') {
      res['status'] = true;
    } else {
      res['status'] = false;
      res['message'] =
        name === 'Po_date'
          ? ValidationMessages.INVALID_PO_DATE
          : ValidationMessages.INVALID_ESTIMATE_DATE;
    }
  }

  if (name === 'Expiration_date') {
    if (isEmpty(value)) {
      res['status'] = true;
    } else {
      if (validateDate(value)) {
        res['status'] = true;
      } else {
        res['status'] = false;
        res['message'] = ValidationMessages.INVALID_EXPIRATION_DATE;
      }
    }
  }

  if (name === 'vendor') {
    if (!isEmpty(value) && value !== '-' && value?.length <= vendorNamelength) {
      res['status'] = true;
    } else {
      res['status'] = false;
      if (value?.length > vendorNamelength) {
        erpType === 'Foundation'
          ? (res['message'] = ValidationMessages.INVALID_FOUNDATION_VENDOR_LENGTH)
          : (res['message'] = ValidationMessages.INVALID_VENDOR_LENGTH);
      } else {
        res['message'] = ValidationMessages.INVALID_VENDOR;
      }
    }
  }

  if (name === 'Estimate_no' && annotationType === 'Estimate') {
    if (!isEmpty(value) && value !== '-' && value?.length <= 20) {
      res['status'] = true;
    } else {
      res['status'] = false;
      if (value?.length > 20) {
        res['message'] = ValidationMessages.INVALID_ESTIMATELENGTH;
      } else {
        res['message'] = ValidationMessages.INVALID_ESTIMATENUMBER;
      }
    }
  }
  if (name === 'purchase_order' && annotationType === 'Po') {
    if (!isEmpty(value) && value !== '-' && value?.length <= 10) {
      res['status'] = true;
    } else {
      res['status'] = false;
      if (value?.length > 10) {
        res['message'] = ValidationMessages.INVALID_POLENGTH;
      } else {
        res['message'] = ValidationMessages.INVALID_PONUMBER;
      }
    }
  }

  return res;
};
export const ValidateDeliveryTicket = (value, name, amount, sum, matchingPermission) => {
  const erpType = localStorage.getItem('erpType');
  const vendorNamelength = getVendorNameLength(erpType);
  let res = { status: true, message: '' };

  if (name === 'total' && amount !== '') {
    const isAmountMatching = matchingPermission ? parseFloat(value) === sum : true;
    if (validateTotal(value) && value?.trim('') !== '' && isAmountMatching) {
      res['status'] = true;
    } else {
      res['status'] = false;
      if (parseFloat(value) !== sum && matchingPermission) {
        res['message'] = ValidationMessages.LINEITEM_AMOUNT_MATCH;
      } else {
        res['message'] = ValidationMessages.INVALID_AMOUNT;
      }
    }
  }
  if (name === 'DeliveryTicket_date') {
    if (validateDate(value) && value?.trim('') !== '') {
      res['status'] = true;
    } else {
      res['status'] = false;
      res['message'] = ValidationMessages.INVALID_DT_DATE;
    }
  }

  if (name === 'vendor') {
    if (!isEmpty(value) && value !== '-' && value?.length <= vendorNamelength) {
      res['status'] = true;
    } else {
      res['status'] = false;
      if (value?.length > vendorNamelength) {
        erpType === 'Foundation'
          ? (res['message'] = ValidationMessages.INVALID_FOUNDATION_VENDOR_LENGTH)
          : (res['message'] = ValidationMessages.INVALID_VENDOR_LENGTH);
      } else {
        res['message'] = ValidationMessages.INVALID_VENDOR;
      }
    }
  }

  if (name === 'DeliveryTicket_no') {
    if (!isEmpty(value) && value !== '-' && value?.length <= 20) {
      res['status'] = true;
    } else {
      res['status'] = false;
      if (value?.length > 20) {
        res['message'] = ValidationMessages.INVALID_DELIVERYLENGTH;
      } else {
        res['message'] = ValidationMessages.INVALID_DELIVERYNUMBER;
      }
    }
  }

  return res;
};
export const autoCap = (value) => {
  const spiltVal = value?.split('_');
  for (let i = 0; i <= spiltVal?.length; i++) {
    if (spiltVal[i] === undefined) {
      continue;
    } else {
      spiltVal[i] = spiltVal[i][0]?.toUpperCase() + spiltVal[i]?.substr(1);
    }
  }
  return spiltVal?.join(' ');
};

export const validateG702 = (value, name, sum, matchingPermission) => {
  const erpType = localStorage.getItem('erpType');
  const fromContractorlength = erpType === 'Foundation' ? 30 : 41;
  let res = { status: true, message: '' };
  if (name === 'current_payment_due') {
    const isAmountMatching = matchingPermission ? parseFloat(value) === sum : true;
    if (validateTotal(value) && value?.trim('') !== '' && isAmountMatching) {
      res['status'] = true;
    } else {
      res['status'] = false;
      if (parseFloat(value) !== sum && matchingPermission) {
        res['message'] = ValidationMessages.LINEITEM_AMOUNT_MATCH;
      } else {
        res['message'] = ValidationMessages.INVALID_PAYMENT;
      }
    }
  }
  if (name === 'from_contractor') {
    if (
      !isEmpty(value) &&
      value !== '-' &&
      value?.length <= fromContractorlength &&
      value?.trim('') !== ''
    ) {
      res['status'] = true;
    } else {
      res['status'] = false;
      if (value?.length > fromContractorlength) {
        erpType === 'Foundation'
          ? (res['message'] = ValidationMessages.INVALID_FOUNDATION_FROM_CONTRACTOR_LENGTH)
          : (res['message'] = ValidationMessages.INVALID_CONTRACTOR_LENGTH);
      } else {
        res['message'] = ValidationMessages.INVALID_CONTRACTOR;
      }
    }
  }
  if (name === 'retainage_percentage') {
    if (validateTotal(value) && value?.trim('') !== '') {
      res['status'] = true;
    } else {
      res['status'] = false;
      res['message'] = ValidationMessages.INVALID_RETAINAGE;
    }
  }
  if (name === 'period_to') {
    if (validateDate(value) && value?.trim('') !== '') {
      res['status'] = true;
    } else {
      res['status'] = false;
      res['message'] = ValidationMessages.INVALID_G702_DATE;
    }
  }
  if (name === 'application_no') {
    if (!isEmpty(value) && value !== '-' && value?.length <= 20 && value?.trim('') !== '') {
      res['status'] = true;
    } else {
      res['status'] = false;
      if (value?.length > 20) {
        res['message'] = ValidationMessages.INVALID_APPLICATION_LENGTH;
      } else {
        res['message'] = ValidationMessages.INVALID_PROJECTNUMBER;
      }
    }
  }

  return res;
};
export const ValidateVendorAndAccount = (value, name) => {
  let res = { status: true, message: '' };
  if (name === 'GlAccount') {
    if (isValueNotEmpty(value)) {
      res['status'] = true;
    } else {
      res['status'] = false;
      res['message'] = ValidationMessages.INVALID_GLACCOUNT;
    }
  }
  if (name === 'VendorNumber') {
    if (isValueNotEmpty(value)) {
      res['status'] = true;
    } else {
      res['status'] = false;
      res['message'] = ValidationMessages.INVALID_VENDOR_NUMBER;
    }
  }

  return res;
};
export const ValidateCodes = (value, name) => {
  let res = { status: true, message: '' };

  if (name === 'JobId') {
    if (isValueNotEmpty(value)) {
      res['status'] = true;
    } else {
      res['status'] = false;
      res['message'] = ValidationMessages.INVALID_JOBID;
    }
  }
  if (name === 'CostId') {
    if (isValueNotEmpty(value)) {
      res['status'] = true;
    } else {
      res['status'] = false;
      res['message'] = ValidationMessages.INVALID_COSTID;
    }
  }
  if (name === 'CostType') {
    if (isValueNotEmpty(value)) {
      res['status'] = true;
    } else {
      res['status'] = false;
      res['message'] = ValidationMessages.INVALID_COSTTYPE;
    }
  }
  if (name === 'GlAccount') {
    if (isValueNotEmpty(value)) {
      res['status'] = true;
    } else {
      res['status'] = false;
      res['message'] = ValidationMessages.INVALID_GLACCOUNT;
    }
  }
  if (name === 'VendorNumber') {
    if (isValueNotEmpty(value)) {
      res['status'] = true;
    } else {
      res['status'] = false;
      res['message'] = ValidationMessages.INVALID_VENDOR_NUMBER;
    }
  }

  return res;
};
export const validateLineItems = (name, value, type, erp, constructionCompany) => {
  let res = {
    status: true,
    message: ''
  };

  if (
    (erp === 'Foundation' || erp === 'QuickBooksDesktop') &&
    type === 'Po' &&
    constructionCompany
  ) {
    if (
      name === 'userCostId' ||
      name === 'Description' ||
      name === 'userCostType' ||
      name === 'GlAccount' ||
      name === 'userJobId' ||
      name === 'Amount'
    ) {
      if (!value || value === 'N/A' || value === '' || isEmpty(value)) {
        res['message'] =
          name === 'userCostId'
            ? ValidationMessages.INVALID_LINEITEMS_COSTID_INFO
            : name === 'Description'
            ? ValidationMessages.INVALID_LINEITEMS_DESCRIPTION_INFO
            : name === 'userCostType'
            ? ValidationMessages.INVALID_LINEITEMS_COSTYPE_INFO
            : name === 'GlAccount'
            ? ValidationMessages.INVALID_LINEITEMS_GLACCOUNT_INFO
            : name === 'userJobId'
            ? ValidationMessages.INVALID_LINEITEMS_JOBID_INFO
            : ValidationMessages.INVALID_LINEITEMS_AMOUNT_INFO;
        res['status'] = false;
      }
    }
  } else if (
    ((erp === 'QuickBooksOnline' || erp === 'QuickBooksDesktop') &&
      (type === 'Invoice' || type === 'Receipt' || type === 'G702')) ||
    ((erp === 'Foundation' || erp === 'QuickBooksDesktop') && type === 'Po' && !constructionCompany)
  ) {
    if (name === 'Description' || name === 'Amount' || name === 'GlAccount') {
      if (!value || value === 'N/A' || value === '' || isEmpty(value)) {
        res['message'] =
          name === 'GlAccount'
            ? ValidationMessages.INVALID_LINEITEMS_GLACCOUNT_INFO
            : name === 'Description'
            ? ValidationMessages.INVALID_LINEITEMS_DESCRIPTION_INFO
            : ValidationMessages.INVALID_LINEITEMS_AMOUNT_INFO;
        res['status'] = false;
      }
    }
  } else {
    if (name === 'Description' || name === 'Amount') {
      if (!value || value === 'N/A' || value === '' || isEmpty(value)) {
        res['message'] =
          name === 'Description'
            ? ValidationMessages.INVALID_LINEITEMS_DESCRIPTION_INFO
            : ValidationMessages.INVALID_LINEITEMS_AMOUNT_INFO;
        res['status'] = false;
      }
    }
  }

  return res;
};

export const isEmailValid = (email) => {
  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  return emailRegex.test(email);
};

export function compareObjects(obj1, obj2) {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1?.length !== keys2?.length) {
    return false;
  }

  const resultObject = {};
  for (let key of keys1) {
    if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
      // Compare array elements
      if (obj1[key]?.length !== obj2[key]?.length) {
        resultObject[key] = false;
      } else {
        let equal = true;
        for (let i = 0; i < obj1[key]?.length; i++) {
          if (obj1[key][i] !== obj2[key][i]) {
            equal = false;
            break;
          }
        }
        resultObject[key] = equal;
      }
    } else {
      resultObject[key] = obj1[key] === obj2[key];
    }
  }

  return resultObject;
}
