const initialState = {
  manualUsers: []
};

const manualUsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'manualUsers':
      return {
        ...state,
        manualUsers: [action.payload]
      };
    default:
      return state;
  }
};
export default manualUsersReducer;
