import * as React from 'react';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { ThemeProvider } from 'styled-components';
import { useSelector } from 'react-redux';

import { Colors } from '../config/default';
import theme from '../theme';
import { BootstrapInput } from '../services/common';
import { userRoles } from '../constants/appConstants';

export default function PermissionDropdown({
  permission,
  setPermission,
  question,
  option1,
  option2,
  option3,
  option4,
  option5,
  disabled,
  toShow,
  status,
  setStartIndex
}) {
  const { OWNER, ACCOUNTANT } = userRoles;
  const userRole = useSelector(
    (state) => state?.signIn?.signIn?.cognitoRes?.idToken?.payload?.given_name
  );
  const handleChange = (event) => {
    setPermission(event.target.value);
    if (toShow === 'dashboard') {
      setStartIndex(0);
    }
  };
  if (disabled) {
    setPermission('');
  }

  const MenuProps = {
    PaperProps: {
      style: {
        backgroundColor: Colors.NAV_BLACK,
        borderRadius: '10px'
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div>
        <FormControl fullWidth variant="standard" disabled={disabled}>
          <InputLabel
            id="demo-simple-select-standard-label"
            sx={{
              fontSize: '13px',
              color: Colors.TEXTGREY
            }}>
            {question}
          </InputLabel>
          <Select
            variant="outlined"
            sx={{
              color: Colors.TEXTGREY,
              border: 'none !important',
              fontSize: '13px',
              padding: '0 !important',
              mt: '0.5em',
              '.MuiSelect-icon': {
                color: 'white !important'
              },
              '& .MuiInputBase-input': {
                paddingLeft: '0.5em !important'
              }
            }}
            MenuProps={MenuProps}
            input={<BootstrapInput />}
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            defaultValue=""
            value={permission}
            onChange={handleChange}
            label="Permission">
            <MenuItem
              sx={{
                color: Colors.TEXTGREY,
                borderRadius: '8px',
                margin: '0px 8px',
                '&:hover': {
                  borderRadius: '7px',
                  backgroundColor: Colors.BG_BLACK
                }
              }}
              value={toShow === 'dashboard' ? option1 : 'Yes'}>
              {option1}
            </MenuItem>
            <MenuItem
              sx={{
                color: Colors.TEXTGREY,
                borderRadius: '8px',
                margin: '0px 8px',
                '&:hover': {
                  borderRadius: '7px',
                  backgroundColor: Colors.BG_BLACK
                }
              }}
              value={toShow === 'dashboard' ? option2 : 'No'}>
              {option2}
            </MenuItem>
            {option3 && (
              <MenuItem
                sx={{
                  color: Colors.TEXTGREY,
                  borderRadius: '8px',
                  margin: '0px 8px',
                  '&:hover': {
                    borderRadius: '7px',
                    backgroundColor: Colors.BG_BLACK
                  }
                }}
                value={toShow === 'dashboard' && status ? 'Populated' : 'Receipt'}>
                {option3}
              </MenuItem>
            )}

            {option5 && userRole !== OWNER && userRole !== ACCOUNTANT && (
              <MenuItem
                sx={{
                  color: Colors.TEXTGREY,
                  borderRadius: '8px',
                  margin: '0px 8px',
                  '&:hover': {
                    borderRadius: '7px',
                    backgroundColor: Colors.BG_BLACK
                  }
                }}
                value="on Hold">
                {option5}
              </MenuItem>
            )}
          </Select>
        </FormControl>
      </div>
    </ThemeProvider>
  );
}
