import React, { useState } from 'react';

import { Typography, Paper, Grid, Box, Modal, MenuItem } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';

import Basic from './dropzone';
import { Colors } from '../config/default';
import PermissionRadio from './permissionRadio';
import { accountDetailsComponent } from '../constants/appConstants';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24
};

export default function UploadAccounts({
  text,
  accountType,
  disable,
  getInformation,
  setAnchorEl
}) {
  const { OVERRIDE_LIST } = accountDetailsComponent;
  const [open, setOpen] = useState(false);
  const [permission, setPermission] = useState('');
  const [files, setFiles] = useState([]);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false), setFiles([]), setPermission('');
    setAnchorEl(null);
  };

  const removeFile = (fileName) => {
    const filteredFile = files?.filter((file) => file.name !== fileName);
    setFiles(filteredFile);
  };

  return (
    <MenuItem onClick={handleOpen}>
      <Typography>{text}</Typography>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box
          sx={{
            ...style,
            width: { xs: '90vw', lg: '50vw' }
          }}>
          <Paper elevation={5} sx={{ height: 'max-content', borderRadius: '20px !important' }}>
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  padding: '0em 1em'
                }}>
                <CloseIcon
                  sx={{ color: Colors.TEXTGREY, marginTop: '1em', cursor: 'pointer' }}
                  onClick={handleClose}
                />
              </Grid>
            </Grid>
            <Grid container sx={{ height: '480px' }}>
              <Grid container sx={{ justifyContent: 'center' }}>
                {disable && (
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{ flexDirection: 'column', justifyContent: 'center' }}>
                    <PermissionRadio
                      permission={permission}
                      setPermission={setPermission}
                      question={OVERRIDE_LIST}
                      options={['Yes', 'No']}
                    />
                  </Grid>
                )}
              </Grid>
              <Basic
                name="Drag n drop some files here or click to select files(only CSV files are supported)"
                disable={disable}
                permission={permission}
                setPermission={setPermission}
                files={files}
                setFiles={setFiles}
                setOpen={setOpen}
                removeFile={removeFile}
                multiple={false}
                icon={<CloudUploadIcon sx={{ fontSize: 60, color: '#EEC160' }} />}
                uploadDocScreen={accountType}
                getInformation={getInformation}
                accept="csv"
                setAnchorEl={setAnchorEl}
              />
            </Grid>
          </Paper>
        </Box>
      </Modal>
    </MenuItem>
  );
}
