import React from 'react';

import { Card, Grid } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutlined';

import { Colors } from '../config/default';
import { headings } from '../constants/appConstants';

export default function ThankyouPage() {
  const { PAYMENT_SUCCESSFULL, THANK_YOU } = headings;
  return (
    <Grid
      container
      sx={{
        height: '100vh',
        width: '100%',
        backgroundColor: '#141414',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
      <Card
        sx={{
          backgroundColor: '#1E1E1E',
          border: `2px dashed ${Colors.TEXTGREY}`,
          height: '38vh',
          width: '50%',
          padding: '3em',
          borderRadius: '16px',
          textAlign: 'center',
          color: Colors.TEXTGREY
        }}>
        <Grid sx={{ fontSize: '3em' }}>{THANK_YOU}</Grid>
        <Grid>
          <CheckCircleIcon sx={{ color: Colors.DARKBLUE, fontSize: '10em', mt: '10px' }} />
        </Grid>
        <Grid sx={{ fontSize: '2em', mt: '0.5em' }}>{PAYMENT_SUCCESSFULL}</Grid>
      </Card>
    </Grid>
  );
}
