const initialState = {
  queue: []
};

const queueReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'queueData':
      return {
        ...state,
        queue: [action.payload]
      };
    default:
      return state;
  }
};
export default queueReducer;
