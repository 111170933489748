const initialState = {
  allErrors: []
};

const allErrorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'allErrors':
      return {
        ...state,
        allErrors: [action.payload]
      };
    default:
      return state;
  }
};
export default allErrorsReducer;
