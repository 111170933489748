const initialState = {
  pending: []
};

const pendingReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'pendingData':
      return {
        ...state,
        pending: [action.payload]
      };
    default:
      return state;
  }
};
export default pendingReducer;
