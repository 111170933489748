import React, { useState } from 'react';

import { Grid } from '@mui/material';

import '../image.css';

function PngImage({ imageSrc, imageAlt, imageRef, onLoad }) {
  const [isEnlarged, setIsEnlarged] = useState(false);
  const handleImageClick = () => {
    setIsEnlarged(!isEnlarged);
  };

  return (
    <Grid
      sx={{ maxwidth: 'none', height: 'calc( 67vh + 3em )' }}
      className={`image-container ${isEnlarged ? 'enlarged' : ''}`}
      onClick={handleImageClick}>
      <img
        id="i"
        src={imageSrc}
        alt={imageAlt}
        ref={imageRef}
        onLoad={onLoad}
        style={{
          height: 'inherit',
          width: 'auto',
          borderRadius: '1em'
        }}
      />
    </Grid>
  );
}

export default PngImage;
