import React, { useState } from 'react';
import Countdown from 'react-countdown';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Typography, Grid, Box, TextField, Input, InputAdornment } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';

import { Colors } from '../../src/config/default';
import theme from '../theme';
import Btn from './button';
import { ValidationMessages, protectedSchema, toasters, headings } from '../constants/appConstants';
import { forgotPassword, resendCode, updatePassword } from '../services/services';
import { hashPassword, hashEmail } from '../services/common';
import { useToast } from '../toast/toastContext';
import { isEmailValid } from '../services/validationService';

function ForgotPassword({ setForgotPassword, setSignIn, setIsHovered }) {
  const { showToast } = useToast();
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [counter, setCounter] = useState(true);
  const [showResendCode, setShowResendCode] = useState(false);
  const [isResendEnabled, setIsResendEnabled] = useState(true);
  const [showCountdownTimer, setShowCountdownTimer] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [verfyCode, setVerifyCode] = useState(false);

  const { REQUIRED, PASSWORD_CHECK } = ValidationMessages;
  const { CODE_VARIFICATION, RESEND_CODE, SECONDS, SEND_EMAIL_TEXT } = protectedSchema;
  const {
    VERIFICATION_CODE,
    PASSWORD,
    PASSWORD_PLACEHOLDER,
    CHANGE_PASSWORD,
    EMAIL_PLACEHOLDER,
    EMAIL,
    CONTINUE,
    CANCEL
  } = headings;
  const { IS_CODE_SENT, RESEND_CODE_LINK, PASSWORD_CHANGED, TIME_ERROR } = toasters;
  const initialValues = {
    password: ''
  };
  const codeValueValidation = (value) => {
    if (value % 1 == 0) {
      setCode(value);
    }
  };
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validationSchema = Yup.object({
    password: Yup.string()
      .trim()
      .required(REQUIRED)
      .min(8, PASSWORD_CHECK)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`~£])[A-Za-z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`~£]{8,}$/,
        PASSWORD_CHECK
      )
  });
  const handleCancel = () => {
    setForgotPassword(false);
    setSignIn(true);
  };
  const verifyEmail = async () => {
    const hashedEmail = hashEmail(email);
    var params = {
      email: hashedEmail
    };
    const forgotPasswordResult = await forgotPassword(params);
    if (forgotPasswordResult.status === 200) {
      showToast(IS_CODE_SENT, 'success');
      setVerifyCode(true);
    } else {
      showToast(forgotPasswordResult?.data?.payload?.data?.cognitoError?.message, 'error');
    }
  };
  const onSubmit = (e) => {
    e.preventDefault();
    handleSubmit(formik.values);
  };

  const handleResendCode = async () => {
    const newEmail = hashEmail(email);
    var params = {
      email: newEmail
    };
    const resendPasswordApiResult = await resendCode(params);
    if (!isResendEnabled) {
      setCounter(!counter);
      if (resendPasswordApiResult?.status === 200) {
        showToast(IS_CODE_SENT, 'success');
        setIsResendEnabled(true);
        setShowCountdownTimer(false);
        setShowResendCode(false);
      } else {
        showToast(resendPasswordApiResult?.data?.payload?.data?.cognitoError?.message, 'error');
      }
    } else {
      showToast(TIME_ERROR, 'error');
    }
  };
  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema
  });
  const handleSubmit = async (values) => {
    const newPassword = hashPassword(values.password);

    const newEmail = hashEmail(email);
    var params = {
      verificationCode: code,
      password: newPassword,
      email: newEmail
    };
    const updatePasswordResult = await updatePassword(params);
    if (updatePasswordResult?.status === 200) {
      showToast(PASSWORD_CHANGED, 'success');
      setForgotPassword(false);
      setSignIn(true);
    } else {
      showToast(updatePasswordResult?.data?.payload?.data?.cognitoError?.message, 'error');
    }
  };
  const renderer = ({ seconds }) => {
    return (
      <div
        style={{
          paddingTop: '2px',
          width: '30px',
          textAlign: 'center',
          fontSize: '1rem',
          fontWeight: 'bold'
        }}>
        <span>{seconds}</span>
      </div>
    );
  };
  const Completed = () => {
    setIsResendEnabled(false);
    setShowCountdownTimer(false);
    setShowResendCode(true);
  };
  const startDate = React.useRef(Date.now());

  return (
    <ThemeProvider theme={theme}>
      <Grid Container>
        {verfyCode ? (
          <Box
            component="form"
            onSubmit={formik.handleSubmit}
            noValidate
            sx={{
              marginTop: '2rem',
              display: 'flex',
              flexDirection: 'column'
            }}>
            <Typography
              sx={{
                color: Colors.WHITE,
                fontFamily: 'Roboto',
                fontWeight: 200,
                fontSize: '1rem',
                textAlign: 'center'
              }}>
              {CODE_VARIFICATION}
            </Typography>

            <Box component="form" noValidate>
              <TextField
                inputProps={{ maxLength: 6 }}
                sx={{ mt: 3 }}
                fullWidth
                variant="standard"
                type="text"
                value={code}
                onChange={(e) => codeValueValidation(e.target.value)}
                name={VERIFICATION_CODE}
                placeholder={VERIFICATION_CODE}
              />
              <Input
                sx={{ mt: 3 }}
                variant="standard"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                name={PASSWORD}
                placeholder={PASSWORD_PLACEHOLDER}
                autoComplete="current-password"
                id={PASSWORD}
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {formik.touched.password && formik.errors.password ? (
                <Box sx={{ color: 'red', margin: '0.1rem 0' }}>{formik.errors.password}</Box>
              ) : (
                <Box sx={{ whiteSpace: 'pre', margin: '0.1rem 0' }}> </Box>
              )}
              <Grid container sx={{ justifyContent: 'flex-end' }}>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: showCountdownTimer ? 'flex' : 'none',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                  <span
                    style={{
                      display: 'flex',
                      color: Colors.TOURQUISE
                    }}>
                    <Typography style={{ display: 'inline-flex', color: Colors.WHITE }}>
                      {RESEND_CODE}
                    </Typography>
                    <Countdown
                      date={startDate.current + 30000}
                      renderer={renderer}
                      key={counter}
                      onComplete={Completed}></Countdown>
                    <Typography style={{ color: Colors.WHITE }}>{SECONDS}</Typography>
                  </span>
                </Grid>
                <Grid item xs={12}>
                  <a
                    style={{
                      display: showResendCode ? 'flex' : 'none',
                      fontFamily: 'Roboto',
                      color: Colors.WHITE,
                      fontWeight: 200,
                      fontSize: '12px',
                      cursor: 'pointer',
                      justifyContent: 'end'
                    }}
                    onClick={handleResendCode}>
                    {RESEND_CODE_LINK}
                  </a>
                </Grid>
              </Grid>
              <Box component="div" sx={{ mb: 2, mt: 3 }}>
                <Btn
                  type="submit"
                  variant="outlined"
                  onClick={onSubmit}
                  disabled={!(formik.isValid && formik.dirty) || code === ''}
                  text={CHANGE_PASSWORD}
                  radius="7.93px"
                  color={Colors.WHITE}
                  setIsHovered={setIsHovered}
                />
              </Box>
              <Btn
                variant="outlined"
                onClick={handleCancel}
                text="Cancel"
                radius="7.93px"
                color={Colors.WHITE}
              />
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '4rem'
            }}>
            <Typography
              sx={{
                color: Colors.WHITE,
                fontFamily: 'Roboto',
                fontWeight: 200,
                fontSize: '1rem',
                textAlign: 'center'
              }}>
              {SEND_EMAIL_TEXT}
            </Typography>

            <Box component="form" noValidate sx={{ mt: 3 }}>
              <TextField
                sx={{ mb: 5 }}
                variant="standard"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                name={EMAIL}
                placeholder={EMAIL_PLACEHOLDER}
              />
              <Box component="div" sx={{ mb: 2 }}>
                <Btn
                  variant="outlined"
                  onClick={verifyEmail}
                  disabled={email === '' || !isEmailValid(email)}
                  text={CONTINUE}
                  radius="7.93px"
                  color={Colors.WHITE}
                  setIsHovered={setIsHovered}
                />
              </Box>
              <Btn
                variant="outlined"
                onClick={handleCancel}
                text={CANCEL}
                radius="7.93px"
                color={Colors.WHITE}
              />
            </Box>
          </Box>
        )}
      </Grid>
    </ThemeProvider>
  );
}

export default ForgotPassword;
