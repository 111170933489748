import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Button, CircularProgress, styled, Typography } from '@mui/material';

import Menu from '@mui/material/Menu';
import { Colors } from '../config/default';
import UploadAccounts from './uploadAccounts';
import { getGlInfo } from '../services/services';
import { HtmlTooltip } from '../services/common';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '10px',
    backgroundColor: '#282828',
    marginTop: theme.spacing(1),
    minWidth: 160,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '.5em'
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: Colors.NAV_BLACK,
        borderRadius: '8px'
      },
      '&:hover': {
        backgroundColor: Colors.NAV_BLACK,
        borderRadius: '8px'
      }
    }
  }
}));

export default function UploadManualFile({
  handleClose,
  anchorEl,
  setAnchorEl,
  height,
  width,
  text,
  loadingCheckbox,
  icon,
  tooltipText
}) {
  const userDetails = useSelector((state) => state?.userInfo?.userInfo);
  const erpType = userDetails?.user?.erpType;
  const isConstructionCompany = userDetails?.user?.constructionCompany;
  const [jobId, setJobId] = useState(false);
  const [costId, setCostId] = useState(false);
  const [accounts, setAccounts] = useState(false);
  const [vendors, setVendors] = useState(false);
  const [costType, setCostType] = useState(false);
  const [phase, setPhase] = useState(false);
  const [po, setPo] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const getInformation = async () => {
    const res = await getGlInfo(userDetails?.user?.email);
    if (res?.data?.metadata?.status === 'SUCCESS') {
      setJobId(res?.data?.payload?.data?.jobIds);
      setCostId(res?.data?.payload?.data?.costIds);
      setAccounts(res?.data?.payload?.data?.glAccount);
      setVendors(res?.data?.payload?.data?.vendors);
      setPo(res?.data?.payload?.data?.purchaseOrder);
      setCostType(res?.data?.payload?.data?.costType);
      setPhase(res?.data?.payload?.data?.phase);
    }
  };

  useEffect(() => {
    getInformation();
  }, []);
  return (
    <div>
      <HtmlTooltip
        title={
          <Typography color="inherit" sx={{ fontSize: '1em' }}>
            {tooltipText}
          </Typography>
        }>
        <span>
          <Button
            sx={{
              width: { xs: 'max-content', lg: width },
              textTransform: 'unset !important',
              marginLeft: { md: '1em' },
              marginTop: { xs: '1em', sm: '0' },
              borderColor: Colors.DEEPBLUE,
              color: Colors.WHITE,
              height: height,
              borderRadius: '10px',
              '&:hover': {
                borderColor: Colors.DEEPBLUE
              }
            }}
            id="demo-customized-button"
            aria-controls={open ? 'demo-customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant="outlined"
            disableElevation
            onClick={handleClick}>
            {loadingCheckbox ? (
              <CircularProgress size={25} color="inherit" />
            ) : (
              <>
                {icon}
                {text}
              </>
            )}
          </Button>
        </span>
      </HtmlTooltip>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        {erpType !== 'DeltekComputerEase' && (
          <UploadAccounts
            text="GL Account"
            accountType="accounts"
            disable={accounts}
            getInformation={getInformation}
            setAnchorEl={setAnchorEl}
          />
        )}

        <UploadAccounts
          text="Vendor"
          accountType="vendors"
          disable={vendors}
          getInformation={getInformation}
          setAnchorEl={setAnchorEl}
        />

        {isConstructionCompany ? (
          <>
            {erpType !== 'DeltekComputerEase' && (
              <UploadAccounts
                text={erpType === 'QuickBooksOnline' ? 'Class' : 'Cost Type'}
                accountType="costType"
                disable={costType}
                getInformation={getInformation}
                setAnchorEl={setAnchorEl}
              />
            )}
            <UploadAccounts
              text={erpType === 'QuickBooksOnline' ? 'Project Id' : 'Job Id'}
              accountType="jobIds"
              disable={jobId}
              getInformation={getInformation}
              setAnchorEl={setAnchorEl}
            />
            {erpType !== 'QuickBooksOnline' && (
              <>
                {erpType !== 'DeltekComputerEase' && (
                  <UploadAccounts
                    text="Cost Id"
                    accountType="costIds"
                    disable={costId}
                    getInformation={getInformation}
                    setAnchorEl={setAnchorEl}
                  />
                )}

                <UploadAccounts
                  text="Phase"
                  accountType="phase"
                  disable={phase}
                  getInformation={getInformation}
                  setAnchorEl={setAnchorEl}
                />
              </>
            )}
          </>
        ) : (
          ''
        )}
        <UploadAccounts
          text="Purchase Order"
          accountType="purchaseOrder"
          disable={po}
          getInformation={getInformation}
          setAnchorEl={setAnchorEl}
        />
      </StyledMenu>
    </div>
  );
}
