export const sign_In = (text) => async (dispatch) => {
  try {
    dispatch({
      type: 'signIn',
      payload: text
    });
  } catch (err) {
    return err;
  }
};

export const get_queue = (text) => async (dispatch) => {
  try {
    dispatch({
      type: 'queueData',
      payload: text
    });
  } catch (err) {
    return err;
  }
};

export const get_all_errors = (text) => async (dispatch) => {
  try {
    dispatch({
      type: 'allErrors',
      payload: text
    });
  } catch (err) {
    return err;
  }
};
export const get_all_users = (text) => async (dispatch) => {
  try {
    dispatch({
      type: 'allUsers',
      payload: text
    });
  } catch (err) {
    return err;
  }
};
export const get_pending_data = (text) => async (dispatch) => {
  try {
    dispatch({
      type: 'pendingData',
      payload: text
    });
  } catch (err) {
    return err;
  }
};

export const user_information = (text) => async (dispatch) => {
  try {
    dispatch({
      type: 'userInfo',
      payload: text
    });
  } catch (err) {
    return err;
  }
};
export const get_manual_users = (text) => async (dispatch) => {
  try {
    dispatch({
      type: 'manualUsers',
      payload: text
    });
  } catch (err) {
    return err;
  }
};
export const get_tabs = (text) => async (dispatch) => {
  try {
    dispatch({
      type: 'tabs',
      payload: text
    });
  } catch (err) {
    return err;
  }
};

export const get_all_vendors = (text) => async (dispatch) => {
  try {
    dispatch({
      type: 'allVendors',
      payload: text
    });
  } catch (err) {
    return err;
  }
};