import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Grid, Typography, Drawer, Box } from '@mui/material';
import {
  ArrowRightAlt,
  AttachMoney,
  ShoppingCart,
  DescriptionOutlined,
  RequestQuoteOutlined,
  InsertDriveFileOutlined
} from '@mui/icons-material';

import { Colors } from '../config/default';
import { generatePo } from '../constants/appConstants';
import { replaceName } from '../services/common';

export default function LineitemsDrawer({ lineitems, isConstructionCompany, annotationType }) {
  const userDetails = useSelector((state) => state?.userInfo?.userInfo);
  const erpType = userDetails?.user?.erpType;
  const serviceAndEquipmentPermission = userDetails?.company?.serviceCodeAndEquipmentNo;
  let filteredArray = lineitems;
  if (annotationType === 'Estimate' || annotationType === 'DeliveryTicket') {
    filteredArray = filteredArray?.map((subArray) => {
      return subArray.filter(
        (item) =>
          item.name !== 'userCostId' &&
          item.name !== 'userPhases' &&
          item.name !== 'userJobId' &&
          item.name !== 'userCostType' &&
          item.name !== 'GlAccount'
      );
    });
  }
  if (
    !isConstructionCompany &&
    annotationType !== 'Estimate' &&
    annotationType !== 'DeliveryTicket'
  ) {
    filteredArray = filteredArray?.map((subArray) => {
      return subArray.filter(
        (item) =>
          item.name !== 'userCostId' &&
          item.name !== 'userPhases' &&
          item.name !== 'userJobId' &&
          item.name !== 'userCostType'
      );
    });
  }
  if (erpType === 'DeltekComputerEase' || erpType === 'QuickBooksOnline') {
    filteredArray = filteredArray?.map((subArray) => {
      return subArray.filter((item) => item.name !== 'userCostId');
    });
  }
  if (erpType === 'QuickBooksOnline' || erpType === 'QuickBooksDesktop') {
    filteredArray = filteredArray?.map((subArray) => {
      return subArray.filter((item) => item.name !== 'userPhases');
    });
  }
  if (
    !serviceAndEquipmentPermission ||
    erpType !== 'Foundation' ||
    (erpType === 'Foundation' && annotationType !== 'Invoice')
  ) {
    filteredArray = filteredArray?.map((subArray) => {
      return subArray.filter((item) => item.name !== 'equipmentNo' && item.name !== 'serviceCode');
    });
  }
  const [state, setState] = useState({
    bottom: false
  });

  const { SHOW_LINEITEMS } = generatePo;

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const iconMap = {
    Description: DescriptionOutlined,
    Unit_Price: AttachMoney,
    Quantity: ShoppingCart,
    Amount: AttachMoney,
    Product_Code: DescriptionOutlined,
    userCostId: RequestQuoteOutlined,
    userCostType: RequestQuoteOutlined,
    userPhases: InsertDriveFileOutlined,
    userJobId: InsertDriveFileOutlined,
    GlAccount: DescriptionOutlined,
    equipmentNo: InsertDriveFileOutlined,
    serviceCode: DescriptionOutlined
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === 'bottom' ? 'auto' : 250,
        backgroundColor: Colors.BG_BLACK
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}>
      <Grid
        container
        sx={{
          fontWeight: 'bold',
          fontSize: '0.80em',
          color: Colors.WHITE,
          backgroundColor: Colors.GREY,
          p: '1em 1em',
          mb: '8px'
        }}>
        {filteredArray?.slice(0, 1)?.map((itemsArray) => (
          <>
            {itemsArray?.map((item) => (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flex: 1
                }}>
                <span>
                  {iconMap[item?.name]
                    ? React.createElement(iconMap[item.name], {
                        style: { fontSize: '1.2rem', color: Colors.TOURQUISE }
                      })
                    : null}
                </span>
                <span>{replaceName(item?.name, erpType)}</span>
              </div>
            ))}
          </>
        ))}
      </Grid>
      <Grid
        sx={{
          height: '9.5em',
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            width: '0.2em',
            height: '0em',
            borderRadius: '20px'
          },
          '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.11)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.11)',
            outline: '1px solid slategrey'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#fff',
            outline: '1px solid slategrey'
          }
        }}>
        {filteredArray?.map((items, index) => (
          <Grid
            container
            key={index}
            sx={{
              fontSize: '0.80em',
              mb: '1em',
              p: '1em 1em',
              color: Colors.TEXTGREY
            }}>
            {items?.map((item) => (
              <span
                style={{
                  flex: 1
                }}>
                {item?.value || '\u00A0\u00A0-'}
              </span>
            ))}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
  return (
    <>
      <div style={{ float: 'right', marginTop: '3%' }}>
        {['bottom'].map((anchor) => (
          <React.Fragment key={anchor}>
            <Typography
              sx={{
                color: Colors.LIGHTBLUE,
                fontSize: '14px',
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer'
              }}
              onClick={toggleDrawer(anchor, true)}>
              {SHOW_LINEITEMS}
              <ArrowRightAlt sx={{ color: Colors.LIGHTBLUE }} />
            </Typography>
            <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
              {list(anchor)}
            </Drawer>
          </React.Fragment>
        ))}
      </div>
    </>
  );
}
