import React from 'react';

import { Card, Grid, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import { Colors } from '../config/default';
import { generatePo } from '../constants/appConstants';
import { getTodaysDate } from '../services/common';

export default function GeneratePoCard({
  lineItemsPerPage,
  totalCardPages,
  height,
  width,
  cursor,
  onClick,
  estimateNumber,
  documentContentMapped,
  documentLineItemsMapped,
  parentHeight,
  show,
  textSize
}) {
  const extraLargeScreen = useMediaQuery('(min-width:2400px)');
  const largeScreen = useMediaQuery('(min-width:2000px) and (max-width:2400px)');
  const { DESCRIPTION, TOTAL, QUANTITY, UNIT_PRICE } = generatePo;
  let startIndex = 1;

  return (
    <Grid
      sx={{
        height: parentHeight || 'calc( 67vh + 3em )',
        overflowY: 'auto',
        paddingRight: show ? 'auto' : '10px',
        '&::-webkit-scrollbar': {
          width: '0.2em',
          height: '0em',
          borderRadius: show ? '0px' : '20px'
        },
        '&::-webkit-scrollbar-track': {
          boxShadow: 'inset 0 0 6px rgba(0,0,0,0.11)',
          webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.11)',
          outline: '1px solid slategrey'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#fff',
          outline: '1px solid slategrey'
        }
      }}>
      <Card
        sx={{
          height: height,
          width: width
            ? width
            : largeScreen
            ? '24vw'
            : extraLargeScreen
            ? '22vw'
            : { xs: '65vw', md: '30vw', lg: '26vw' },
          padding: '1.5em 2em',
          borderRadius: show ? '0px' : '1em',
          cursor: cursor,
          backgroundColor: Colors.WHITE
        }}
        id="Po0"
        onClick={onClick}>
        <Grid
          item
          sx={{
            backgroundColor: Colors.LIGHT_TOURQUISE,
            padding: '.5rem',
            display: 'flex',
            alignItems: 'flex-start'
          }}>
          <Typography
            sx={{
              width: '35%',
              fontSize: largeScreen ? '30px' : extraLargeScreen ? '20px' : '18px',
              fontWeight: 'bolder'
            }}>
            Purchase Order
          </Typography>
        </Grid>
        <div style={{ display: 'flex' }}>
          <Grid
            item
            xs={8}
            sx={{
              marginTop: '.5rem'
            }}>
            <Typography
              sx={{
                backgroundColor: Colors.LIGHT_TOURQUISE,
                fontSize: textSize,
                width: 'max-content',
                padding: '0rem .2rem',
                marginBottom: '.5rem',
                fontWeight: 'bold',
                color: Colors.BG_BLACK
              }}>
              Purchase Order Details
            </Typography>
            {!show && estimateNumber && (
              <Grid
                sx={{
                  display: 'flex',
                  fontSize: textSize,
                  color: Colors.BG_BLACK
                }}>
                <div style={{ width: '40%' }}>
                  <strong>PO # </strong>
                </div>
                <div>{estimateNumber}</div>
              </Grid>
            )}

            {documentContentMapped?.map(
              (item, index) =>
                item?.name !== 'Vendor' && (
                  <Grid
                    sx={{
                      display: 'flex',
                      fontSize: textSize,
                      marginTop: '.2rem',
                      color: Colors.BG_BLACK
                    }}>
                    <div style={{ width: '40%' }}>
                      <strong>{item?.name}</strong>
                    </div>
                    <div>{item?.name === 'Date' ? getTodaysDate() : item?.value}</div>
                  </Grid>
                )
            )}
          </Grid>
          <Grid item xs={4} sx={{ marginTop: '.5rem' }}>
            <Typography
              sx={{
                backgroundColor: Colors.LIGHT_TOURQUISE,
                fontSize: textSize,
                width: 'max-content',
                padding: '0rem .2rem',
                marginBottom: '.5rem',
                fontWeight: 'bold',
                color: Colors.BG_BLACK
              }}>
              Order To
            </Typography>
            {documentContentMapped?.map(
              (item, index) =>
                item?.name === 'Vendor' && (
                  <Grid
                    sx={{
                      fontSize: textSize,
                      marginTop: '.2rem',
                      color: Colors.BG_BLACK
                    }}>
                    <div
                      style={{
                        marginTop: '.3rem',
                        overflowWrap: 'break-word'
                      }}>
                      {item?.value}
                    </div>
                  </Grid>
                )
            )}
          </Grid>
        </div>
        {documentLineItemsMapped?.length === 0 ? (
          ''
        ) : (
          <Grid>
            <Grid
              xs={12}
              container
              sx={{
                fontWeight: 'bold',
                fontSize: textSize,
                mt: '1em',
                mb: '8px',
                padding: '.1rem',
                backgroundColor: Colors.LIGHT_TOURQUISE,
                color: Colors.BG_BLACK
              }}>
              <Grid xs={6}>{DESCRIPTION}</Grid>
              <Grid xs={2}>{UNIT_PRICE}</Grid>
              <Grid xs={2}>{QUANTITY}</Grid>
              <Grid xs={2}>{TOTAL}</Grid>
            </Grid>

            {documentLineItemsMapped?.slice(0, lineItemsPerPage)?.map((items, index) => (
              <Grid
                xs={12}
                container
                sx={{
                  fontSize: textSize,
                  borderBottom: '1px solid black',
                  mb: '8px',
                  color: Colors.BG_BLACK
                }}>
                <Grid xs={5.75}>{items?.description}</Grid>
                <Grid xs={0.25}></Grid>
                <Grid xs={2}>{items?.unit_price}</Grid>
                <Grid xs={2}>{items?.qty}</Grid>
                <Grid xs={2}>{items?.total}</Grid>
              </Grid>
            ))}
          </Grid>
        )}
      </Card>

      {documentLineItemsMapped?.length > 12 &&
        Array?.from({ length: totalCardPages }, () => {
          const currentIndex = startIndex;
          const cardId = `Po${currentIndex}`;
          startIndex++;
          return (
            <Card
              key={currentIndex}
              sx={{
                height: height,
                width: width
                  ? width
                  : largeScreen
                  ? '24vw'
                  : extraLargeScreen
                  ? '22vw'
                  : { xs: '65vw', md: '30vw', lg: '22em' },
                padding: '1.5em 2em',
                // borderRadius: '1em',
                cursor: cursor,
                backgroundColor: Colors.WHITE,
                mt: '1em',
                color: Colors.BG_BLACK
              }}
              id={cardId}
              onClick={onClick}>
              <Grid>
                <Grid
                  xs={12}
                  container
                  sx={{
                    fontWeight: 'bold',
                    fontSize: textSize,
                    mt: '1em',
                    mb: '8px',
                    padding: '.1rem',
                    backgroundColor: Colors.LIGHT_TOURQUISE
                  }}>
                  <Grid xs={6}>{DESCRIPTION}</Grid>
                  <Grid xs={2}>{UNIT_PRICE}</Grid>
                  <Grid xs={2}>{QUANTITY}</Grid>
                  <Grid xs={2}>{TOTAL}</Grid>
                </Grid>

                {documentLineItemsMapped
                  ?.slice(currentIndex * lineItemsPerPage, (currentIndex + 1) * lineItemsPerPage)
                  ?.map((items, idx) => (
                    <Grid
                      xs={12}
                      container
                      sx={{
                        fontSize: textSize,
                        borderBottom: '1px solid black',
                        mt: '16px',
                        color: Colors.BG_BLACK
                      }}
                      key={idx}>
                      <Grid xs={5.75}>{items?.description}</Grid>
                      <Grid xs={0.25}></Grid>
                      <Grid xs={2}>{items?.unit_price}</Grid>
                      <Grid xs={2}>{items?.qty}</Grid>
                      <Grid xs={2}>{items?.total}</Grid>
                    </Grid>
                  ))}
              </Grid>
            </Card>
          );
        })}
    </Grid>
  );
}
