import React, { useState } from 'react';
import { useToast } from '../toast/toastContext';

import {
  Grid,
  Typography,
  Button,
  CircularProgress,
  Box,
  Skeleton,
  TextField
} from '@mui/material';

import { generateBill, getDocumentCount } from '../services/services';
import { Colors } from '../config/default';
import { manualUserComponent } from '../constants/appConstants';
import DateRangePickerWithButtons from './DatePicker';
import { useEffect } from 'react';
import CustomTextField from './textfield';

export default function CreateBill({ userEmail, setOpen, show, role }) {
  const { showToast } = useToast();
  const { MAKE_PAYMENT, SEND_BILL, AMOUNT, AMOUNT_CHECK, SEND_BILL_VENDOR } = manualUserComponent;
  const [billAmount, setBillAmount] = useState('');
  const [loading, setLoading] = useState(false);
  const [documentLoading, setDocumentLoading] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [documentCount, setDocumentCount] = useState(null);
  const [pageCount, setPageCount] = useState(null);

  const handleAmountChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue === '' || (/^\d*\.?\d*$/.test(inputValue) && parseFloat(inputValue) > 0)) {
      setBillAmount(inputValue);
    }
  };
  const handleGenerateBill = async () => {
    setLoading(true);
    const params = {
      customerEmail: userEmail,
      amount: billAmount,
      callSource: show
    };
    const result = await generateBill(params);
    if (result.status === 200) {
      showToast(MAKE_PAYMENT, 'success');
      setBillAmount(''), setOpen(false);
    } else {
      showToast(result?.response?.data?.metadata?.message, 'error');
    }
    setLoading(false);
  };

  const getData = async (start, end, email) => {
    if (start && end) {
      setDocumentLoading(true);
      start?.setHours(0, 0, 0, 0);
      end?.setHours(23, 59, 59, 999);
      const utcStartDate = new Date(start?.getTime() - start?.getTimezoneOffset() * 60000);
      const utcEndDate = new Date(end?.getTime() - end?.getTimezoneOffset() * 60000);
      const result = await getDocumentCount(
        utcStartDate?.toISOString(),
        utcEndDate?.toISOString(),
        email,
        role
      );

      if (result?.status === 200) {
        setDocumentLoading(false);
        setDocumentCount(result?.data?.payload?.data?.count);
        setPageCount(result?.data?.payload?.data?.totalPageCount);
      }
      setDocumentLoading(false);
    }
  };

  useEffect(() => {
    getData(startDate, endDate, userEmail);
  }, [startDate, endDate]);

  return (
    <Grid item>
      <Typography sx={{ color: Colors.TOURQUISE, fontSize: '1.3rem' }} textAlign="center">
        {show === 'api' ? SEND_BILL_VENDOR : SEND_BILL}
      </Typography>
      <Grid container sx={{ m: '1.25em 0em', justifyContent: 'space-between' }}>
        {documentLoading ? (
          <Box>
            <Skeleton style={{ height: '1.5rem', width: '8rem' }} animation="wave" />
            <Skeleton style={{ height: '1.5rem', width: '8rem', mt: '.5rem' }} animation="wave" />
          </Box>
        ) : (
          <Box>
            <Typography sx={{ color: Colors.TEXTGREY }}>Document Count: {documentCount}</Typography>
            <Typography sx={{ color: Colors.TEXTGREY }}>Page Count: {pageCount}</Typography>
          </Box>
        )}

        <DateRangePickerWithButtons
          toShow="createBill"
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
      </Grid>

      <Typography sx={{ color: Colors.TEXTGREY, mb: '1rem', ml: '.2rem' }}>
        Email: {userEmail}
      </Typography>
      <CustomTextField
        value={billAmount}
        setValue={setBillAmount}
        placeHolder="Enter Amount $"
        type="number"
        onChange={handleAmountChange}
      />
      {parseFloat(billAmount) < 0.5 ? (
        <Box sx={{ color: Colors.RED, margin: '.3rem 0rem .8rem .3rem', fontSize: '14px' }}>
          {AMOUNT_CHECK}
        </Box>
      ) : (
        <Box sx={{ margin: '.3rem 0rem 1rem .2rem', fontSize: '14px', height: '13px' }}></Box>
      )}
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="outlined"
          disabled={billAmount === '' || parseFloat(billAmount) < 0.5}
          onClick={handleGenerateBill}
          sx={{
            height: '2.2rem',
            marginTop: '1em',
            width: '5rem',
            borderRadius: '10px',
            color: Colors.WHITE
          }}>
          {loading ? <CircularProgress size={20} color="inherit" /> : 'Send'}
        </Button>
      </Grid>
    </Grid>
  );
}
