import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Grid, MenuItem, Select, Typography } from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { getOwnerUsers, getGlInfo } from '../services/services';
import { HtmlTooltip, BootstrapInput } from '../services/common';
import { Colors } from '../config/default';
import DropdownEmails from './dropDownEmails';
import { policyPage } from '../constants/appConstants';

export default function LevelCards({
  index,
  setData,
  item,
  allEmails,
  setAllEmails,
  policyBase,
  policyData
}) {
  const userDetails = useSelector((state) => state?.userInfo?.userInfo);
  const erpType = userDetails?.user?.erpType;
  const { MAX_VALUE_CHECK, REQUIRED_APPROVALS, AUTO, APPROVED } = policyPage;
  const approvalArray = [0, 1, 2, 3];
  const [vendors, setVendors] = useState([]);
  const [jobIds, setJobIds] = useState([]);
  const policyOptions = policyBase === 'vendor' ? vendors : jobIds;
  const getInformation = async () => {
    const res = await getGlInfo(userDetails?.user?.email);
    if (res?.data?.metadata?.status === 'SUCCESS') {
      setVendors(res?.data?.payload?.data?.vendorsList?.map((item) => item?.name));
      setJobIds(
        res?.data?.payload?.data?.jobIdsList?.map((item) =>
          erpType === 'Foundation'
            ? item?.deptNum + ' - ' + item?.name
            : item?.id + ' - ' + item?.name
        )
      );
    }
  };

  useEffect(() => {
    getInformation();
  }, []);
  const getUsers = async () => {
    const result = await getOwnerUsers();
    if (result?.status === 200) {
      const filteredUsers = result?.data?.payload?.data?.filter((item) => {
        return item?.role !== 'Accountant' && item?.signUp === true;
      });
      setAllEmails(filteredUsers);
    }
  };
  useEffect(() => {
    getUsers();
  }, []);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        borderRadius: '10px'
      }
    }
  };

  const removeselectedVendor = (i) => {
    let newVendor = [];
    policyData?.levels?.forEach((level) => {
      level?.match?.forEach((vendor) => {
        newVendor = [...newVendor, vendor];
      });
    });

    const filteredEmails =
      policyOptions &&
      policyOptions?.filter((childItem) => {
        return item?.match?.includes(childItem) || !newVendor.includes(childItem);
      });
    return filteredEmails?.map((item) => item);
  };
  const removeSelectedValue = (i) => {
    let newArray = [];
    item?.approvals?.forEach((email) => {
      newArray = [...newArray, ...email];
    });

    const filteredEmails =
      allEmails &&
      allEmails?.filter((childItem) => {
        return (
          (item?.approvals?.length > i && item?.approvals[i]?.includes(childItem?.email)) ||
          !newArray.includes(childItem?.email)
        );
      });
    return filteredEmails?.map((item) => item?.email);
  };

  const handleMaxValueChange = (e) => {
    const newMaxValue = Math.max(0, parseInt(e.target.value, 10));
    setData({ ...item, max: newMaxValue });
  };

  const handleRequiredApprovalsChange = (e) => {
    const newRequiredApprovals = parseInt(e.target.value, 10);
    const currentApprovals = item?.approvals || [];
    const selectedApprovals = currentApprovals.slice(0, newRequiredApprovals);

    setData({
      ...item,
      requiredApprovals: newRequiredApprovals,
      approvals: selectedApprovals
    });
  };

  return (
    <>
      <Grid
        container
        sx={{
          padding: '.7em 0em',
          mt: '1em',
          backgroundColor: Colors.NAV_BLACK,
          borderRadius: '0.55em',
          boxShadow: 'none',
          paddingLeft: '1.25em'
        }}>
        <Grid
          item
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            marginRight: '.5em'
          }}>
          <Grid item>
            <Typography
              sx={{
                color: Colors.WHITE,
                fontSize: '0.75em'
              }}>
              Level {index + 1}
            </Typography>
          </Grid>

          <Grid item container sx={{ gap: '0.7em', alignItems: 'center' }}>
            {policyBase === 'vendor' || policyBase === 'jobId' ? (
              <DropdownEmails
                setMails={(vendor) => setData({ ...item, match: vendor })}
                mails={item?.match}
                apiData={removeselectedVendor()}
                toShow="vendor"
                height={true}
              />
            ) : (
              <>
                <Grid item>
                  <input
                    readOnly
                    style={{
                      width: '5em',
                      height: '1.2em',
                      outline: 'none',
                      border: '.7px solid #BABABA',
                      backgroundColor: 'transparent',
                      color: Colors.LIGHTBLUE,
                      borderRadius: '7px',
                      padding: '0.5em 0.2em',
                      fontSize: '0.75em',
                      textAlign: 'center',
                      fontWeight: 'bold'
                    }}
                    type="number"
                    value={item?.min}
                  />
                </Grid>
                <Grid item>
                  <Typography sx={{ color: Colors.TEXTGREY, fontSize: '0.8em' }}>to</Typography>
                </Grid>
                <HtmlTooltip
                  title={
                    <Typography color="inherit" sx={{ fontSize: '1em' }}>
                      {MAX_VALUE_CHECK}
                    </Typography>
                  }>
                  <Grid item>
                    <input
                      style={{
                        width: '5em',
                        outline: 'none',
                        height: '1.2em',
                        border: '.7px solid #BABABA',
                        backgroundColor: 'transparent',
                        color: Colors.LIGHTBLUE,
                        min: '0',
                        padding: '0.5em 0.2em',
                        borderRadius: '7px',
                        fontSize: '0.75em',
                        textAlign: 'center',
                        fontWeight: 'bold'
                      }}
                      type="number"
                      value={item?.max}
                      onChange={handleMaxValueChange}
                    />
                  </Grid>
                </HtmlTooltip>
              </>
            )}
            <Grid item>
              <ArrowRightAltIcon sx={{ fontSize: '2em', color: Colors.TEXTGREY }} />
            </Grid>
            <Grid item>
              <Typography sx={{ color: Colors.TEXTGREY, fontSize: '0.8em' }}>
                {REQUIRED_APPROVALS}
              </Typography>
            </Grid>
            <Grid item>
              <Select
                variant="outlined"
                sx={{
                  borderRadius: '0.6em',
                  outline: 'none',
                  color: Colors.TEXTGREY,
                  height: '2.4em',
                  fontSize: '0.75em',
                  padding: '1em 0em',
                  textAlign: 'left',
                  border: '.7px solid #BABABA'
                }}
                MenuProps={MenuProps}
                input={<BootstrapInput />}
                value={item?.requiredApprovals}
                onChange={handleRequiredApprovalsChange}>
                {approvalArray?.map((num, i) => {
                  return (
                    <MenuItem
                      key={i}
                      sx={{
                        color: Colors.TEXTGREY,
                        borderRadius: '8px',
                        margin: '0px 8px',
                        '&:hover': {
                          borderRadius: '7px',
                          backgroundColor: Colors.BG_BLACK
                        }
                      }}
                      value={num}>
                      {num}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
            <Grid item>
              <ArrowRightAltIcon sx={{ fontSize: '2em', color: Colors.TEXTGREY }} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {item?.requiredApprovals > 0 && (
            <Grid container item sx={{ gap: '1em', mt: { xs: '0.5em', lg: '0' } }}>
              {Array?.from({ length: item?.requiredApprovals }, (_, i) => (
                <DropdownEmails
                  key={i}
                  index={i}
                  setMails={(mails) => setData({ ...item, approvals: mails })}
                  mails={item?.approvals}
                  apiData={removeSelectedValue(i)}
                  toShow="policy"
                />
              ))}
            </Grid>
          )}
          {item?.requiredApprovals === 0 && (
            <Grid
              item
              sx={{
                display: 'flex',
                height: '100%',
                gap: '0.5em',
                alignItems: 'center',
                paddingTop: '1em'
              }}>
              <Typography
                sx={{
                  color: Colors.TEXTGREY,
                  fontWeight: '400',
                  fontSize: '0.8em'
                }}>
                {AUTO}
              </Typography>
              <Typography
                sx={{
                  color: Colors.LIGHTBLUE,
                  fontWeight: '600',
                  fontSize: '0.8em'
                }}>
                {APPROVED}
              </Typography>
              <CheckCircleIcon sx={{ fontSize: '1.2em', color: Colors.LIGHTBLUE }} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
}
