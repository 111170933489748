import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Resizable } from 're-resizable';

import { ArrowRightAlt, GetAppOutlined, HistorySharp } from '@mui/icons-material';
import { Drawer, Button, Grid, CircularProgress } from '@mui/material';

import LineItems from './lineItems';
import { canvasDetails, lineItemsText } from '../constants/appConstants';
import { Colors } from '../config/default';
import { compareObjects } from '../services/validationService';
import Prompt from './prompt';
import { getDocumentDetails } from '../services/services';
import { useToast } from '../toast/toastContext';
import { isEmpty } from 'lodash';
import Btn from './button';
import ButtonDropdown from './buttonDropdown';

export default function BottomDrawer({
  isDrawerOpen,
  setIsDrawerOpen,
  itemsData,
  handleClick,
  setRef,
  setLineItemsData,
  isLineItemsMatched,
  isPoMatched,
  endIcon,
  disabled,
  onClick,
  annotationType,
  data,
  costType,
  jobId,
  costId,
  phase,
  glAccount,
  obj,
  setObj,
  handleBoxClick,
  fetchingPO,
  lineItemInitialObj,
  openPrompt,
  setOpenPrompt,
  constructionCompany,
  valueChanged,
  fetchPo,
  lineItem,
  id,
  callSource,
  setLineItem,
  matchPoLoading,
  totalItems,
  setTolalItems,
  headers,
  handleFieldChange,
  imageDimensions,
  setClickedBoxIndex,
  matchDtLoading,
  onDtClick,
  refId,
  disableDt,
  isInvoiceNumberMatched,
  serviceCode,
  equipmentNo,
  serviceAndEquipmentPermission
}) {
  const { showToast } = useToast();
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  });

  const [resetting, setResetting] = useState(false);

  const ResetLineItems = async () => {
    setResetting(true);
    const dataFound = await getDocumentDetails(id, callSource);
    if (dataFound?.status === 200) {
      setLineItem(dataFound?.data?.payload?.data?.document?.lineItems);
      setObj(lineItemInitialObj);
      showToast('Line items reset successfully', 'success');
    } else {
      showToast('Something went wrong ', 'error');
    }
    setResetting(false);
  };
  useEffect(() => {
    if (lineItemInitialObj[headers[0]]) {
      setTolalItems(new Array(lineItemInitialObj?.Amount?.length)?.fill(0));
    } else {
      setTolalItems([]);
    }
  }, [lineItem]);
  const resultArray = compareObjects(lineItemInitialObj, obj);
  function differentLineItems(obj) {
    if (obj === false) {
      return true;
    }
    for (let key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] === false) {
        return true;
      }
    }
    return false;
  }
  const isDifferentLineItems = differentLineItems(resultArray);

  useEffect(() => {
    if (costType && valueChanged && !fetchingPO && resultArray?.userCostType) {
      const newOnj = { ...obj };
      newOnj?.userCostType?.fill(costType);
      setObj(newOnj);
    }
  }, [costType]);
  useEffect(() => {
    if (costId && valueChanged && !fetchingPO && resultArray?.userCostId) {
      const newOnj = { ...obj };
      newOnj?.userCostId?.fill(costId);
      setObj(newOnj);
    }
  }, [costId]);
  useEffect(() => {
    if (jobId && valueChanged && !fetchingPO && resultArray?.userJobId) {
      const newOnj = { ...obj };
      newOnj?.userJobId?.fill(jobId);
      setObj(newOnj);
    }
  }, [jobId]);
  useEffect(() => {
    if (glAccount && valueChanged && !fetchingPO && resultArray?.GlAccount) {
      const newOnj = { ...obj };
      newOnj?.GlAccount?.fill(glAccount);
      setObj(newOnj);
    }
  }, [glAccount]);
  useEffect(() => {
    if (phase && valueChanged && !fetchingPO && resultArray?.userPhases) {
      const newOnj = { ...obj };
      newOnj?.userPhases?.fill(phase);
      setObj(newOnj);
    }
  }, [phase]);
  useEffect(() => {
    if (serviceCode && valueChanged && !fetchingPO && resultArray?.serviceCode) {
      const newOnj = { ...obj };
      newOnj?.serviceCode?.fill(serviceCode);
      setObj(newOnj);
    }
  }, [serviceCode]);
  useEffect(() => {
    if (equipmentNo && valueChanged && !fetchingPO && resultArray?.equipmentNo) {
      const newOnj = { ...obj };
      newOnj?.equipmentNo?.fill(equipmentNo);
      setObj(newOnj);
    }
  }, [equipmentNo]);

  const { SHOW_LINEITEMS } = lineItemsText;
  const { FETCH_PO, FETCH_PO_DESCRIPTION, RESET, FETCH_SC_DESCRIPTION } = canvasDetails;
  const userDetails = useSelector((state) => state?.userInfo?.userInfo);
  const handleOpen = () => setOpenPrompt(true);
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const POPermission =
    userDetails?.user?.poMatchPermission &&
    annotationType !== 'Estimate' &&
    annotationType !== 'DeliveryTicket' &&
    annotationType !== 'Po';
  const list = (anchor) => (
    <Resizable
      defaultSize={{
        height: '305px',
        width: '100%'
      }}
      minHeight="150px"
      maxHeight="80vh"
      enable={{ top: true }}
      style={{
        overflow: 'hidden',
        backgroundColor: Colors.NAV_BLACK
      }}>
      <LineItems
        setDrawerOpen={setIsDrawerOpen}
        isPoMatched={isPoMatched}
        itemsData={itemsData}
        handleClick={handleClick}
        setRef={setRef}
        setLineItemsData={setLineItemsData}
        isLineItemsMatched={isLineItemsMatched}
        obj={obj}
        setObj={setObj}
        annotationType={annotationType}
        data={data}
        constructionCompany={constructionCompany}
        lineItem={lineItem}
        totalItems={totalItems}
        setTolalItems={setTolalItems}
        headers={headers}
        handleFieldChange={handleFieldChange}
        handleBoxClick={handleBoxClick}
        imageDimensions={imageDimensions}
        setClickedBoxIndex={setClickedBoxIndex}
        isInvoiceNumberMatched={isInvoiceNumberMatched}
        serviceAndEquipmentPermission={serviceAndEquipmentPermission}
      />
      <Grid
        item
        xs={12}
        sx={{
          height: '19.5%',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          marginBottom: '1rem',
          backgroundColor: Colors.NAV_BLACK
        }}>
        <Button
          disabled={!isDifferentLineItems || resetting}
          sx={{
            borderRadius: '5px',
            color: Colors.RED,
            fontWeight: 'bold',
            fontSize: { xs: '10px', lg: '15px' },
            width: '12rem',
            height: '2.5rem',
            '&:hover': {
              background: 'none'
            },
            '&:disabled': {
              color: 'gray',
              cursor: 'not-allowed'
            }
          }}
          onClick={ResetLineItems}>
          {resetting ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            <>
              {RESET}
              <HistorySharp sx={{ width: { xs: '13px', lg: '20px' }, ml: 1 }} />
            </>
          )}
        </Button>
        {annotationType !== 'Estimate' &&
          annotationType !== 'DeliveryTicket' &&
          annotationType !== 'Po' && (
            <>
              <ButtonDropdown
                text="Fetch"
                width="8rem"
                showPrompt={true}
                disable={!userDetails?.user?.poMatchPermission}
                icon={<GetAppOutlined sx={{ width: { xs: '13px', lg: '20px' } }} />}
                color={Colors.WHITE}
                openPrompt={openPrompt}
                setOpenPrompt={setOpenPrompt}
                options={[
                  {
                    name: 'Purchase Order',
                    disable: disabled,
                    action: handleOpen,
                    promptTitle: FETCH_PO,
                    promptDescription: FETCH_PO_DESCRIPTION,
                    promptLoading: fetchingPO,
                    promptFunction: () => fetchPo('Po'),
                    showButton: POPermission && annotationType !== 'G702'
                  },
                  {
                    name: 'Subcontract',
                    disable: disabled,
                    action: handleOpen,
                    promptTitle: 'Fetch Subcontract',
                    promptDescription: FETCH_SC_DESCRIPTION,
                    promptLoading: fetchingPO,
                    promptFunction: () => fetchPo('subContract'),
                    showButton: POPermission
                  }
                ]}
              />
              <ButtonDropdown
                text="Match"
                width="8rem"
                icon={endIcon}
                color={Colors.WHITE}
                showPrompt={false}
                disable={
                  !userDetails?.user?.poMatchPermission && !userDetails?.user?.deliveryTicketUpload
                }
                options={[
                  {
                    name: 'Purchase Order',
                    disable: disabled,
                    action: () => onClick('Po'),
                    showButton: POPermission && annotationType !== 'G702'
                  },
                  {
                    name: 'Delivery Ticket',
                    disable: disableDt || isEmpty(refId),
                    action: () => onDtClick('DeliveryTicket'),
                    showButton:
                      (annotationType === 'Invoice' || annotationType === 'G702') &&
                      userDetails?.user?.deliveryTicketUpload &&
                      (isEmpty(refId) || refId?.length === 1)
                  },
                  {
                    name: 'Subcontract',
                    disable: disabled,
                    action: () => onClick('subContract'),
                    showButton: POPermission
                  }
                ]}
              />
            </>
          )}
      </Grid>
    </Resizable>
  );

  return (
    <div>
      {['bottom']?.map((anchor) => (
        <React.Fragment key={anchor}>
          <Button
            style={{
              fontSize: '13px',
              borderRadius: '5px',
              background: 'transparent',
              color: '#ffffff'
            }}
            endIcon={<ArrowRightAlt sx={{ fontSize: '15px', color: Colors.TOURQUISE }} />}
            onClick={() => setIsDrawerOpen(true)}>
            {SHOW_LINEITEMS}
          </Button>
          <Drawer
            style={{ position: 'initial' }}
            hideBackdrop
            disableEnforceFocus
            anchor={anchor}
            open={isDrawerOpen}
            onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
