import * as React from 'react';
import Switch from '@mui/material/Switch';
import { Grid, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { Colors } from '../config/default';

const StyledSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: Colors.WHITE
  },
  '& .MuiSwitch-track': {
    backgroundColor: Colors.RED,
    transparency: 1
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: Colors.TOURQUISE
  }
}));
export default function SwitchComponent({ description, checked, setChecked, disable }) {
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <Grid
      item
      xs={12}
      sx={{
        marginTop: '.3rem',
        display: 'inline-flex',
        alignItems: 'center'
      }}>
      <Grid item style={{ width: '250px' }}>
        <Typography sx={{ color: Colors.WHITE, fontSize: '14px' }}>{description}</Typography>
      </Grid>
      <Grid item>
        <StyledSwitch
          disabled={disable}
          checked={checked}
          onChange={handleChange}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </Grid>
    </Grid>
  );
}
