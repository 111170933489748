import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';

import { Box, Grid, Typography, CircularProgress } from '@mui/material';

import { Colors } from '../config/default';
import { ErrorOutline } from '@mui/icons-material';
import { tableComponent } from '../constants/appConstants';
import ImageCard from '../components/imageCard';

export default function ErpDocuments({
  searchedResult,
  isChequeSearched,
  isLoading,
  handleSearchFromErp,
  setChequeNum
}) {
  const navigate = useNavigate();
  const { SEARCH_NOT_FOUND, SEARCH_CHEQUES } = tableComponent;

  const searchedData = localStorage.getItem('chequeNo');

  useEffect(() => {
    if (searchedData && searchedData !== '' && searchedData !== 'undefined') {
      setChequeNum(searchedData);
      handleSearchFromErp(searchedData);
    }
  }, []);

  return (
    <>
      {isLoading ? (
        <Grid
          container
          sx={{
            height: '70vh',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <CircularProgress size={150} color="primary" />
        </Grid>
      ) : isEmpty(searchedResult) ? (
        <Grid
          container
          sx={{
            alignItems: 'center',
            gap: '1em',
            justifyContent: 'center',
            ml: '2em',
            height: '75vh'
          }}>
          <Box
            sx={{
              backgroundColor: Colors.NAV_BLACK,
              padding: '.5em',
              borderRadius: '10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '.8em',
              color: Colors.TEXTGREY
            }}>
            <ErrorOutline sx={{ color: Colors.TEXTGREY, marginRight: '.3em', fontSize: '1.3em' }} />
            {!isChequeSearched ? SEARCH_CHEQUES : SEARCH_NOT_FOUND}
          </Box>
        </Grid>
      ) : (
        <Grid
          container
          sx={{
            height: '75vh',
            justifyContent: 'space-between',
            padding: '.5rem 2rem',
            overflowY: 'auto',

            '&::-webkit-scrollbar': {
              width: '.2em',
              height: '0em',
              borderRadius: '20px'
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.11)',
              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.11)',
              outline: '1px solid slategrey'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#fff',
              outline: '1px solid slategrey'
            }
          }}>
          <Grid item xs={12} lg={12}>
            <Typography
              sx={{ color: Colors.TOURQUISE, ml: '.5rem', mb: '.5rem', fontSize: '1.2rem' }}>
              Documents
            </Typography>
            <Grid
              container
              sx={{
                color: Colors.TEXTGREY,
                maxHeight: '67vh',
                gap: '2%',
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  width: '.2em',
                  height: '0em',
                  borderRadius: '20px'
                },
                '&::-webkit-scrollbar-track': {
                  boxShadow: 'inset 0 0 6px rgba(0,0,0,0.11)',
                  webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.11)',
                  outline: '1px solid slategrey'
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#fff',
                  outline: '1px solid slategrey'
                }
              }}>
              {searchedResult?.map((doc, i) => {
                return (
                  <ImageCard
                    data={doc}
                    onClick={() => navigate(`/details/${doc?._id}`)}
                    btnText="View Details"
                    width={270}
                    height={338}
                    variant="body2"
                    fontLength={13}
                    btnColor={Colors.DEEPBLUE}
                    url={doc?.convertedS3Keys[0]}
                  />
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}
