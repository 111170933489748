import React from 'react';
import { isEmpty, debounce } from 'lodash';

import { styled } from '@mui/material/styles';
import { Search, Close } from '@mui/icons-material';
import InputBase from '@mui/material/InputBase';
import Tooltip from '@mui/material/Tooltip';
import { Colors } from '../config/default';
const StyledSearch = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '10px',
  border: '1px solid #797979',
  height: '2rem',
  color: Colors.WHITE,
  display: 'flex',
  alignItems: 'center',

  [theme.breakpoints.up('xs')]: {
    marginRight: theme.spacing(1),
    width: 'max-content'
  },
  [theme.breakpoints.up('sm')]: {
    width: 'max-content',
    marginTop: '0'
  }
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  [theme.breakpoints.up('xs')]: {
    fontSize: '0.65em'
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '1em'
  },

  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 1),
    transition: theme.transitions.create('width'),
    width: '250px',
    [theme.breakpoints.up('xs')]: {
      width: '100px'
    },
    [theme.breakpoints.up('sm')]: {
      width: '130px'
    },
    [theme.breakpoints.up('md')]: {
      width: '250px'
    }
  }
}));
function SearchBar({
  setSearchProducts,
  text,
  setCurrentPage,
  searchProducts,
  tooltipText,
  handleKeyPress,
  handleClear,
  filteredData,
  recordExist,
  width
}) {
  const handleClearDebounced = debounce(handleClear, 300);

  return (
    <Tooltip title={tooltipText}>
      <StyledSearch>
        <StyledInputBase
          sx={{ width: { xs: 'auto', lg: width ? width : 'auto' } }}
          placeholder={text}
          value={searchProducts}
          inputProps={{ 'aria-label': 'search' }}
          onChange={(e) => {
            setSearchProducts(e.target.value);
            setCurrentPage ? setCurrentPage(1) : '';
          }}
          onKeyPress={(e) =>
            handleKeyPress && e?.key === 'Enter' && searchProducts !== '' ? handleKeyPress() : ''
          }
        />

        {!isEmpty(filteredData) || recordExist ? (
          <Close
            sx={{ color: Colors.DEEPGREY, marginRight: '.5em', cursor: 'pointer' }}
            onClick={() => handleClearDebounced()}
          />
        ) : (
          <Search
            sx={{ color: Colors.DEEPGREY, marginRight: '.5em', cursor: 'pointer' }}
            onClick={() => {
              searchProducts !== '' ? handleKeyPress() : '';
            }}
          />
        )}
      </StyledSearch>
    </Tooltip>
  );
}

export default SearchBar;
