import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Box, Grid, Typography, CircularProgress, IconButton } from '@mui/material';

import { Colors } from '../config/default';
import { ErrorOutline, ChevronLeft, ChevronRight } from '@mui/icons-material';

import { useToast } from '../toast/toastContext';
import Btn from '../components/button';
import CustomTextField from '../components/textfield';
import { tableComponent } from '../constants/appConstants';
import { truncateFileName } from '../services/common';
import { deleteAttachment, editAttachment } from '../services/services';
import Prompt from '../components/prompt';
import ImageCard from '../components/imageCard';

export default function Attachments({
  searchedResult,
  isAttachmentSearched,
  isLoading,
  handleSearchAttachment,
  setSearchAttachments,
  instrumentId,
  setInstumentId
}) {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const userDetails = useSelector((state) => state?.userInfo?.userInfo);
  const companyId = userDetails?.company?._id;
  const { SEARCH_NOT_FOUND, SEARCH_ATTACHMENTS } = tableComponent;
  const [editattachment, setEditAttachment] = useState(false);
  const [openPrompt, setOpenPrompt] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const searchedData = localStorage.getItem('instrumentId');
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isEnlarged, setIsEnlarged] = useState(false);
  useEffect(() => {
    if (searchedData && searchedData !== '' && searchedData !== 'undefined') {
      setSearchAttachments(searchedData);
      handleSearchAttachment(searchedData);
    }
  }, []);
  const handleEdit = () => {
    setEditAttachment(true);
  };
  const handleCancel = () => {
    setInstumentId(searchedResult?.attachment?.referenceNo);
    setEditAttachment(false);
  };
  const handleSave = async (id) => {
    setLoading(true);
    const params = {
      instrumentNo: instrumentId,
      companyId: companyId
    };
    const result = await editAttachment(params, id);
    if (result?.status === 200) {
      setSearchAttachments(instrumentId);
      setEditAttachment(false);
      showToast(result?.data?.metadata?.message, 'success');
      handleSearchAttachment(instrumentId);
    } else {
      showToast(
        result?.response?.data?.metadata?.message || result?.data?.metadata?.message,
        'error'
      );
    }
    setLoading(false);
  };
  const handleDelete = async () => {
    setIsLoading(true);
    const result = await deleteAttachment(searchedResult?.attachment?._id, companyId);
    if (result?.status === 200) {
      showToast(result?.data?.metadata?.message, 'success');
      handleSearchAttachment();
    } else {
      showToast(
        result?.response?.data?.metadata?.message || result?.data?.metadata?.message,
        'error'
      );
    }
    setIsLoading(false);
  };
  const handleNextImage = () => {
    setCurrentImageIndex(
      (currentImageIndex + 1) % searchedResult?.attachment?.convertedS3Keys?.length
    );
  };
  const handlePrevImage = () => {
    const nextIndex = currentImageIndex - 1;
    if (nextIndex < 0) {
      setCurrentImageIndex(searchedResult?.attachment?.convertedS3Keys?.length - 1);
    } else {
      setCurrentImageIndex(nextIndex);
    }
  };
  const handleImageClick = () => {
    setIsEnlarged(!isEnlarged);
  };
  const smallImageStyle = {
    height: '100%',
    aspectRatio: 1,
    cursor: 'pointer',
    opacity: 1,
    zIndex: 1,
    objectFit: 'contain',
    width: '80%'
  };

  const largeImageStyle = {
    width: '100vw',
    height: '100vh',
    objectFit: 'contain',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: isEnlarged ? 9999 : -1,
    cursor: 'pointer',
    opacity: isEnlarged ? 1 : 0
  };
  return (
    <>
      {isLoading ? (
        <Grid
          container
          sx={{
            height: '70vh',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <CircularProgress size={150} color="primary" />
        </Grid>
      ) : isEmpty(searchedResult) ? (
        <Grid
          container
          sx={{
            alignItems: 'center',
            gap: '1em',
            justifyContent: 'center',
            ml: '2em',
            height: '75vh'
          }}>
          <Box
            sx={{
              backgroundColor: Colors.NAV_BLACK,
              padding: '.5em',
              borderRadius: '10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '.8em',
              color: Colors.TEXTGREY
            }}>
            <ErrorOutline sx={{ color: Colors.TEXTGREY, marginRight: '.3em', fontSize: '1.3em' }} />
            {!isAttachmentSearched ? SEARCH_ATTACHMENTS : SEARCH_NOT_FOUND}
          </Box>
        </Grid>
      ) : (
        <Grid
          container
          sx={{
            height: '75vh',
            justifyContent: 'space-between',
            padding: '.5rem 2rem',
            overflowY: 'auto',

            '&::-webkit-scrollbar': {
              width: '.2em',
              height: '0em',
              borderRadius: '20px'
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.11)',
              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.11)',
              outline: '1px solid slategrey'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#fff',
              outline: '1px solid slategrey'
            }
          }}>
          <Grid
            item
            xs={12}
            lg={3.5}
            sx={{
              display: 'flex',
              flexDirection: 'column'
            }}>
            <Typography
              sx={{ color: Colors.TOURQUISE, ml: '1rem', mb: '.5rem', fontSize: '1.2rem' }}>
              Attachment
            </Typography>
            <Box
              sx={{
                backgroundColor: Colors.NAV_BLACK,
                padding: '1rem',
                borderRadius: '20px',
                height: 'max-content'
              }}>
              <Box
                sx={{
                  backgroundColor: Colors.Canvas_BG,
                  padding: '.5rem',
                  borderRadius: '20px',
                  height: '40vh',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'relative'
                }}>
                {searchedResult?.attachment?.convertedS3Keys?.length > 1 && (
                  <IconButton
                    disabled={currentImageIndex === 0}
                    onClick={handlePrevImage}
                    sx={{
                      position: 'absolute',
                      left: '1%',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      color: Colors.WHITE
                    }}>
                    <ChevronLeft />
                  </IconButton>
                )}
                <>
                  <img
                    src={searchedResult?.attachment?.convertedS3Keys[currentImageIndex]}
                    alt="file"
                    style={smallImageStyle}
                    onClick={handleImageClick}
                  />
                  <img
                    src={searchedResult?.attachment?.convertedS3Keys[currentImageIndex]}
                    alt="file"
                    style={largeImageStyle}
                    onClick={handleImageClick}
                  />
                </>
                {searchedResult?.attachment?.convertedS3Keys?.length > 1 && (
                  <IconButton
                    disabled={
                      currentImageIndex === searchedResult?.attachment?.convertedS3Keys?.length - 1
                    }
                    onClick={handleNextImage}
                    sx={{
                      position: 'absolute',
                      right: '1%',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      color: Colors.WHITE
                    }}>
                    <ChevronRight />
                  </IconButton>
                )}
              </Box>
              {editattachment ? (
                <Box sx={{ display: 'flex', mb: '2rem', mt: '.7rem', alignItems: 'center' }}>
                  <Typography sx={{ color: Colors.WHITE, width: '10rem' }}>
                    Instrument ID:
                  </Typography>
                  <CustomTextField
                    setValue={setInstumentId}
                    value={instrumentId}
                    placeHolder="Instrument Id"
                    backgroundColor={Colors.Canvas_BG}
                  />
                </Box>
              ) : (
                <>
                  <Typography sx={{ color: Colors.WHITE, display: 'flex', mt: '1rem' }}>
                    <div style={{ width: '8rem' }}>File Name:</div>
                    {truncateFileName(searchedResult?.attachment?.originalFileName, 12)}
                  </Typography>
                  <Typography sx={{ color: Colors.WHITE, display: 'flex', mb: '2rem' }}>
                    <div style={{ width: '8rem' }}>Instrument ID:</div>
                    {instrumentId}
                  </Typography>
                </>
              )}

              <Grid container item xs={12} sx={{ justifyContent: 'space-between', mb: '.2rem' }}>
                <Btn
                  color={Colors.WHITE}
                  backgroundColor={Colors.RED}
                  text={editattachment ? 'Cancel' : 'Delete'}
                  height="2.2rem"
                  width="48%"
                  onClick={() => {
                    editattachment ? handleCancel() : setOpenPrompt(true);
                  }}
                />
                <Prompt
                  title={'Delete Attachment'}
                  description={'Are you sure you want to delete attachment?'}
                  leftButton="Cancel"
                  rightButton="Confirm"
                  setOpen={setOpenPrompt}
                  open={openPrompt}
                  handleClick={handleDelete}
                  loading={isloading}
                />
                <Btn
                  loading={loading}
                  color={Colors.WHITE}
                  backgroundColor={Colors.DEEPBLUE}
                  text={editattachment ? 'Save' : 'Edit'}
                  height="2.2rem"
                  width="48%"
                  onClick={() => {
                    editattachment ? handleSave(searchedResult?.attachment?._id) : handleEdit();
                  }}
                />
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} lg={8}>
            <Typography
              sx={{ color: Colors.TOURQUISE, ml: '1rem', mb: '.5rem', fontSize: '1.2rem' }}>
              Documents
            </Typography>
            <Grid
              item
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                color: Colors.TEXTGREY,
                gap: '2%',
                maxHeight: '67vh',
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  width: '.2em',
                  height: '0em',
                  borderRadius: '20px'
                },
                '&::-webkit-scrollbar-track': {
                  boxShadow: 'inset 0 0 6px rgba(0,0,0,0.11)',
                  webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.11)',
                  outline: '1px solid slategrey'
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#fff',
                  outline: '1px solid slategrey'
                }
              }}>
              {searchedResult?.document?.map((doc, i) => {
                return (
                  <ImageCard
                    data={doc}
                    onClick={() => navigate(`/details/${doc?.id}`)}
                    btnText="View Details"
                    width={270}
                    height={338}
                    variant="body2"
                    fontLength={13}
                    btnColor={Colors.DEEPBLUE}
                    url={doc?.s3SignedUrl[0]}
                  />
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}
