import React, { useState, useEffect } from 'react';

import { Grid } from '@mui/material';

import logo from '../../src/assets/BLogo.svg';
import logoText from '../../src/assets/BHLogo.svg';
import '../LoginTransition.css';

function LoginTransition({ setIsTransitionCompleted }) {
  const [isTextVisible, setIsTextVisible] = useState(true);

  useEffect(() => {
    const textVisibleTimer = setTimeout(() => {
      setIsTextVisible(false);
    }, 1000);

    const transitionCompletedTimer = setTimeout(() => {
      setIsTransitionCompleted(false);
    }, 4000);

    return () => {
      clearTimeout(textVisibleTimer);
      clearTimeout(transitionCompletedTimer);
    };
  }, [setIsTransitionCompleted]);
  return (
    <Grid
      container
      sx={{
        alignItems: 'center',
        justifyContent: 'center'
      }}>
      <img
        src={logo}
        alt="logo"
        style={{
          width: '8rem',
          animation: isTextVisible ? '' : 'slideRightAndZoomIn 2s ease 2s forwards'
        }}
      />
      <img
        src={logoText}
        alt="BeiingHumans"
        style={{
          width: '28rem',
          marginLeft: '1rem',
          animation: 'fadeOutFromRight 1 ease 2s forwards'
        }}
      />
    </Grid>
  );
}

export default LoginTransition;
