import React from 'react';

import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { ThemeProvider } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';

import { dropzoneData } from '../constants/appConstants';
import theme from '../theme';
import { Colors } from '../config/default';

export default function FileList({ files, removeFile }) {
  const { FILE_STATUS } = dropzoneData;

  return (
    <ThemeProvider theme={theme}>
      <Grid
        sx={{
          height: '6.875rem',
          overflowY: files?.length > 3 ? 'scroll' : '',
          '&::-webkit-scrollbar': {
            width: '0.5em',
            height: '0em',
            borderRadius: '20px'
          },
          '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.11)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.11)',
            outline: '1px solid slategrey'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#fff',
            outline: '1px solid slategrey'
          }
        }}>
        {files?.length === 0 ? (
          <Grid sx={{ textAlign: 'center' }}>
            <h4>{FILE_STATUS}</h4>
          </Grid>
        ) : (
          files &&
          files?.map((data, i) => (
            <Grid
              item
              key={i}
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '1rem',
                justifyContent: 'space-between'
              }}>
              <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                <InsertDriveFileIcon sx={{ color: 'secondary.light' }} />
                <Typography variant="body2">{data?.name}</Typography>
              </Grid>
              {files.isUploading && <CircularProgress size={150} color="primary" />}
              {!files.isUploading && (
                <DeleteOutlineOutlinedIcon
                  sx={{ color: Colors.RED, cursor: 'pointer' }}
                  onClick={() => {
                    removeFile(data?.name);
                  }}
                />
              )}
            </Grid>
          ))
        )}
      </Grid>
    </ThemeProvider>
  );
}
