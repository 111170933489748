import React, { useState } from 'react';
import { useToast } from '../toast/toastContext';

import { Grid, Button, CircularProgress } from '@mui/material';

import { registerVendor } from '../services/services';
import { Colors } from '../config/default';
import { isEmailValid } from '../services/validationService';
import CustomTextField from './textfield';

export default function RegisterVendor({ setOpen, getAllVendors }) {
  const { showToast } = useToast();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const addNewVendor = async (e) => {
    e.preventDefault();
    setLoading(true);
    const params = {
      email: email?.toLowerCase()
    };
    const result = await registerVendor(params);
    if (result?.status === 200) {
      showToast(result?.data?.metadata?.message, 'success');
      getAllVendors();
      setEmail('');
      setOpen(false);
    } else {
      showToast(result?.data?.metadata?.message || result?.res?.data?.metadata?.message, 'error');
    }
    setLoading(false);
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && email.trim() !== '' && isEmailValid(email)) {
      addNewVendor(e);
    }
  };
  return (
    <Grid>
      <CustomTextField
        setValue={setEmail}
        value={email}
        placeHolder="Email"
        toShow="vendor"
        handleKeyPress={handleKeyPress}
      />
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="outlined"
          disabled={email === '' || loading || !isEmailValid(email)}
          onClick={(e) => addNewVendor(e)}
          sx={{
            height: '2.2rem',
            marginTop: '2em',
            width: '6rem',
            borderRadius: '10px',
            color: Colors.WHITE,
            textTransform: 'none'
          }}>
          {loading ? <CircularProgress size={20} color="inherit" /> : 'Register'}
        </Button>
      </Grid>
    </Grid>
  );
}
