import React, { useState } from 'react';

import { Box, Modal, IconButton, Input, Button, Typography, Grid } from '@mui/material';
import Btn from './button';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';

import { headings, QwcPopup } from '../constants/appConstants';
import { Colors } from '../config/default';
import { downloadQBDFile } from '../services/services';
import { hashPassword } from '../services/common';
import { useToast } from '../toast/toastContext';
import qbImage from '../assets/QB_btn.png';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  p: 5
};

export default function Popup({ show, activity }) {
  const { PASSWORD } = headings;
  const { NOTE, NOTICE, DOWNLOAD_QWC_FILE, PASSWORD_INFO } = QwcPopup;
  const { showToast } = useToast();
  const [open, setOpen] = React.useState(false);
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleDownload = async () => {
    const hashedPassword = hashPassword(password);
    const params = {
      password: hashedPassword
    };
    const result = await downloadQBDFile(params);
    if (result?.status === 200) {
      window.open(result?.data?.payload?.data, '_blank');
      setPassword('');
      setOpen(false);
    } else {
      showToast(result?.response?.data?.payload?.data, 'error');
    }
  };
  return (
    <div>
      {show === 'download' ? (
        <Btn
          height="2.2rem"
          text="DOWNLOAD"
          variant="contained"
          onClick={handleOpen}
          marginBottom=".2rem"
          radius="5px"
          color={Colors.WHITE}
          backgroundColor={Colors.QB_GREEN}
        />
      ) : (
        <Grid item xs={12}>
          <img
            src={qbImage}
            alt="QuickBooks Integration"
            width="100%"
            style={{ objectFit: 'contain', cursor: 'pointer' }}
            onClick={handleOpen}
          />
        </Grid>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography variant="h5" sx={{ color: Colors.TEXTGREY }}>
            {PASSWORD_INFO}
          </Typography>
          <Typography sx={{ fontSize: '13px', color: Colors.TEXTGREY }}>
            <span style={{ color: Colors.RED }}>{NOTE}</span>
            {NOTICE}
          </Typography>
          <Input
            fullWidth
            sx={{ mt: 2, mb: 3 }}
            variant="outlined"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            placeholder="Enter Password"
            name={PASSWORD}
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          <Button
            fullWidth
            variant="contained"
            disabled={password === ''}
            onClick={handleDownload}
            sx={{
              height: '3rem',
              borderRadius: '5px',
              textTransform: 'none',
              backgroundColor: Colors.QB_GREEN,
              color: Colors.WHITE,
              '&:hover': {
                backgroundColor: Colors.QB_GREEN
              }
            }}>
            {DOWNLOAD_QWC_FILE}
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
