import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Grid, Hidden, Typography } from '@mui/material';
import theme from '../theme';
import { ThemeProvider } from '@mui/material/styles';
import UseQuery from '../hooks/useQuery';
import { isEmpty } from 'lodash';

import SignUp from '../components/signUp';
import logo from '../../src/assets/BLogo.svg';
import bgImage from '../../src/assets/Background.png';
import { headings } from '../constants/appConstants';

export default function Registeration() {
  const { AUTH_HEADING, BEIING_HUMAN } = headings;
  const [isHovered, setIsHovered] = useState(false);
  let query = UseQuery();
  const id = query.get('companyId');
  const companyName = query.get('companyName');
  const cLevelMail = query.get('cLevelEmail');
  const managerMail = query.get('managerEmail');
  const accountantMail = query.get('accountantEmail');
  const { role } = useParams();
  const name = query.get('name');

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'primary.light',
          height: '100vh',
          backgroundImage: isHovered ? `url(${bgImage})` : 'none',
          backgroundSize: 'cover'
        }}>
        <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '2rem',
              flexDirection: { xs: 'column', md: 'row' }
            }}>
            <Hidden mdDown>
              <Typography variant="h1">{AUTH_HEADING}</Typography>
            </Hidden>
            <img src={logo} alt="Logo" style={{ margin: '0 .7rem', width: '5em' }} />
            <Typography variant="h1">{BEIING_HUMAN}</Typography>
          </Grid>

          <Grid item xs={9} md={5} lg={2.5}>
            <SignUp
              role={
                role === 'accountantSignup'
                  ? 'Accountant'
                  : role === 'managerSignup'
                  ? 'Manager'
                  : 'C_Level'
              }
              company={id}
              defaultMail={
                role === 'cLevelSignup'
                  ? cLevelMail
                  : role === 'managerSignup'
                  ? managerMail
                  : role === 'accountantSignup'
                  ? accountantMail
                  : ''
              }
              setIsHovered={setIsHovered}
              defaultCompany={companyName}
              designationName={name}
            />
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
