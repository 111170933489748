import React, { useState } from 'react';
import { Drawer, Button, Grid, Typography, Box, IconButton } from '@mui/material';
import { useParams } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CloseIcon from '@mui/icons-material/Close';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { useSelector } from 'react-redux';

import { Colors } from '../config/default';
import Btn from './button';
import { truncateFileName } from '../services/common';
import Prompt from './prompt';
import { useToast } from '../toast/toastContext';
import { removeAttachment, getDocumentDetails } from '../services/services';

export default function AttachmentDrawer({ data, setAttachments, userRole }) {
  const { id } = useParams();
  const userDetails = useSelector((state) => state?.userInfo?.userInfo);
  const companyId = userDetails?.company?._id;
  const { showToast } = useToast();
  const [openPrompt, setOpenPrompt] = useState(false);
  const [currentItemId, setCurrentItemId] = useState('');
  const [enlargedStates, setEnlargedStates] = useState(Array(data?.length).fill(false));
  const [state, setState] = useState({
    right: false
  });
  const [isLoading, setIsLoading] = useState(false);
  const [currentAttachmentIndices, setCurrentAttachmentIndices] = useState(
    Array(data?.length).fill(0)
  );

  const handleNextAttachment = (e, i) => {
    e.stopPropagation();
    setCurrentAttachmentIndices((prev) => {
      const newIndices = [...prev];
      newIndices[i] = (newIndices[i] + 1) % data[i]?.convertedS3Keys?.length;
      return newIndices;
    });
  };

  const handlePrevAttachment = (e, i) => {
    e.stopPropagation();
    setCurrentAttachmentIndices((prev) => {
      const newIndices = [...prev];
      const nextIndex = newIndices[i] - 1;
      newIndices[i] = nextIndex < 0 ? data[i]?.convertedS3Keys?.length - 1 : nextIndex;
      return newIndices;
    });
  };

  const handleImageClick = (e, i) => {
    e.stopPropagation();
    e.preventDefault();
    setEnlargedStates((prev) => {
      const newEnlargedStates = [...prev];
      newEnlargedStates[i] = !newEnlargedStates[i];
      return newEnlargedStates;
    });
  };

  const smallImageStyle = {
    height: '100%',
    objectFit: 'contain',
    cursor: 'pointer',
    opacity: 1,
    zIndex: 1,
    aspectRatio: 1
  };

  const largeImageStyle = {
    width: '100vw',
    height: '100vh',
    objectFit: 'contain',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 9999,
    cursor: 'pointer',
    opacity: 1
  };

  const handleDelete = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsLoading(true);
    const result = await removeAttachment(id, currentItemId, companyId);
    if (result?.status === 200) {
      const callSource = userRole === 'Vendor' ? 'api' : 'app';
      const response = await getDocumentDetails(id, callSource);
      if (response?.status === 200) {
        setAttachments(response?.data?.payload?.data?.attachments);
        showToast(result?.data?.metadata?.message, 'success');
        setOpenPrompt(false);
      }
    } else {
      setOpenPrompt(false);
      showToast(
        result?.response?.data?.metadata?.message || result?.data?.metadata?.message,
        'error'
      );
    }
    setIsLoading(false);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Grid
      sx={{
        alignItems: 'center',
        height: '100vh',
        backgroundColor: Colors.NAV_BLACK
      }}
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}>
      <Grid>
        <Grid
          container
          sx={{ padding: '0em 2em', mt: '1.5em', mb: '1em', justifyContent: 'flex-end' }}>
          <CloseIcon sx={{ cursor: 'pointer', fontSize: '1.5em', color: Colors.LIGHTGREY }} />
        </Grid>
        <Grid
          container
          sx={{
            height: '83vh',
            width: { xs: '20em', sm: '28em', xl: '42em' },
            padding: '2em 0.8em',
            borderRadius: '1em',
            backgroundColor: Colors.BG_BLACK,
            flexDirection: 'column',
            justifyContent: 'flex-start',
            m: '0em 2em',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              width: '.2em',
              height: '0em',
              borderRadius: '20px'
            },
            '&::-webkit-scrollbar-track': {
              margin: '1rem 0rem',
              borderRadius: '20px',
              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.11)',
              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.11)',
              outline: '1px solid slategrey'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#fff',
              outline: '1px solid slategrey'
            }
          }}>
          <Grid item>
            <Typography sx={{ color: Colors.TOURQUISE, fontSize: '1em', ml: '1.1em', mb: '1rem' }}>
              Attachments
            </Typography>
            {data?.map((item, i) => (
              <Grid key={i} item xs={12}>
                <Box
                  sx={{
                    backgroundColor: Colors.Canvas_BG,
                    height: '35vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '20px',
                    objectFit: 'contain',
                    position: 'relative',
                    padding: '.5rem 0'
                  }}>
                  {item?.convertedS3Keys?.length > 1 && (
                    <>
                      <IconButton
                        disabled={currentAttachmentIndices[i] === 0}
                        sx={{
                          position: 'absolute',
                          left: '3%',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          backgroundColor: Colors.TRANSPARENT,
                          color: Colors.WHITE
                        }}
                        onClick={(e) => handlePrevAttachment(e, i)}>
                        <ChevronLeft />
                      </IconButton>
                      <IconButton
                        disabled={currentAttachmentIndices[i] === item?.convertedS3Keys?.length - 1}
                        sx={{
                          position: 'absolute',
                          right: '3%',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          backgroundColor: Colors.TRANSPARENT,
                          color: Colors.WHITE
                        }}
                        onClick={(e) => handleNextAttachment(e, i)}>
                        <ChevronRight />
                      </IconButton>
                    </>
                  )}
                  <>
                    <>
                      <img
                        alt="attachment"
                        src={item?.convertedS3Keys[currentAttachmentIndices[i]]}
                        style={smallImageStyle}
                        onClick={(e) => handleImageClick(e, i)}
                      />
                      {enlargedStates[i] && (
                        <img
                          alt="attachment"
                          src={item?.convertedS3Keys[currentAttachmentIndices[i]]}
                          style={largeImageStyle}
                          onClick={(e) => handleImageClick(e, i)}
                        />
                      )}
                    </>
                  </>
                </Box>
                <Grid
                  container
                  item
                  sx={{
                    justifyContent: 'space-between',
                    padding: '.5rem',
                    alignItems: 'center'
                  }}>
                  <Typography sx={{ color: Colors.WHITE }}>
                    {truncateFileName(item?.originalFileName, 16)}
                    <div style={{ padding: 0, color: Colors.TEXTGREY, fontSize: '.8rem' }}>
                      <span style={{ color: Colors.TOURQUISE }}>Instrument Id: </span>
                      {item?.referenceNo}
                    </div>
                  </Typography>
                  <div>
                    <Btn
                      text="Remove"
                      color={Colors.WHITE}
                      height="2rem"
                      width="6rem"
                      backgroundColor={Colors.RED}
                      radius="10px"
                      onClick={(e) => {
                        e.stopPropagation();
                        setCurrentItemId(item?._id);
                        setOpenPrompt(true);
                      }}
                    />
                    <Prompt
                      title="Delete Attachment"
                      description="Are you sure you want to delete attachment?"
                      leftButton="Cancel"
                      rightButton="Confirm"
                      setOpen={setOpenPrompt}
                      open={openPrompt}
                      handleClick={(e) => handleDelete(e)}
                      loading={isLoading}
                      opacity={true}
                    />
                  </div>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <div>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button
            variant="Outlined"
            sx={{
              border: '1px solid ',
              borderColor: Colors.DARKBLUE,
              height: '2rem',
              textTransform: 'none',
              mr: '1em',
              color: Colors.WHITE,
              borderRadius: '10px',
              '&:hover': { backgroundColor: 'transparent' }
            }}
            onClick={toggleDrawer(anchor, true)}>
            <ArrowBackIosNewIcon sx={{ color: Colors.DARKBLUE, fontSize: '1em', mr: '.3em' }} />
            <Typography sx={{ fontSize: '1em' }}>Attachments</Typography>
          </Button>
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
