import React from 'react';

import { Grid, Typography } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import { Colors } from '../config/default';
function PaginationButtons({
  disableFirstButton,
  disableSecondButton,
  slideLeft,
  slideRight,
  index,
  images,
  margin,
  totalPages
}) {
  return (
    <Grid xs={12} sx={{ marginTop: margin }}>
      <Grid
        item
        xs={12}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '0.5em' }}>
        <Grid
          disabled={disableFirstButton}
          container
          sx={{
            height: '1.5em',
            width: '1.5em',
            background: '#373735',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '0.2em',
            '&:hover': {
              background: '#13293E'
            },
            '&:disabled': {
              background: '#13293E',
              color: 'gray',
              opacity: '0.6',
              cursor: 'not-allowed'
            }
          }}>
          <ArrowBackIosNewIcon
            sx={{ color: Colors.WHITE, height: '.6em', width: '.6em', cursor: 'pointer' }}
            onClick={slideLeft}
          />
        </Grid>

        <Grid>
          <Typography
            variant="h6"
            sx={{ textAlign: 'center', fontSize: '0.85em', color: Colors.WHITE }}>
            Page {index + 1} of {totalPages || images?.length}
          </Typography>
        </Grid>

        <Grid
          disabled={disableSecondButton}
          container
          sx={{
            height: '1.5em',
            width: '1.5em',
            background: '#373735',
            borderRadius: '0.2em',
            alignItems: 'center',
            justifyContent: 'center',
            '&:hover': {
              background: '#13293E'
            },
            '&:disabled': {
              background: '#13293E',
              color: 'gray',
              opacity: '0.6',
              cursor: 'not-allowed'
            }
          }}>
          <ArrowForwardIosIcon
            sx={{
              color: Colors.WHITE,
              height: '.6em',
              width: '.6em',
              cursor: 'pointer'
            }}
            onClick={slideRight}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PaginationButtons;
