import React from 'react';
import { useSelector } from 'react-redux';

import { Card, Grid, Switch, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import Popup from './popup';
import { Colors } from '../config/default';
import Btn from './button';
import qbImage from '../assets/QB_btn.png';

const StyledSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: Colors.WHITE
  },
  '& .MuiSwitch-track': {
    backgroundColor: Colors.RED,
    transparency: 1
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: Colors.TOURQUISE
  }
}));
export default function IntegrationButton({
  handleClick,
  loading,
  cardIcon,
  cardTitle,
  toggle,
  buttonName,
  activity,
  subTitle,
  setChecked,
  checked,
  cursor,
  width,
  imageWidth
}) {
  const userDetails = useSelector((state) => state?.userInfo?.userInfo);
  const activeIntegration = useSelector((state) => state?.userInfo?.userInfo?.activeIntegration);
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const renderContent = () => {
    if (buttonName === 'Connect to QuickBooks') {
      return (
        <Grid item xs={12}>
          <img
            src={qbImage}
            alt="QuickBooks Integration"
            width="100%"
            style={{ objectFit: 'contain', cursor: 'pointer' }}
            onClick={handleClick}
          />
        </Grid>
      );
    } else {
      return (
        <Btn
          height="2.2rem"
          text={buttonName}
          variant="contained"
          backgroundColor={activity}
          color={Colors.WHITE}
          loading={loading}
          onClick={handleClick}
          radius="5px"
          cursor={cursor}
        />
      );
    }
  };
  return (
    <Card
      sx={{
        backgroundColor: Colors.NAV_BLACK,
        borderRadius: '10px',
        color: Colors.WHITE,
        padding: '1em',
        width: width,
        height: 'max-content'
      }}>
      <Card
        sx={{
          height: '9em',
          backgroundColor: '#282828',
          borderRadius: '0.75em',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        <img
          style={{ objectFit: 'contain' }}
          width={imageWidth || 50}
          height={50}
          src={cardIcon}
          alt="img"
        />
      </Card>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mt: '1.5rem',
          alignItems: 'center'
        }}>
        <Typography sx={{ fontSize: '1rem', fontWeight: '500' }}>{cardTitle}</Typography>
        {toggle && (
          <StyledSwitch
            size="small"
            checked={checked}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        )}
      </Grid>
      <Typography
        sx={{
          fontSize: '.75rem',
          width: '90%',
          mt: '.7em',
          marginBottom: '1.5rem'
        }}>
        {subTitle}
      </Typography>

      {userDetails?.user?.erpType === 'QuickBooksDesktop' &&
      activeIntegration === '' &&
      buttonName !== 'ACTIVE' &&
      cursor !== false ? (
        <Grid item xs={12}>
          <Popup isIntegrated={activeIntegration} activity={activity} />
        </Grid>
      ) : userDetails?.user?.erpType === 'QuickBooksDesktop' &&
        activeIntegration === 'QuickBooksDesktop' &&
        buttonName !== 'ACTIVE' &&
        cursor !== false ? (
        <Grid item xs={12}>
          <Popup isIntegrated={activeIntegration} show="download" activity={activity} />
          {renderContent()}
        </Grid>
      ) : (
        renderContent()
      )}
    </Card>
  );
}
