const erpConfig = {
  csvPaths: {
    GLAccounts: '/csv/GLAccounts.csv',
    VendorList: {
      DeltekComputerEase: '/csv/VendorList.csv',
      Foundation: '/csv/otherVendor.csv',
      QuickBooksOnline: '/csv/otherVendor.csv',
      QuickBooksDesktop: '/csv/otherVendor.csv',
      ['Accounting Seed']: '/csv/otherVendor.csv',
      Other: '/csv/otherVendor.csv'
    },
    CostTypeList: '/csv/costTypeList.csv',
    JobList: {
      DeltekComputerEase: '/csv/JobList.csv',
      Other: '/csv/otherJobList.csv',
      Foundation: '/csv/otherJobList.csv',
      QuickBooksOnline: '/csv/otherJobList.csv',
      QuickBooksDesktop: '/csv/otherJobList.csv',
      ['Accounting Seed']: '/csv/otherJobList.csv'
    },
    CostList: '/csv/CostList.csv',
    Phases: '/csv/phases.csv',
    PurchaseOrder: '/csv/purchaseOrder.csv'
  },
  csvProcessing: {
    VendorList: {
      DeltekComputerEase: parseDeltekVendors,
      Foundation: parseOtherVendors,
      QuickBooksOnline: parseOtherVendors,
      QuickBooksDesktop: parseOtherVendors,
      ['Accounting Seed']: parseOtherVendors,
      Other: parseOtherVendors
    },
    GLAccounts: parseAccounts,
    CostList: parseCostIds,
    Phases: parsePhase,
    CostTypeList: parseCostType,
    JobList: {
      DeltekComputerEase: parseDeltekJobIds,
      Other: parseOtherJobIds,
      Foundation: parseOtherJobIds,
      QuickBooksOnline: parseOtherJobIds,
      QuickBooksDesktop: parseOtherJobIds,
      ['Accounting Seed']: parseOtherJobIds
    }
  }
};
export default erpConfig;

export function parseAccounts(data) {
  return data?.map(({ Account }) => ({ name: Account }));
}

export function parseDeltekVendors(data) {
  return data?.map((item) => ({
    name: item?.name,
    email: item?.email,
    id: item?.vennum,
    costType: item?.costtype,
    AcctNum: item?.acctnum
  }));
}
export function parseOtherVendors(data) {
  return data?.map((item) => ({ name: item?.Vendor, email: item?.Email }));
}
export function parseCostType(data) {
  return data?.map((item) => ({ id: item?.id, name: item?.name, code: item?.code }));
}

export function parsePhase(data) {
  return data.map((item) => ({
    id: item?.phasenum,
    code: item?.catnum,
    name: item?.name
  }));
}

export function parseCostIds(data) {
  return data?.map((item) => ({ name: item?.Item }));
}

export function parseDeltekJobIds(data) {
  return data?.map((item) => ({
    id: item?.jobnum,
    name: item?.name,
    salesacct: item?.salesacct,
    deptnum: item?.deptnum
  }));
}
export function parseOtherJobIds(data) {
  return data?.map((item) => ({ name: item?.Customer }));
}
